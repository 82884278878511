import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import { useAppContext } from '../../contexts/AppContext';
import { DataContext } from './contexts';
import useDishesQuery from '../../graphql/hooks/dishes/useGetDishesQuery';
import MetaTags from '../../components/MetaTags';
import BodyContainer from '../../components/BodyContainer';
import Head from './Head';
import TopFilters from './TopFilters';
import Body from './Body';

const Dishes = () => {
  const { breakpoints } = useTheme();
  const { t } = useTranslation('');
  const isMobileView = useMediaQuery(breakpoints.down('sm'));
  const { orderDishesFilters: [filters] } = useAppContext();
  const { getDishes, ...dishesData } = useDishesQuery({ filters, lazy: true });

  useEffect(getDishes, []);

  return (
    <>
      <MetaTags
        title="Piatti - Libraway"
        description={t('meta-description-dishes')}
      />
      <DataContext.Provider value={{ dataType: 'dishes', data: dishesData }}>
        <BodyContainer>
          <Container>
            {!isMobileView ? <Head /> : null}
            <TopFilters />
            <Body />
          </Container>
        </BodyContainer>
      </DataContext.Provider>
    </>
  );
};

export default Dishes;
