import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { childrenPropType } from '../../components/propTypes';
import useStyles from './style';

const Section = ({ label, component: Component, editable }) => {
  const { t } = useTranslation();
  const { sectionContainer, switchModeButton } = useStyles();
  const [editMode, setEditMode] = useState(false);

  const handleSwitchModeClick = () => { setEditMode(!editMode); };

  return (
    <Grid container>
      <div className={sectionContainer}>
        <Grid container spacing={4} justify="space-between">
          {label ? (
            <Grid item xs={6}>
              <Typography variant="h3">{label}</Typography>
            </Grid>
          ) : null}

          {editable ? (
            <Grid item xs={6}>
              <Grid container justify="flex-end">
                <ButtonBase
                  onClick={handleSwitchModeClick}
                  className={switchModeButton}
                >
                  {t(editMode ? 'common:cancel' : 'common:edit')}
                </ButtonBase>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Component
              editMode={editable ? editMode : undefined}
              setEditMode={editable ? setEditMode : undefined}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

Section.propTypes = {
  component: childrenPropType,
  editable: PropTypes.bool,
  label: PropTypes.string,
};

Section.defaultProps = {
  component: null,
  editable: false,
  label: null,
};

export default Section;
