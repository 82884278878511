import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/comments.svg';
import { ReactComponent as BoxBadgeIcon } from '../../assets/icons/boxBadge.svg';
import { ReactComponent as FreshBadgeIcon } from '../../assets/icons/freshBadge.svg';
import { ReactComponent as RecipeBadgeIcon } from '../../assets/icons/recipeBadge.svg';
import { ReactComponent as RestaurantBadgeIcon } from '../../assets/icons/restaurantBadge.svg';
import { useCartContext } from '../../contexts/CartContext';
import { nullType } from '../../libraries/helpers';
import SizesDialog from '../SizesDialog';
import Button from '../Button';
import ValuationScale from '../ValuationScale';
import Image from './Image';
import Price from './Price';
import useStyles from './style';

/* eslint-disable react/prop-types */
const itemType = {
  dishes: ({ className }) => <BoxBadgeIcon className={className} />,
  fresh: ({ className }) => <FreshBadgeIcon className={className} />,
  restaurants: ({ className }) => <RestaurantBadgeIcon className={className} />,
  recipes: ({ className }) => <RecipeBadgeIcon className={className} />,
};
/* eslint-enable react/prop-types */

const Card = ({
  badge,
  cartInfo,
  commentsCount,
  foods,
  handleClick,
  image,
  title,
  type,
  valuation,
}) => {
  const [{ cartItems }] = useCartContext();
  const alreadyInCart = cartItems.filter(({ dishId }) => dishId === cartInfo.id);
  const {
    body,
    container,
    cartButton,
    cartButtonIcon,
    fullHeight,
    titleStyle,
    valuationStyle,
    valuationText,
    footerIcon,
    foodStyle,
  } = useStyles({
    cursor: !!handleClick,
    alreadyInCart: alreadyInCart.length,
  });
  const { capitalize } = useTheme().useClasses();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    setModalOpen(true);
  };
  const handleClose = () => { setModalOpen(false); };

  // eslint-disable-next-line react/prop-types
  const renderFood = ({ name, image: ingredientImage }) => (
    <Grid item key={name}>
      <img
        alt={name}
        src={ingredientImage.url}
        className={foodStyle}
      />
    </Grid>
  );

  return (
    <>
      <Paper
        onClick={handleClick}
        className={container}
      >
        <Grid
          container
          direction="column"
          justify="center"
          className={fullHeight}
        >
          {/* Image cover */}
          <Grid item xs>
            <Image
              type={type}
              image={image}
              badge={badge}
            />
          </Grid>

          {/* Body */}
          <Grid item xs className={body}>
            <Grid
              container
              direction="column"
              justify="space-between"
              className={fullHeight}
            >
              {/* Food icons */}
              {foods && foods.length ? (
                <Grid item>
                  <Grid container spacing={2}>
                    {foods.map(renderFood)}
                  </Grid>
                </Grid>
              ) : null}

              {/* Title */}
              <Grid item style={{ flexGrow: 1 }}>
                <Box className={classNames(titleStyle, capitalize)}>
                  {title}
                </Box>
              </Grid>

              {/* Footer */}
              {type === 'dishes' ? (

                // Price and cart button
                <Grid item container justify="space-between" alignItems="center">
                  <Grid item>
                    <Price
                      price={cartInfo.price}
                      discountedPrice={cartInfo.discountedPrice}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleOpen}
                      fitted
                      filled={!!alreadyInCart.length}
                      padding={2}
                      className={cartButton}
                    >
                      <span
                        style={{
                          fontWeight: alreadyInCart.length ? 700 : 900,
                        }}
                      >
                        {alreadyInCart.length ? sumBy(alreadyInCart, 'quantity') : '+'}
                      </span>
                      <CartIcon className={cartButtonIcon} />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container>
                  <Grid item container alignContent="center" className={valuationStyle}>
                    <StarIcon className={footerIcon} />
                    <span className={valuationText}>
                      <ValuationScale>{valuation}</ValuationScale>
                    </span>
                  </Grid>
                  <Grid item container alignContent="center" style={{ width: 'auto' }}>
                    <CommentsIcon className={footerIcon} />
                    <span className={valuationText}>{`${commentsCount || nullType}`}</span>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {type === 'dishes' && modalOpen ? (
        <SizesDialog
          cartInfo={cartInfo}
          open
          onClose={handleClose}
          sizes={cartInfo.sizes}
        />
      ) : null}
    </>
  );
};

Card.propTypes = {
  badge: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
  }),
  cartInfo: PropTypes.shape(),
  commentsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  foods: PropTypes.arrayOf(PropTypes.shape()),
  handleClick: PropTypes.func,
  image: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    thumbnail: PropTypes.string,
    thumbnail2x: PropTypes.string,
    thumbnail3x: PropTypes.string,
  })),
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(itemType)),
  valuation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Card.defaultProps = {
  badge: {},
  cartInfo: {},
  commentsCount: nullType,
  foods: [],
  handleClick: undefined,
  image: [],
  title: '',
  type: undefined,
  valuation: null,
};

export default Card;
