import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  palette: {
    grey,
    black,
    white,
    secondary,
  },
}) => ({
  root: {
    display: 'inline-flex',
    cursor: 'pointer',
    borderRadius: 6,
    border: 0,
    padding: 0,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    transition: '50ms ease',
    backgroundColor: grey.main,
  },

  checkedStyle: {
    border: '2px solid',
    borderColor: secondary.main,
    backgroundColor: white.main,
  },

  icon: {
    width: 12,
    height: 12,
    fill: black.light,
    transition: 'opacity 75ms ease',
    opacity: ({ checked }) => (checked ? 1 : 0),
  },
}));

export default useStyles;
