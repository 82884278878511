import React from 'react';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useGetWeights from '../../../../graphql/hooks/plans/useGetWeights';
import LocalLoading from '../../../../components/LocalLoading';
import DraggableItem from './DraggableItem';
import useStyles from './style';
import Placeholder from '../../../../components/Placeholder';

const minMaxOffsetPercent = 0.05; // 5%

const Weight = () => {
  const { t } = useTranslation('dashboard');
  const { weightGraphContainer } = useStyles();
  const { palette: { secondary } } = useTheme();
  const { loading, data } = useGetWeights();

  if (loading) return <LocalLoading />;

  const minValue = minBy(data, 'y').y;
  const maxValue = maxBy(data, 'y').y;

  return (
    <DraggableItem title={t('weights-trend')}>
      <Grid container justify="center" alignItems="center">
        {data.length ? (
          <div className={weightGraphContainer}>
            <ResponsiveLine
              data={[{ id: 'weight', data }]}
              margin={{
                top: 30,
                right: 40,
                bottom: 50,
                left: 60,
              }}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                precision: 'day',
              }}
              xFormat="time:%d-%m"
              yScale={{
                type: 'linear',
                min: minValue - minValue * minMaxOffsetPercent,
                max: maxValue + maxValue * minMaxOffsetPercent,
              }}
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'kg',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              axisBottom={{
                format: '%d-%m-%y',
              }}
              colors={secondary.main}
              enableArea
              areaBaselineValue={minValue - minValue * minMaxOffsetPercent}
              pointSize={10}
              pointBorderWidth={2}
              pointLabelYOffset={-12}
              useMesh
            />
          </div>
        ) : <Placeholder text={t('no-weights')} />}
      </Grid>
    </DraggableItem>
  );
};

export default Weight;
