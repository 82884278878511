import { useMutation } from '@apollo/react-hooks';
import { createOrUpdateShoppingListMutation } from '../../mutations/shoppingList';

export default () => {
  const [
    createOrUpdateShoppingList,
    { loading, data, error },
  ] = useMutation(
    createOrUpdateShoppingListMutation,
    { refetchQueries: ['ShoppingLists'] },
  );

  return [createOrUpdateShoppingList, { loading, data, errors: error && error.graphQLErrors }];
};
