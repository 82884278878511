import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import FilterItem from './FilterItem';
import useStyles from './style';

const Group = ({
  items,
  titleKey,
  title,
  name: groupName,
  ellipsisTreshold,
  handleClick,
}) => {
  const { t } = useTranslation('orderDishes');
  const [open, setOpen] = useState(false);
  const { groupContainer, moreButton } = useStyles();

  const label = title || t(titleKey);
  const needsEllipsis = items.length - ellipsisTreshold > 1;
  const itemsToRender = needsEllipsis ? items.slice(0, ellipsisTreshold - 1) : items;
  const itemsToCollapse = items.slice(ellipsisTreshold - 1, items.length);

  const handleMoreClick = () => { setOpen((currentOpen) => !currentOpen); };

  // eslint-disable-next-line react/prop-types
  const renderCategoryItem = ({ key, label: subLabel, items: subItems }) => (
    <FilterItem
      key={key}
      id={key}
      name={subLabel}
      handleClick={handleClick}
      group={groupName}
      subFilters={subItems}
    />
  );

  if (items.length) {
    return (
      <div className={groupContainer}>
        <Typography variant="h2">{label}</Typography>
        <List>
          {itemsToRender.map(renderCategoryItem)}
          {needsEllipsis ? (
            <>
              <Collapse in={open}>
                {itemsToCollapse.map(renderCategoryItem)}
              </Collapse>
              <ListItem
                button
                classes={{ root: moreButton }}
                onClick={handleMoreClick}
              >
                {`${open ? '-' : '+'} ${label}`}
              </ListItem>
            </>
          ) : null}
        </List>
      </div>
    );
  }

  return null;
};

Group.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string.isRequired,
  titleKey: PropTypes.string,
  title: PropTypes.string,
  ellipsisTreshold: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
};

Group.defaultProps = {
  items: [],
  ellipsisTreshold: 5,
  titleKey: null,
  title: null,
};

export default Group;
