import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';
import useStyles from './style';

const Close = ({ onClick }) => {
  const { closeButton } = useStyles();

  return (
    <ButtonBase onClick={onClick} className={closeButton}>
      <TimesIcon style={{ width: 18 }} />
    </ButtonBase>
  );
};

Close.propTypes = {
  onClick: PropTypes.func,
};

Close.defaultProps = {
  onClick: () => {},
};

export default Close;
