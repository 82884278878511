import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { childrenPropType } from '../propTypes';
import Close from './Close';
import useStyles from './style';

const Dialog = ({
  children,
  className,
  closeButton,
  large,
  minWidth,
  noPadding,
  onClose,
  open,
  title,
}) => {
  const { container, titleContainer } = useStyles({ large, noPadding, minWidth });

  const renderCloseButton = () => {
    if (!closeButton) return null;
    if (closeButton === true) return <Close onClick={onClose} />;
    const CloseButtonComponent = closeButton;
    return <CloseButtonComponent onClick={onClose} />;
  };

  return (
    <MuiDialog
      classes={{ paper: classNames(container, className) }}
      onClose={onClose}
      open={open}
    >
      {title ? (
        <DialogTitle disableTypography className={titleContainer}>
          {title}
        </DialogTitle>
      ) : null}
      {children}
      {renderCloseButton()}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  minWidth: PropTypes.number,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  closeButton: PropTypes.oneOfType([
    PropTypes.bool,
    childrenPropType,
  ]),
};

Dialog.defaultProps = {
  className: '',
  title: '',
  large: false,
  minWidth: null,
  noPadding: false,
  onClose: () => {},
  open: false,
  closeButton: true,
};

export default Dialog;
