import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Tab from '../../../components/Tab';
import TabPanel from '../../../components/TabPanel';
import Today from './Today';
import Week from './Week';
import Diary from './Diary';
import Tracking from './Tracking';
import useStyles from './style';

const tabs = [{
  slug: 'today',
  label: 'tab-title-today',
  content: Today,
}, {
  slug: 'week',
  label: 'tab-title-week',
  content: Week,
}, {
  slug: 'diary',
  label: 'tab-alimentary-diary',
  content: Diary,
}, {
  slug: 'tracking',
  label: 'tab-tracking',
  content: Tracking,
  disabled: true,
}, {
  slug: 'map',
  label: 'tab-alimentary-map',
  disabled: true,
}, {
  slug: 'fitness',
  label: 'tab-fitness-program',
  disabled: true,
}];

const TabsContainer = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const { sectionSlug } = useParams();
  const tab = sectionSlug || 'today';
  const { panelContainer, tabsButtonRow } = useStyles();

  const handleTabChange = (slug) => () => { history.push(`/dashboard/${slug}`); };

  // eslint-disable-next-line react/prop-types
  const renderTab = ({ slug, label, disabled }) => (
    <Grid item key={slug} style={{ minWidth: 200 }}>
      <Tab
        index={slug}
        label={t(label)}
        disabled={disabled}
        currentTab={tab}
        onClick={handleTabChange(slug)}
      />
    </Grid>
  );

  // eslint-disable-next-line react/prop-types
  const renderTabPanel = ({ slug, content: Content }) => (
    <TabPanel key={slug} value={tab} index={slug}>
      {Content ? <Content /> : null}
    </TabPanel>
  );

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <div className={tabsButtonRow}>
          {tabs.map(renderTab)}
        </div>
      </Grid>
      <div className={panelContainer}>
        {tabs.map(renderTabPanel)}
      </div>
    </Grid>
  );
};

export default TabsContainer;
