import React from 'react';
import PropTypes from 'prop-types';
import { trackingStatus } from './constants';
import useStyles from './style';

const statusMap = {
  EMPTY: 'default',
  SELECTED: 'success',
  SWITCHEDDAY: 'warning',
  SWITCHEDMEAL: 'warning',
  NOTFOLLOWED: 'error',
  RECOVER: 'warning',
};

const Dot = ({ type }) => {
  const { dot } = useStyles({ type: statusMap[type] });

  return type !== 'NONE' ? <div className={dot} /> : null;
};

Dot.propTypes = {
  type: PropTypes.oneOf(trackingStatus).isRequired,
};

export default Dot;
