import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Zoom from '@material-ui/core/Zoom';
import useReactHookForm from '../../hooks/useReactHookForm';
import useUpdateUserProfile from '../../graphql/hooks/user/useUpdateUserProfile';
import Input from '../../components/Input';
import Button from '../../components/Button';
import useStyles from './style';

const Access = ({ editMode, setEditMode }) => {
  const { t } = useTranslation('profile');
  const { submitContainer } = useStyles();
  const [passwordValue, setPasswordValue] = useState();
  const {
    useForm,
    registers: { getRequiredMinLength, getMatch },
  } = useReactHookForm();
  const [updateUserProfile] = useUpdateUserProfile();

  const {
    register,
    triggerValidation,
    errors,
    getValues,
  } = useForm();

  const handleSubmitClick = async () => {
    if (editMode) {
      if (await triggerValidation()) {
        updateUserProfile({ variables: getValues() });
        setEditMode(false);
      }
    } else {
      setEditMode(true);
    }
  };

  const renderErrors = () => Object.keys(errors).map((fieldName) => (
    <Zoom in>
      <Alert key={`${fieldName}-error`} severity="error">
        {errors[fieldName].message}
      </Alert>
    </Zoom>
  ));

  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12} sm={6}>
        <Input
          type="password"
          readOnly={!editMode}
          square
          label={t('new-password')}
          name="new-password"
          required
          value={passwordValue}
          onChange={setPasswordValue}
          ref={register(getRequiredMinLength(t('password')))}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Input
          type="password"
          readOnly={!editMode}
          square
          label={t('password-match')}
          name="password-match"
          required
          ref={register(getMatch(passwordValue))}
        />
      </Grid>

      <Grid item xs={6}>
        {renderErrors()}
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
          justify="flex-end"
          alignItems="flex-end"
          className={submitContainer}
        >
          <Zoom in={editMode}>
            <Button
              filled
              onClick={handleSubmitClick}
            >
              {t('common:save')}
            </Button>
          </Zoom>
        </Grid>
      </Grid>
    </Grid>
  );
};

Access.propTypes = {
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
};

Access.defaultProps = {
  editMode: true,
  setEditMode: () => { },
};

export default Access;
