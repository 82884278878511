import gql from 'graphql-tag';

const createCommentMutation = gql`
  mutation CreateComment(
    $name: String,
    $email: String,
    $message: String!,
    $recipeId: Int!
  ) {
    createComment(
      name: $name,
      email: $email,
      text: $message,
      ref: $recipeId,
      type: RECIPES,
    ) {
      id,
      text,
      status
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { createCommentMutation };
