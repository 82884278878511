import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import italia from 'italia';
import sortBy from 'lodash/sortBy';
import Grid from '@material-ui/core/Grid';
import useReactHookForm from '../../../hooks/useReactHookForm';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

const SpreadAddress = ({
  register,
  data: {
    address,
    number,
    zip,
    city,
    state,
  },
}) => {
  const { t } = useTranslation(['profile', 'order']);
  const { registers: { getRequired } } = useReactHookForm();

  const states = italia.regioni.reduce(
    (accumulator, { capoluoghi, province }) => [
      ...accumulator,
      ...province.map((provincia, index) => ({ value: provincia, label: capoluoghi[index] })),
    ],
    [],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          required
          label={t('address')}
          name="address"
          defaultValue={address}
          ref={register(getRequired('address'))}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          required
          label={t('number')}
          name="number"
          defaultValue={number}
          ref={register(getRequired('number'))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          required
          label={t('zip')}
          name="zip"
          defaultValue={zip}
          ref={register(getRequired('zip'))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          required
          label={t('city')}
          name="city"
          defaultValue={city}
          ref={register(getRequired('city'))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          label={t('state')}
          name="state"
          defaultValue={state}
          ref={register(getRequired('state'))}
        >
          {sortBy(states, 'label').map(({ value, label }) => (
            <option value={value}>{label}</option>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

SpreadAddress.propTypes = {
  register: PropTypes.func.isRequired,
  data: PropTypes.shape({
    address: PropTypes.string,
    number: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    state: PropTypes.string,
  }),
};

SpreadAddress.defaultProps = {
  data: {
    address: '',
    number: '',
    zip: '',
    city: '',
    state: '',
  },
};

export default SpreadAddress;
