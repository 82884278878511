import Box from '@material-ui/core/Box';
import { makeStyles, styled } from '@material-ui/core/styles';
import { headerHeight } from '../../libraries/helpers';

const PersonSquareStyled = styled(Box)(({
  theme: { spacing },
  image,
}) => ({
  width: 400,
  height: 400,
  position: 'relative',
  overflow: 'hidden',
  marginBottom: spacing(4),
  borderRadius: 30,
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    '& > #info': {
      bottom: 0,
    },
  },
}));

const useStyles = makeStyles(({ breakpoints, spacing, palette: { primary, white } }) => ({
  intro: {
    display: 'flex',
    minHeight: `calc(100vh - ${spacing(headerHeight)}px)`,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: white.main,
    [breakpoints.down('sm')]: {
      paddingTop: 60,
    },
  },

  floating: {
    width: '150vw',
    position: 'absolute',
    bottom: spacing(4),
    left: '50%',
    transform: 'translate(-50%, 0)',
    opacity: 0.45,
    zIndex: 0,
    [breakpoints.down('md')]: {
      width: '220vw',
    },
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  title: {
    marginBottom: 0,
  },

  divider: {
    width: spacing(10),
    border: `2px solid ${white.main}`,
    borderRadius: 999,
    marginTop: spacing(4),
  },

  text: {
    lineHeight: '28px',
    textAlign: 'left',
    padding: spacing(4),
    minWidth: 500,
    maxWidth: '60vw',
    zIndex: 100,
    [breakpoints.down('sm')]: {
      minWidth: 0,
      maxWidth: '100vw',
      textAlign: 'justify',
      lineHeight: '22px',
    },
  },

  body: {
    paddingTop: spacing(4),
  },
  info: {
    position: 'absolute',
    bottom: -150,
    left: 0,
    padding: spacing(4),
    color: white.main,
    zIndex: 2,
    transition: 'bottom 200ms ease-out',
    pointerEvents: 'none',
    '& > h2': { marginBottom: 0 },
    '& > p': { fontWeight: 900 },
  },
  greenHover: {
    position: 'absolute',
    backgroundColor: primary.dark,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: 30,
    zIndex: 1,
    opacity: 0,
    transition: 'opacity 200ms ease',
    '&:hover': {
      opacity: 0.55,
      transition: 'opacity 100ms ease',
    },
  },
}));

export { PersonSquareStyled };
export default useStyles;
