import { useQuery } from '@apollo/react-hooks';
import { getRecipeQuery } from '../../queries/recipes';

export default ({ recipeId }) => {
  const { loading, data } = useQuery(getRecipeQuery, {
    variables: { recipeId },
  });

  return { loading, recipe: data && data.recipe };
};
