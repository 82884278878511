import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BodyContainer from '../../components/BodyContainer';
import OrderTable from './OrderTable';

const OrderHistory = () => {
  const { t } = useTranslation('order');

  return (
    <BodyContainer>
      <Container>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h2">{t('orders-title')}</Typography>
            <Typography>{t('orders-subtitle')}</Typography>
          </Grid>
          <Grid item>
            <OrderTable />
          </Grid>
        </Grid>
      </Container>
    </BodyContainer>
  );
};

export default OrderHistory;
