import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useCartContext } from '../../contexts/CartContext';
import CartItem from '../../components/CartItem';

const CartItems = () => {
  const { t } = useTranslation('order');
  const [
    { cartItems, voidSpacesLeft },
    { removeItem, updateQuantityCart },
  ] = useCartContext();

  return (
    <Grid container>
      {cartItems.map(({
        id,
        dishId,
        title,
        grams,
        unitPrice,
        quantity,
        image,
      }) => (
        <CartItem
          key={id}
          id={id}
          dishId={dishId}
          title={title}
          grams={grams}
          unitPrice={unitPrice}
          quantity={quantity}
          voidSpacesLeft={voidSpacesLeft}
          updateQuantityCart={updateQuantityCart}
          removeItem={removeItem}
          image={image}
        />
      ))}

      <Grid item>
        <Link to="dishes" style={{ fontWeight: 700 }}>
          {t('add-more-dishes')}
        </Link>
      </Grid>
    </Grid>
  );
};

export default CartItems;
