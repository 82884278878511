import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Widget } from 'rasa-webchat';
import useBus from 'use-bus';
import { useTheme } from '@material-ui/core/styles';
import { librawaySessionStorage } from '../../libraries/helpers';
import { useAppContext } from '../../contexts/AppContext';
import useRegisterChatSession from '../../graphql/hooks/chat/useRegisterChatSession';
import useUnregisterChatSession from '../../graphql/hooks/chat/useUnregisterChatSession';
import librawayLogoContrasting from '../../assets/librawayLogoContrasting.svg';

const { REACT_APP_SOCKET_URL, REACT_APP_SOCKET_PATH } = process.env;

window.addEventListener('storage', (e) => {
  console.log('librawaySessionStorage.get(chat_session):', librawaySessionStorage.get('chat_session'));
  if (e.storageArea === sessionStorage) {
  }
  // else, event is caused by an update to localStorage, ignore it
});

const RasaWidget = () => {
  const rasaRef = useRef(null);
  const userIdRef = useRef(null);
  const history = useHistory();
  const [registerChatSession] = useRegisterChatSession();
  const [unregisterChatSession] = useUnregisterChatSession();

  const { i18n: { language } } = useTranslation();
  const { user: [user] } = useAppContext();
  const { palette: { primary, common } } = useTheme();

  const authenticateChat = () => {
    if (userIdRef.current !== user.id) {
      if (user.id) {
        registerChatSession({
          variables: {
            token: rasaRef.current.getSessionId(),
          },
        });
      } else {
        unregisterChatSession({
          variables: {
            token: rasaRef.current.getSessionId(),
          },
        });
      }

      userIdRef.current = user.id;
    }
  };

  useBus(
    '@@rasa/addNote',
    ({ payload }) => {
      rasaRef.current.sendMessage(`/interaction_add_note${JSON.stringify(payload)}`);
    },
    [],
  );

  useEffect(authenticateChat, [user.id]);

  const handleEvents = ({ action, payload }) => {
    switch (action) {
      case 'goTo': {
        const timestamp = +new Date();
        history.push(`${payload.url}${payload.url.includes('?') ? '&' : '?'}timestamp=${timestamp}`);
        break;
      }

      default:
    }
  };

  return (
    <>
      <Widget
        ref={rasaRef}
        initPayload="/start"
        socketUrl={REACT_APP_SOCKET_URL}
        socketPath={REACT_APP_SOCKET_PATH}
        customData={{ language }}
        title="Primo"
        subtitle="Il tuo assistente personale"
        profileAvatar={librawayLogoContrasting}
        mainColor={primary.dark}
        userBackgroundColor={primary.dark}
        userTextColor={common.white}
        showFullScreenButton
        displayUnreadCount
        showCloseButton
        params={{ storage: 'session' }}
        onSocketEvent={{
          bot_uttered: handleEvents,
          connect: authenticateChat,
          disconnect: (event) => { console.log('disconnect', event); },
        }}
      />
    </>
  );
};

export default RasaWidget;
