import React, { createContext, useContext, useState } from 'react';
import { childrenPropType } from '../../../../components/propTypes';

const DiaryContext = createContext([]);

const DiaryProvider = ({ children }) => {
  const diaryState = useState(new Date());

  return (
    <DiaryContext.Provider value={diaryState}>
      {children}
    </DiaryContext.Provider>
  );
};

const useDiaryContext = () => useContext(DiaryContext);

DiaryProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  DiaryContext,
  DiaryProvider,
  useDiaryContext,
};
