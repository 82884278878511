import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MuiDataTable from 'mui-datatables';
import useDateFns from '../../hooks/useDateFns';
import useGetOrders from '../../graphql/hooks/order/useGetOrders';
import { formatMoney } from '../../libraries/helpers';
import LocalLoading from '../../components/LocalLoading';
import Button from '../../components/Button';
import useStyles from './style';

const options = {
  selectableRows: 'none',
  ilter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  customToolbar: false,
  title: false,
  filter: false,
  sort: false,
};

const OrderTable = () => {
  const { t } = useTranslation('order');
  const { tableContainer } = useStyles();
  const history = useHistory();
  const { format } = useDateFns();
  const { loading, data } = useGetOrders({});

  const columns = [
    { label: '' },
    { label: t('thead-date') },
    { label: t('thead-status') },
    { label: t('thead-total') },
    { label: '' },
  ];

  if (loading || !data) return <LocalLoading />;

  const tabletizeData = data.map(({
    node: {
      id,
      insertDate,
      label,
      status,
      total,
    },
  }) => ([
    `#${id}`,
    format(new Date(insertDate.replace(' ', 'T')), 'd MMMM'),
    status,
    formatMoney(total),
    <Button
      onClick={() => { history.push(`/order-details/${label.split('#')[1]}`); }}
    >
      {t('view-detail')}
    </Button>,
  ]));

  return (
    <div className={tableContainer}>
      <MuiDataTable
        data={tabletizeData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default OrderTable;
