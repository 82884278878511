import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';

const Logout = () => {
  const { logout } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    logout();
    history.push('/');
  }, []);

  return null;
};

export default Logout;
