import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { nullType } from '../../libraries/helpers';
import RecipeContext from './contexts';
import Rating from '../../components/Rating';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as CookingIcon } from '../../assets/icons/cooking.svg';
import { ReactComponent as HatIcon } from '../../assets/icons/hat.svg';
import { ReactComponent as PriceIcon } from '../../assets/icons/price.svg';
import useStyles from './style';

const costMap = [
  'VERYLOW',
  'LOW',
  'NORMAL',
  'HIGH',
  'VERYHIGH',
];

const difficultyMap = [
  'VERYEASY',
  'EASY',
  'MEDIUM',
  'HARD',
  'VERYHARD',
];

const Info = () => {
  const { infoContainer, labelStyle } = useStyles();
  const { t } = useTranslation('recipe');
  const recipe = useContext(RecipeContext);

  const getDurationHumanized = (stringDuration) => {
    const [hour, minute] = stringDuration.split(':');
    const intHour = parseInt(hour, 10);
    const intMinute = parseInt(minute, 10);
    const stringHour = intHour ? t('hour', { count: intHour }) : null;
    const stringMinute = intMinute ? t('minute', { count: intMinute }) : null;
    return `${stringHour || ''}${stringHour && stringMinute ? ' ' : ''}${stringMinute || ''}`;
  };

  const infos = [
    {
      label: 'difficulty',
      icon: HatIcon,
      getValue: () => (
        recipe.difficulty
          ? <Rating value={difficultyMap.findIndex((item) => item === recipe.difficulty) + 1} />
          : nullType
      ),
    }, {
      label: 'preparation-time',
      icon: ClockIcon,
      getValue: () => ((recipe.preparationTime && recipe.preparationTime !== '00:00:00')
        ? getDurationHumanized(recipe.preparationTime)
        : nullType),
    }, {
      label: 'cooking-time',
      icon: CookingIcon,
      getValue: () => ((recipe.cookingTime && recipe.cookingTime !== '00:00:00')
        ? getDurationHumanized(recipe.cookingTime)
        : nullType),
    }, {
      label: 'price',
      icon: PriceIcon,
      getValue: () => (recipe.cost
        ? <Rating value={costMap.findIndex((item) => item === recipe.cost) + 1} />
        : nullType),
    },
  ];

  /* eslint-disable react/prop-types */
  const renderRow = ({
    label,
    icon: Icon,
    getValue,
  }) => (
    <Grid
      key={label}
      item
      container
      direction="row"
      spacing={1}
    >
      <Grid item container xs={1}><Icon /></Grid>
      <Grid item container xs={6} className={labelStyle}>{t(label)}</Grid>
      <Grid item container xs={5} alignItems="center">{getValue()}</Grid>
    </Grid>
  );
  /* eslint-enable react/prop-types */

  return (
    <Grid container direction="column" classes={{ root: infoContainer }}>
      {infos.map(renderRow)}
    </Grid>
  );
};

export default Info;
