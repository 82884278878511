import { useMutation } from '@apollo/react-hooks';
import { manageVoteMutation } from '../../mutations/generic';

export default () => {
  const [
    manageVote,
    { loading, data, error },
  ] = useMutation(
    manageVoteMutation,
    { refetchQueries: ['Recipe'] },
  );

  return [manageVote, { loading, data, errors: error && error.graphQLErrors }];
};
