import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { formatMoney } from '../../libraries/helpers';
import useStyles from './style';

const Price = ({ price, discountedPrice }) => {
  const { finalPrice, captionPrice, oldPrice } = useStyles({ sale: !!discountedPrice });

  if (!price) return null;

  return (
    <>
      <Typography className={captionPrice} variant="caption">standard</Typography>
      <Typography className={finalPrice}>{formatMoney(discountedPrice || price)}</Typography>
      {discountedPrice && <Typography className={oldPrice}>{formatMoney(price)}</Typography>}
    </>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
};

Price.defaultProps = {
  price: null,
  discountedPrice: null,
};

export default Price;
