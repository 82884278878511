import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useResponsive = () => {
  const { breakpoints } = useTheme();

  return {
    isSmallView: useMediaQuery(breakpoints.down('sm')),
  };
};

export default useResponsive;
