import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { defaultUpdateQuery } from '../../../libraries/helpers';
import { getOrdersQuery } from '../../queries/order';

const paginationCount = 100;

export default ({ lazy = false }) => {
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const [getOrders, { loading, data, fetchMore }] = useLazyQuery(getOrdersQuery, {
    variables: {
      first: paginationCount,
    },
  });

  useEffect(() => {
    if (!lazy) {
      getOrders();
    }
  }, [getOrders, lazy]);

  const { me: { orders: { edges, pageInfo } } } = data || { me: { orders: {} } };

  return {
    getOrders,
    loading,
    data: edges || data,
    loadingFetchMore,
    hasNextPage: pageInfo && pageInfo.hasNextPage,
    fetchMore: data
      ? () => {
        setLoadingFetchMore(true);
        fetchMore({
          variables: { after: pageInfo ? pageInfo.endCursor : null },
          updateQuery: defaultUpdateQuery('orders', setLoadingFetchMore),
        });
      }
      : fetchMore,
  };
};
