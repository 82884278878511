import { useMutation } from '@apollo/react-hooks';
import { switchMealMutation } from '../../mutations/plans';
import { refetchQueries } from '../../constants';

export default () => {
  const [switchMeal, { loading, data, errors }] = useMutation(
    switchMealMutation,
    { refetchQueries },
  );

  return {
    switchMeal,
    loading,
    data: data && data.switchMeal,
    errors,
  };
};
