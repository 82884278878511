import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useGetCheckoutSession from '../../graphql/hooks/order/useGetCheckoutSession';
import { CartContext } from '../../contexts/CartContext';
import { formatMoney } from '../../libraries/helpers';
import BodyContainer from '../../components/BodyContainer';
import LocalLoading from '../../components/LocalLoading';
import useStyles from './style';
import CartItem from '../../components/CartItem';

const PaymentLanding = () => {
  const { response } = useParams();
  const { loading, data } = useGetCheckoutSession({ orderSessionId: response });
  const { summaryContainer } = useStyles();
  const { t } = useTranslation('order');
  const [, { cleanCart, cleanOrderId }] = useContext(CartContext);

  useEffect(() => {
    if (response) {
      cleanCart();
      cleanOrderId();
    }
    // eslint-disable-next-line
  }, [response]);

  if (!response) {
    return (
      <Redirect
        to={{
          pathname: '/order',
          state: { from: 'payment' },
        }}
      />
    );
  }

  if (loading) return <LocalLoading />;

  const {
    value,
    order: {
      id: orderId,
      name,
      surname,
      shipping: {
        nameContact,
        surnameContact,
        address,
        zip,
        city,
        state,
        date,
        phone,
        email,
      },
      cart: { items },
      coupons,
    },
  } = data;

  const renderItems = () => items.map(({
    id,
    label,
    quantity,
    price,
    menuIngredients,
  }) => (
    <CartItem
      key={id}
      dishId={menuIngredients && menuIngredients.menu.id}
      title={label}
      quantity={quantity}
      unitPrice={price}
      image={menuIngredients && menuIngredients.menu.image[0]}
      notEditable
    />
  ));

  return (
    <BodyContainer>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h2">{`${t('order')} #${orderId}`}</Typography>
          </Grid>

          <Grid item xs={12} sm={7}>
            <Grid container direction="column" spacing={4}>

              <Grid item container direction="column" justify="space-between">
                <Grid item>
                  <Typography variant="h3">{t('delivery-address')}</Typography>
                </Grid>
                <Grid item>
                  <p>
                    {`${nameContact} ${surnameContact}`}
                    <br />
                    {`${address} - ${zip} ${city}`}
                    {state ? `(${state})` : null}
                  </p>
                </Grid>
                <Grid item>
                  <Divider color="grey" />
                </Grid>
              </Grid>

              <Grid item container direction="column" justify="space-between">
                <Grid item>
                  <Typography variant="h3">{t('delivery-date')}</Typography>
                </Grid>
                <Grid item>
                  <p>{date && format(new Date(date), 'd MMMM yyyy')}</p>
                </Grid>
                <Grid item>
                  <Divider color="grey" />
                </Grid>
              </Grid>

              <Grid item container direction="column" justify="space-between">
                <Grid item>
                  <Typography variant="h3">{t('delivery-contact')}</Typography>
                </Grid>
                <Grid item>
                  <p>
                    {`${name} ${surname}`}
                    <br />
                    {email}
                    <br />
                    {phone}
                  </p>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={5}>
            <div className={summaryContainer}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h2">{t('order-summary')}</Typography>
                </Grid>

                <Grid item>
                  <Grid container>
                    {renderItems()}
                  </Grid>
                </Grid>

                <Grid item><Divider color="grey" /></Grid>

                {coupons.length ? (
                  <Grid item container justify="space-between">
                    <Grid item xs={8} container alignItems="center">
                      <Typography variant="h3">
                        {t('coupon')}
                        <Typography variant="caption">{` (${coupons[0].coupon.label})`}</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} container alignItems="center" justify="flex-end">
                      {coupons[0].coupon.code}
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item container justify="space-between">
                  <Grid item xs={8} container alignItems="center">
                    <Typography variant="h3">{t('total')}</Typography>
                  </Grid>
                  <Grid item xs={3} container alignItems="center" justify="flex-end">
                    <b>{formatMoney(value)}</b>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </BodyContainer>
  );
};

export default PaymentLanding;
