import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { secondary, white } }) => ({
  unitContainer: {
    '& > * + *': {
      marginLeft: spacing(0.5),
    },
  },

  unit: {
    width: spacing(2),
    height: spacing(2),
    borderRadius: 999,
    border: `1px solid ${secondary.main}`,
    backgroundColor: ({ filled }) => (filled ? secondary.main : white.main),
  },
}));

export default useStyles;
