import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { childrenPropType } from './propTypes';
import { headerHeight, headerMobileHeight } from '../libraries/helpers';

const StyledContainer = styled(({ children, className, style }) => (
  <Box className={className} style={style}>{children}</Box>
))(({
  theme: { spacing, palette: { common }, breakpoints },
  backgroundCover,
  backgroundImage,
  backgroundFixed,
  position,
}) => {
  const styles = {
    position: 'relative',
    paddingTop: (!position || position === 'top') && spacing(headerHeight),
    paddingBottom: (!position || position === 'bottom') && spacing(16),
    [breakpoints.down('md')]: {
      paddingTop: (!position || position === 'top') && spacing(headerMobileHeight),
    },
  };

  if (backgroundImage) {
    styles.backgroundImage = `url(${backgroundImage})`;
    styles.backgroundSize = backgroundCover ? 'cover' : 'auto';
    styles.backgroundPosition = 'center';
    styles.backgroundRepeat = 'no-repeat';
    styles.backgroundAttachment = backgroundFixed ? 'fixed' : 'scroll';
    styles[breakpoints.down('sm')] = {
      backgroundPosition: 'left',
      backgroundAttachment: 'scroll',
    };
  } else {
    styles.backgroundColor = common.white;
  }

  return styles;
});

const BodyContainer = ({
  children,
  className,
  style,
  backgroundCover,
  backgroundImage,
  backgroundFixed,
  position,
}) => (
  <StyledContainer
    className={className}
    style={style}
    backgroundCover={backgroundCover}
    backgroundImage={backgroundImage}
    backgroundFixed={backgroundFixed}
    position={position}
  >
    {children}
  </StyledContainer>
);

BodyContainer.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  backgroundCover: PropTypes.bool,
  backgroundImage: PropTypes.string,
  backgroundFixed: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'middle', 'bottom']),
};

BodyContainer.defaultProps = {
  className: null,
  style: null,
  backgroundCover: false,
  backgroundImage: null,
  backgroundFixed: false,
  position: undefined,
};

export default BodyContainer;
