import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RecipeContext from '../contexts';
import DosesContext from './contexts';
import { renderIngredientQuantity } from '../../../libraries/helpers';
import useGetShoppingLists from '../../../graphql/hooks/shoppingList/getShoppingLists';
import useAddShoppingListItems from '../../../graphql/hooks/shoppingList/updateShoppingList';
import Select from '../../../components/Select';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';

const interestringIngredientKeys = [
  'id',
  'ingredient',
  'quantity',
  'unit',
];

const ShoppingListDialog = ({ ingredients, recipeId }) => {
  const history = useHistory();
  const { t } = useTranslation(['shoppingList', 'ingredients']);
  const { breakpoints } = useTheme();
  const isExtraSmallView = useMediaQuery(breakpoints.down('xs'));
  const { people } = useContext(RecipeContext);
  const [doses] = useContext(DosesContext);
  const [selectedShoppingListId, setSelectedShoppingListId] = useState('new');
  const [itemsToAdd, setItemsToAdd] = useState(ingredients.map(
    (ingredient) => pick(ingredient, interestringIngredientKeys),
  ));
  const [addShoppingListItems, { data }] = useAddShoppingListItems();
  const { loading, shoppingLists } = useGetShoppingLists();

  useEffect(() => {
    if (data) {
      history.push('/shopping-lists');
    }
  }, [data]);

  const handleShoppingListChange = ({ target: { value } }) => { setSelectedShoppingListId(value); };

  const handleCheckboxChange = (ingredient) => ({ target: { checked } }) => {
    if (!checked) {
      setItemsToAdd(itemsToAdd.filter(({ id: ingredientId }) => ingredient.id !== ingredientId));
    } else {
      setItemsToAdd([...itemsToAdd, ingredient]);
    }
  };

  const handleAddToShoppingList = () => {
    addShoppingListItems({
      variables: {
        id: selectedShoppingListId !== 'new' ? selectedShoppingListId : undefined,
        type: 'RECIPES',
        ref: recipeId,
        items: itemsToAdd.map(({ id, ingredient, ...otherKeys }) => (
          { ...otherKeys, item: ingredient }
        )),
      },
    });
  };

  /* eslint-disable react/prop-types */
  const renderShoppingList = ({ id, title }) => (
    <option key={id} value={id}>{title}</option>
  );

  const renderIngredient = ({
    id,
    ingredient,
    quantity,
    unit,
  }) => (
    <Grid
      container
      key={id}
      alignItems="center"
    >
      <Grid item>
        <FormControlLabel
          control={(
            <Checkbox
              defaultChecked
              id={id}
              onChange={handleCheckboxChange({
                id,
                ingredient,
                quantity,
                unit,
              })}
            />
          )}
          label={`${ingredient} (${renderIngredientQuantity(quantity, doses, people, unit, t)})`}
        />
      </Grid>
    </Grid>
  );
  /* eslint-enable react/prop-types */

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h3">{t('recipe-ingredients')}</Typography>
        <Divider color="primary" marginBottom={1} />
        {ingredients.map(renderIngredient)}
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12} sm="auto">
            {loading ? <Loading /> : (
              <Select
                variant="outlined"
                value={selectedShoppingListId}
                onChange={handleShoppingListChange}
                small
              >
                <option value="new">{t('new-shopping-list')}</option>
                {shoppingLists.map(renderShoppingList)}
              </Select>
            )}
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              fitted
              filled
              stretched={isExtraSmallView}
              onClick={handleAddToShoppingList}
            >
              {t('add-to-list')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ShoppingListDialog.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  recipeId: PropTypes.number.isRequired,
};

export default ShoppingListDialog;
