import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '../Input';
import useStyles from './style';

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };
const geocoderService = { current: null };

const PlacesAutocomplete = ({
  dense,
  label,
  onPlaceSelected,
  defaultValue,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAPv32EwAoOmvSVbgkOh6fWl5gYd06qsDU&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () => throttle((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (value) {
      if (!geocoderService.current && window.google) {
        geocoderService.current = new window.google.maps.Geocoder();
      }
      if (!geocoderService.current) return;

      geocoderService.current.geocode({ placeId: value.place_id }, onPlaceSelected);
    }
  }, [value]);

  return (
    <Autocomplete
      id="google-map-demo"
      freeSolo
      classes={{ endAdornment: classes.endAdornment }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value || defaultValue}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={({
        InputProps: {
          className,
          endAdornment,
          ref,
          startAdornment,
        },
        disabled,
        fullWidth,
        inputProps,
      }) => (
        <Input
          autocomplete="chrome-off"
          dense={dense}
          label={label}
          className={className}
          endAdornment={endAdornment}
          startAdornment={startAdornment}
          ref={ref}
          required
          fullWidth={fullWidth}
          disabled={disabled}
          inputProps={inputProps}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map(({ highlight, text }) => (
                <span key={text} style={{ fontWeight: highlight ? 700 : 400 }}>
                  {text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

PlacesAutocomplete.propTypes = {
  dense: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onPlaceSelected: PropTypes.func,
  defaultValue: PropTypes.string,
};

PlacesAutocomplete.defaultProps = {
  dense: false,
  onPlaceSelected: () => {},
  defaultValue: '',
};

export default PlacesAutocomplete;
