import { useQuery } from '@apollo/react-hooks';
import { getWeightsQuery } from '../../queries/plans';

export default () => {
  const { loading, data } = useQuery(getWeightsQuery);

  const mapWeights = ({ date, weight }) => ({
    x: date,
    y: weight,
  });

  return {
    loading,
    data: (
      data
      && data.me.weights
      && Array.isArray(data.me.weights)
        ? data.me.weights.map(mapWeights)
        : []
    ),
  };
};
