import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Placeholder = ({ text }) => {
  const { palette: { grey } } = useTheme();

  return (
    <div style={{ width: '100%', padding: 50 }}>
      <Typography
        align="center"
        style={{ color: grey.dark }}
      >
        {text}
      </Typography>
    </div>
  );
};

Placeholder.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Placeholder;
