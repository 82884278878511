import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  palette: {
    secondary,
    grey,
    facebook,
    google,
  }, spacing,
}) => ({
  container: {
    background: grey.main,
    minHeight: '100vh',
    overflowY: 'auto',
    padding: spacing(4, 2),
  },

  formContainer: {
    maxWidth: 454,
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: spacing(4),
    padding: spacing(4),
    '& > * + *, & > form > * + *': {
      marginTop: spacing(2),
    },
  },

  title: { fontWeight: 900 },

  subButton: {
    marginTop: spacing(),
    '& > a > span': {
      color: secondary.main,
      fontWeight: 900,
    },
  },

  facebook: {
    backgroundColor: facebook.main,
    borderColor: facebook.dark,
  },

  google: {
    backgroundColor: google.main,
    borderColor: google.dark,
  },
}));

export default useStyles;
