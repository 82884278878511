import React from 'react';
import useBus from 'use-bus';
import { ThemeProvider } from '@material-ui/styles';
import librawayV2Theme from '../../theme/v2';
import useModalOpen from '../../hooks/useModalOpen';
import { librawayStorage } from '../../libraries/helpers';
import Dialog from '../Dialog';
import MainContent from './MainContent';

const WelcomeModal = () => {
  const { opened, closeModal, openModal } = useModalOpen(!librawayStorage.get('welcomeModal'));

  librawayStorage.set('welcomeModal', true);

  useBus('@@newsletterModal/open', openModal);

  return (
    <ThemeProvider theme={librawayV2Theme}>
      <Dialog
        open={opened}
        onClose={closeModal}
        noPadding
        minWidth={600}
      >
        <MainContent />
      </Dialog>
    </ThemeProvider>
  );
};

export default WelcomeModal;
