import { useReducer, useEffect } from 'react';
import useGetPlanRecap from '../graphql/hooks/plans/useGetPlanRecap';
import useGetPlanToday from '../graphql/hooks/plans/useGetPlanToday';
import useGetPlanWeeks from '../graphql/hooks/plans/useGetPlanWeeks';
import useGetPlanWeek from '../graphql/hooks/plans/useGetPlanWeek';
import useSetTracking from '../graphql/hooks/plans/useSetTracking';
import useSwitchMeal from '../graphql/hooks/plans/useSwitchMeal';
import useSwitchDay from '../graphql/hooks/plans/useSwitchDay';

const defaultStructure = { loading: true, lastUpdate: null, data: {} };
const initialState = { today: defaultStructure, week: defaultStructure, loading: false };

const planReducer = (state, data) => {
  if (data.type === 'selectedWeek') {
    return {
      ...state,
      plan: {
        ...state.plan,
        data: {
          ...state.plan.data,
          num: data.payload.num,
          planWeek: data.payload.planWeek,
        },
      },
    };
  }

  if (data.type === 'loading') {
    return {
      ...state,
      loading: data.payload,
    };
  }

  if (data.type === 'mutationConfirm') {
    return {
      ...state,
      loading: false,
      lastUpdate: data.payload,
    };
  }

  return {
    ...state,
    loading: data.payload.loading !== undefined
      ? data.payload.loading
      : state.loading,
    [data.type]: {
      ...state[data.type],
      ...data.payload,
      lastUpdate: new Date(),
    },
  };
};

const usePlan = () => {
  const [state, dispatch] = useReducer(planReducer, initialState);
  const { today, plan = {} } = state;
  const { selectedWeek, currentWeek, planWeek } = plan.data || {};
  const selectedWeekNumber = () => (selectedWeek && selectedWeek.num) || currentWeek;

  /**
   * Dispatchers
   */

  const dispatchMutationResponse = ({ localLoading }) => {
    if (localLoading) {
      dispatch({
        type: 'loading',
        payload: true,
      });
    } else {
      dispatch({
        type: 'mutationConfirm',
        payload: new Date(),
      });
    }
  };

  const dispatchQueryResponse = ({ type, localLoading, localData }) => {
    dispatch({
      type,
      payload: {
        loading: localLoading,
        data: localData,
      },
    });
  };

  /**
   * Mutations
   */

  const {
    setTracking,
    loading: setTrackingLoading,
    data: setTrackingData,
  } = useSetTracking();
  const enhancedSetTracking = (options) => {
    setTracking({
      variables: {
        ...options,
        planWeek: (selectedWeek && selectedWeek.planWeek) || planWeek,
      },
    });
  };
  useEffect(() => {
    dispatchMutationResponse({ localLoading: setTrackingLoading });
  }, [setTrackingData, setTrackingLoading]);

  const {
    switchMeal,
    loading: switchMealLoading,
    data: switchMealData,
  } = useSwitchMeal();
  const enhancedSwitchMeal = (options) => {
    switchMeal({
      variables: {
        ...options,
        planWeek: (selectedWeek && selectedWeek.planWeek) || planWeek,
      },
    });
  };
  useEffect(() => {
    dispatchMutationResponse({ localLoading: switchMealLoading });
  }, [switchMealData, switchMealLoading]);

  // Switch Day
  const {
    switchDay,
    loading: switchDayLoading,
    data: switchDayData,
  } = useSwitchDay();
  const enhancedSwitchDay = (options) => {
    switchDay({
      variables: {
        ...options,
        planWeek: (selectedWeek && selectedWeek.planWeek) || planWeek,
      },
    });
  };
  useEffect(() => {
    dispatchMutationResponse({ localLoading: switchDayLoading });
  }, [switchDayData, switchDayLoading]);

  /**
   * Queries
   */

  const {
    getPlanRecap,
    loading: planRecapLoading,
    data: planRecapData,
  } = useGetPlanRecap();
  useEffect(() => {
    if (planRecapData !== undefined && planRecapLoading !== undefined) {
      dispatch({
        type: 'plan',
        payload: {
          loading: planRecapLoading,
          data: planRecapData,
        },
      });
    }
  }, [planRecapData, planRecapLoading]);

  const {
    getPlanToday,
    loading: planTodayLoading,
    data: planTodayData,
  } = useGetPlanToday();
  useEffect(() => {
    dispatchQueryResponse({
      type: 'today',
      localLoading: planTodayLoading,
      localData: planTodayData,
    });
  }, [planTodayData, planTodayLoading]);

  const {
    getPlanWeek,
    loading: planWeekLoading,
    data: planWeekData,
  } = useGetPlanWeek();
  useEffect(() => {
    dispatchQueryResponse({
      type: 'week',
      localLoading: planWeekLoading,
      localData: planWeekData,
    });
  }, [planWeekData, planWeekLoading]);

  const {
    getPlanWeeks,
    loading: planWeeksLoading,
    data: planWeeksData,
  } = useGetPlanWeeks();
  useEffect(() => {
    dispatchQueryResponse({
      type: 'weeks',
      localLoading: planWeeksLoading,
      localData: planWeeksData,
    });
  }, [planWeeksData, planWeeksLoading]);

  /**
   * Others
   */

  const switchToday = (day) => {
    switchDay({
      variables: {
        fromDay: today.data.day.day,
        toDay: day,
      },
    });
  };

  const setSelectedWeek = ({ num, planWeek: localPlanWeek }) => {
    dispatch({
      type: 'selectedWeek',
      payload: { num, planWeek: localPlanWeek },
    });
  };

  /**
   * Returns
   */

  return [{
    ...state,
    selectedWeekNumber: selectedWeekNumber(),
  }, {
    getPlanRecap,
    getPlanToday,
    getPlanWeeks,
    getPlanWeek,
    setTracking: enhancedSetTracking,
    setSelectedWeek,
    switchToday,
    switchMeal: enhancedSwitchMeal,
    switchDay: enhancedSwitchDay,
  }];
};

export default usePlan;
