import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useManageFavourite from '../../graphql/hooks/generic/useManageFavourite';
import { useAuthContext } from '../../contexts/AuthContext';
import Button from '../../components/Button';
import { ReactComponent as HearthIcon } from '../../assets/icons/hearth.svg';
import useStyles from './style';

const FavouriteButton = ({ type, reference, isFavourite }) => {
  const { t } = useTranslation('recipe');
  const history = useHistory();
  const { pathname } = useLocation();
  const { icon } = useStyles({ isFavourite });
  const { isLoggedIn } = useAuthContext();
  const [manageFavourite] = useManageFavourite();

  const handleClick = () => {
    if (!isLoggedIn) {
      history.push(`/signin?redirectTo=${pathname}`);
    } else {
      manageFavourite({
        variables: {
          action: isFavourite ? 'REMOVE' : 'ADD',
          type,
          ref: reference,
        },
      });
    }
  };

  return (
    <Button
      fat
      stretched
      onClick={handleClick}
    >
      <HearthIcon className={icon} />
      {t(`${isFavourite ? 'remove' : 'add'}-to-favourites`)}
    </Button>
  );
};

FavouriteButton.propTypes = {
  type: PropTypes.oneOf(['MENUS', 'RECIPES', 'RESTAURANTS']).isRequired,
  reference: PropTypes.string.isRequired,
  isFavourite: PropTypes.bool,
};

FavouriteButton.defaultProps = {
  isFavourite: false,
};

export default FavouriteButton;
