import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { UserInfoContext } from './UserInfoContext';
import { ActivatedStepProvider } from './ActivatedStepContext';
import Address from './userInfoSteps/Address';
import DeliveryDate from './userInfoSteps/DeliveryDate';
import DeliveryContact from './userInfoSteps/DeliveryContact';
import UserInfoStep from './UserInfoStep';
import Button from '../../components/Button';
import useStyles from './style';

const userInfoSteps = [{
  title: 'delivery-address',
  subTitle: 'delivery-address-subtitle',
  component: Address,
}, {
  title: 'delivery-date',
  subTitle: 'delivery-date-subtitle',
  component: DeliveryDate,
}, {
  title: 'delivery-contact',
  subTitle: 'delivery-contact-subtitle',
  component: DeliveryContact,
}];

const OrderUserInfo = ({ nextStep }) => {
  const { t } = useTranslation('order');
  const { buttonContainer } = useStyles();
  const { userInfoDone } = useContext(UserInfoContext);

  // eslint-disable-next-line react/prop-types
  const renderUserInfoStep = ({ title, subTitle, component }, index) => (
    <UserInfoStep
      key={title}
      index={index}
      title={t(title)}
      subTitle={t(subTitle)}
      component={component}
      lastStep={userInfoSteps.length === index + 1}
    />
  );

  return (
    <Grid container direction="column" style={{ height: '100%' }}>
      <Grid item style={{ flexGrow: 1 }}>
        <ActivatedStepProvider>
          {userInfoSteps.map(renderUserInfoStep)}
        </ActivatedStepProvider>
      </Grid>

      <Zoom in={userInfoDone}>
        <Grid
          item
          className={buttonContainer}
          style={{ textAlign: 'center', justifySelf: '' }}
        >
          <Tooltip
            title={t('need-complete-user-info')}
            placement="top"
            enterDelay={300}
            disableHoverListener={!userInfoDone}
            arrow
          >
            <div>
              <Button
                filled
                disabled={!userInfoDone}
                onClick={nextStep}
                style={{ marginBottom: 48 }}
              >
                {t('proceed-to-purchase')}
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Zoom>
    </Grid>
  );
};

OrderUserInfo.propTypes = {
  nextStep: PropTypes.func,
};

OrderUserInfo.defaultProps = {
  nextStep: () => {},
};

export default OrderUserInfo;
