import gql from 'graphql-tag';

const orderFragment = gql`
  fragment MainInfoOrder on Order {
    id
    label
    insertDate: created_at
    status
    total
    name: first_name
    surname: last_name
    cart {
      items {
        id
        label
        quantity
        price
        menuIngredients: menu_ingredient {
          additional_cost
          grams
          dimension
          menu {
            id
            title
            image {
              thumbnail
              thumbnail2x
              thumbnail3x
            }
          }
        }
      }
    }
    shipping {
      address
      date
      zip
      city
      state
      country
      nameContact: first_name
      surnameContact: last_name
      phone
      company
      status
      email
    }
    coupons {
      coupon {
        label
        amount
        type
        code
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { orderFragment };
