import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import librawayV2Theme from '../../theme/v2';
import BodyContainer from '../../components/BodyContainer';
import MetaTags from '../../components/MetaTags';
import Presentation from './Presentation';
import HowItWorksPlans from './HowItWorksPlans';
import FoodMap from './FoodMap';
import Plans from './Plans';
import sharePreview from '../../assets/sharePreview.png';
// import InfoForm from './Plans/InfoForm';

const HowItWorks = () => {
  const { t } = useTranslation('howItWorks');

  return (
    <>
      <MetaTags
        title={`${t('title')} - Libraway`}
        description={t('meta-description')}
        image={sharePreview}
      />
      <ThemeProvider theme={librawayV2Theme}>
        <BodyContainer backgroundFixed style={{ paddingBottom: 0 }}>
          <Presentation />
          <HowItWorksPlans />
          <FoodMap />
          <Plans />
          {/* <InfoForm /> */}
        </BodyContainer>
      </ThemeProvider>
    </>
  );
};

export default HowItWorks;
