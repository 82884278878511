import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import useLocationQuery from '../../hooks/useLocationQuery';
import useGetShoppingLists from '../../graphql/hooks/shoppingList/getShoppingLists';
import useCreateOrUpdateShoppingList from '../../graphql/hooks/shoppingList/createOrUpdateShoppingList';
import useDeleteShoppingList from '../../graphql/hooks/shoppingList/deleteShoppingList';
import BodyContainer from '../../components/BodyContainer';
import LocalLoading from '../../components/LocalLoading';
import Tab from '../../components/Tab';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import TabLabel from './TabLabel';
import Items from './Items';
import AddIngredient from './AddIngredient';
import useStyles from './style';

const ShoppingLists = () => {
  const { t } = useTranslation('shoppingList');
  const { shoppingListId } = useParams();
  const query = useLocationQuery();
  const {
    actionStyle,
    tabContainer,
    tabsButtonRow,
    tabButtonContainer,
  } = useStyles();
  const [currentTab, setCurrentTab] = useState(shoppingListId);
  const [confirmModal, setConfirmModal] = useState(false);
  const handleConfirmModalOpen = () => { setConfirmModal(true); };
  const handleConfirmModalClose = () => { setConfirmModal(false); };
  const [getShoppingLists, { loading, shoppingLists }] = useGetShoppingLists();
  const [createOrUpdateShoppingList] = useCreateOrUpdateShoppingList();
  const [deleteShoppingList] = useDeleteShoppingList();
  const currentShoppingList = shoppingLists && shoppingLists.find(({ id }) => id === currentTab);

  useEffect(getShoppingLists, [shoppingListId, query.get('timestamp')]);

  useEffect(() => {
    if (
      shoppingLists
      && shoppingLists.length
      && !currentShoppingList
    ) {
      setCurrentTab(shoppingLists[0].id);
    }
  }, [shoppingLists]);

  useEffect(() => { setCurrentTab(shoppingListId); }, [shoppingListId]);

  if (loading) return <LocalLoading />;

  const handleCurrentTab = (id) => () => { setCurrentTab(id); };

  const removeList = () => {
    deleteShoppingList({ variables: { id: currentTab } });
    handleConfirmModalClose();
  };

  const deleteIcon = () => (
    <ButtonBase
      className={actionStyle}
      onClick={handleConfirmModalOpen}
    >
      <FontAwesomeIcon
        size="sm"
        icon={faTrashAlt}
      />
    </ButtonBase>
  );

  // eslint-disable-next-line react/prop-types
  const renderTab = ({ id, title }) => (
    <div className={tabButtonContainer} key={id}>
      <Tab
        index={id}
        label={<TabLabel id={id} label={title} />}
        currentTab={currentTab}
        onClick={handleCurrentTab(id)}
        action={deleteIcon()}
        mainColor="primary"
        minWidth
        maxWidth
      />
    </div>
  );

  const renderAddTab = () => (
    <div className={tabButtonContainer}>
      <Tab
        index="add"
        label={t('new-list')}
        onClick={createOrUpdateShoppingList}
        mainColor="secondary"
      />
    </div>
  );

  return (
    <>
      <BodyContainer>
        <Container>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h2">{t('shopping-lists-title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={tabsButtonRow}>
                {shoppingLists && shoppingLists.map(renderTab)}
                {renderAddTab()}
              </div>
              <Grid container>
                <Grid item xs={12} className={tabContainer}>
                  <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Items
                        data={currentShoppingList && currentShoppingList.shoppingListGroupedItems}
                        shoppingListId={currentShoppingList && currentShoppingList.id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AddIngredient
                        shoppingListId={currentShoppingList && currentShoppingList.id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </BodyContainer>

      <Dialog open={confirmModal} onClose={handleConfirmModalClose}>
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <Typography variant="h3">{t('common:caution')}</Typography>
          </Grid>
          <Grid item>
            <Typography>{t('dialog-confirm-delete')}</Typography>
          </Grid>
          <Grid item style={{ marginTop: 20 }}>
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <Button onClick={handleConfirmModalClose}>{t('common:cancel')}</Button>
              </Grid>
              <Grid item>
                <Button onClick={removeList} filled>{t('common:confirm')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ShoppingLists;
