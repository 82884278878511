import React from 'react';
import BodyContainer from '../../components/BodyContainer';


const NoMatch = () => (
  <BodyContainer style={{ textAlign: 'center' }}>
      404 | Page not found
  </BodyContainer>
);

export default NoMatch;
