import { useLazyQuery } from '@apollo/react-hooks';
import { getPlanDayQuery } from '../../queries/plans';

export default () => {
  const [getPlanDay, { loading, data }] = useLazyQuery(getPlanDayQuery);

  return {
    getPlanDay,
    loading,
    data: data && data.getPlanDay,
  };
};
