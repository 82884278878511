import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Zoom from '@material-ui/core/Zoom';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import useDateFns from '../../hooks/useDateFns';
import useReactHookForm from '../../hooks/useReactHookForm';
import useGetRegistry from '../../graphql/hooks/user/useGetRegistry';
import useUpdateUserProfile from '../../graphql/hooks/user/useUpdateUserProfile';
import CollapseError from '../../components/CollapseError';
import LocalLoading from '../../components/LocalLoading';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/Input';
import Button from '../../components/Button';
import useStyles from './style';

const Registry = ({ editMode, setEditMode }) => {
  const { t } = useTranslation('profile');
  const { submitContainer } = useStyles();
  const { format } = useDateFns();
  const { loading, data } = useGetRegistry();
  const [currentBirthdate, setCurrentBirthdate] = useState(data?.birthdate || null);
  const {
    useForm,
    registers: {
      getRequiredMinLength,
      getRequiredEmail,
      getRequired,
    },
  } = useReactHookForm();
  const [updateUserProfile, { errors: errorsRequest }] = useUpdateUserProfile();
  const {
    register,
    triggerValidation,
    errors,
    getValues,
  } = useForm();

  const handleSubmitClick = async () => {
    if (editMode) {
      const validation = await triggerValidation();

      if (validation) {
        updateUserProfile({
          variables: {
            ...getValues(),
            birthdate: format(currentBirthdate, 'yyyy-MM-dd'),
          },
        });
        setEditMode(false);
      }
    } else {
      setEditMode(true);
    }
  };

  if (loading) return <LocalLoading />;

  const {
    name,
    surname,
    height,
    weight,
    gender,
    email,
  } = data;

  const renderErrors = () => Object.keys(errors).map((fieldName) => (
    <Zoom in key={fieldName}>
      <Alert key={`${fieldName}-error`} severity="error">
        {errors[fieldName].message}
      </Alert>
    </Zoom>
  ));

  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12} sm={6}>
        <Input
          readOnly={!editMode}
          disabled={!editMode}
          label={t('name')}
          name="name"
          value={name}
          required
          ref={register(getRequiredMinLength(t('name')))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          readOnly={!editMode}
          disabled={!editMode}
          label={t('surname')}
          name="surname"
          value={surname}
          required
          ref={register(getRequiredMinLength(t('surname')))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Input
          required
          readOnly={!editMode}
          disabled={!editMode}
          label={t('height')}
          type="number"
          endAdornment="cm"
          value={height}
          name="height"
          ref={register(getRequired(t('height')))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePicker
          required
          name="birthdate"
          readOnly={!editMode}
          disabled={!editMode}
          label={t('profile:birthdate')}
          inputRef={register(getRequired(t('birthdate')))}
          value={currentBirthdate}
          onChange={setCurrentBirthdate}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl disabled={!editMode}>
          <Typography>{t('profile:gender')}</Typography>
          <RadioGroup
            required
            direction="row"
            defaultValue={gender}
          >
            <FormControlLabel
              value="MALE"
              control={(
                <Radio
                  name="gender"
                  inputRef={register(getRequired(t('gender')))}
                />
              )}
              label={t('profile:male')}
            />
            <FormControlLabel
              value="FEMALE"
              control={(
                <Radio
                  name="gender"
                  inputRef={register(getRequired(t('gender')))}
                />
              )}
              label={t('profile:female')}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          required
          readOnly={!editMode}
          disabled={!editMode}
          label={t('weight')}
          type="number"
          endAdornment="kg"
          value={weight}
          name="weight"
          ref={register(getRequired(t('weight')))}
        />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={12} sm={6}>
          <Input
            readOnly={!editMode}
            disabled={!editMode}
            required
            label={t('email')}
            type="email"
            value={email}
            name="email"
            ref={register(getRequiredEmail(t('email')))}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        {renderErrors()}
        <CollapseError errors={errorsRequest} />
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
          justify="flex-end"
          alignItems="flex-end"
          className={submitContainer}
        >
          <Zoom in={editMode}>
            <Button
              filled
              onClick={handleSubmitClick}
            >
              {t('common:save')}
            </Button>
          </Zoom>
        </Grid>
      </Grid>
    </Grid>
  );
};

Registry.propTypes = {
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
};

Registry.defaultProps = {
  editMode: true,
  setEditMode: () => {},
};

export default Registry;
