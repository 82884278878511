import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { grey }, spacing }) => ({
  sectionContainer: {
    width: '100%',
    backgroundColor: grey.light,
    borderRadius: 20,
    padding: spacing(2, 4),
  },

  switchModeButton: {
    fontWeight: 900,
    borderRadius: 10,
    padding: spacing(),
    transition: '200ms ease',
  },

  submitContainer: {
    height: '100%',
  },
}));

export default useStyles;
