import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  breakpoints,
  spacing,
  palette: {
    secondary,
    grey,
    black,
    white,
  },
}) => ({
  ingredientsSubtitle: {
    marginLeft: spacing(2),
    fontSize: '1rem',
    fontWeight: 300,
    [breakpoints.down('md')]: {
      display: 'block',
      marginLeft: 0,
    },
  },

  subTitle: {
    [breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },

  shareContainer: {
    [breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },

  icon: {
    marginRight: spacing(2),
    '& .main': {
      fill: ({ isFavourite }) => (isFavourite ? secondary.main : 'transparent'),
      stroke: ({ isFavourite }) => (isFavourite ? secondary.main : black.main),
      transition: '225ms ease',
    },
  },

  divider: {
    height: 43,
    display: 'inline-block',
    borderRight: `1px solid ${secondary.main}`,
  },

  imageContainer: {
    height: 450,
    borderRadius: '30px 0 0 30px',
    overflow: 'hidden',
    backgroundColor: grey.dark,
    position: 'relative',
    '& > img': {
      position: 'absolute',
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    [breakpoints.down('md')]: {
      borderRadius: 0,
      marginRight: 0,
      marginBottom: spacing(4),
    },
  },

  detailContainer: {
    height: 450,
    position: 'relative',
    backgroundColor: grey.light,
    borderRadius: '0 30px 30px 0',
    padding: `${spacing(4)}px ${spacing(8)}px`,
    [breakpoints.down('md')]: {
      borderRadius: 30,
      padding: `${spacing(2)}px ${spacing(4)}px`,
    },
    [breakpoints.down('sm')]: {
      borderRadius: 20,
    },
  },

  descriptionBox: {
    padding: `${spacing(8)}px ${spacing(4)}px`,
    borderRadius: 30,
    backgroundColor: grey.light,
  },

  infoContainer: {
    marginTop: spacing(2),
    '& > div': {
      padding: `${spacing(3)}px 0`,
    },
  },

  badge: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  labelStyle: {
    alignItems: 'center',
    fontWeight: 900,
  },

  ingredientsContainer: {
    width: '100%',
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  headContainer: {
    marginBottom: -spacing(3),
    [breakpoints.down('md')]: {
      marginBottom: spacing(2),
    },
  },

  counterContainer: {
    justifyContent: 'flex-end',
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },

  dosesTitleContainer: {
    justifyContent: 'flex-start',
    [breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [breakpoints.only('sm')]: {
      justifyContent: 'flex-end',
      paddingLeft: spacing(2),
    },
  },

  pillowContainer: {
    justifyContent: 'flex-end',
    [breakpoints.only('sm')]: {
      justifyContent: 'flex-start',
      paddingLeft: spacing(2),
    },
  },

  ingredientsTitle: {
    [breakpoints.down('md')]: {
      fontSize: '1.4rem',
      marginBottom: spacing(4),
    },
  },

  ingredientGrid: {
    width: '100%',
    padding: `${spacing(7)}px ${spacing(8)}px ${spacing(8)}px`,
    borderTop: `2px solid ${secondary.main}`,
    borderBottom: `2px solid ${secondary.main}`,
    display: 'flex',
    alignItems: 'flex-start',
    [breakpoints.down('md')]: {
      padding: `${spacing(8)}px 0`,
      borderColor: grey.main,
      '& > * + *': {
        marginTop: spacing(2),
      },
    },
  },

  dietsGrid: {
    alignSelf: 'center',
    transform: 'translateY(+40%)',
    zIndex: 100,
  },

  dietsContainer: {
    width: 'auto',
    borderRadius: 999,
    border: `2px solid ${secondary.main}`,
    backgroundColor: white.main,
  },

  buttonsContainer: {
    alignSelf: 'center',
    transform: 'translateY(-40%)',
    [breakpoints.down('md')]: {
      marginTop: spacing(2),
      transform: 'none',
    },
  },

  commentBox: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 30,
    backgroundColor: grey.light,
    padding: spacing(4),
  },

  commentIconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > div + div': {
      marginLeft: spacing(2),
    },
  },

  commentIcon: {
    cursor: 'pointer',
    padding: spacing(),
    backgroundColor: 'transparent',
    border: `2px solid ${grey.dark}`,
    transition: '300ms ease',
    '& svg .main': {
      transition: '300ms ease',
    },
    '&:hover': {
      borderColor: secondary.main,
      '& svg .main': {
        stroke: black.light,
      },
    },
  },

  commentActionContainer: {
    padding: spacing(4),
    '& > button + button': {
      marginLeft: spacing(4),
    },
    [breakpoints.down('md')]: {
      '& > button + button': {
        marginLeft: 0,
        marginTop: spacing(2),
      },
    },
  },

  mobileCarousel: {
    minWidth: '100%',
    display: 'flex',
    overflowX: 'scroll',
    justifyContent: 'space-between',
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
    marginBottom: spacing(3),
    paddingLeft: spacing(2),
    paddingBottom: spacing(2),
    '& > * + *': {
      marginLeft: spacing(4),
      [breakpoints.down('md')]: {
        marginLeft: spacing(2),
      },
    },
  },

  variationImage: {
    width: spacing(8),
    height: spacing(8),
    marginRight: spacing(2),
  },

  badgeContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    bottom: 0,
    left: 0,
    padding: `0 ${spacing(5)}px`,
    height: spacing(12),
    backgroundColor: `${grey.light}c9`,
    borderTopRightRadius: 30,
  },

  shoppingListDialog: {
    minWidth: '25%',
  },
}));

export default useStyles;
