import { useQuery } from '@apollo/react-hooks';
import { getDishQuery } from '../../queries/dishes';

export default ({ dishId }) => {
  const { loading, data } = useQuery(getDishQuery, {
    variables: { dishId },
  });

  return { loading, data: data ? data.menu : {} };
};
