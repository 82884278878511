import React, { useState } from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '../../../components/Checkbox';
import { useAppContext } from '../../../contexts/AppContext';
import { ReactComponent as CaretDownIcon } from '../../../assets/icons/caretDown.svg';
import useStyles from './style';

const FilterItem = ({
  id,
  name,
  handleClick,
  group,
  subFilters,
  level,
}) => {
  const { orderDishesFilters: [checkedFilters] } = useAppContext();
  const { capitalize } = useTheme().useClasses();
  const {
    subList,
    listItem,
    listItemIcon,
    icon,
  } = useStyles({ level });

  const subLevel = level > 1;
  const hasChildren = subFilters && subFilters.length;
  const subFiltersIsChecked = hasChildren && intersection(
    checkedFilters[group],
    subFilters.map(({ key: subFilterId }) => subFilterId),
  ).length;

  const [open, setOpen] = useState(subFiltersIsChecked);

  const toggleOpen = () => { setOpen(!open); };

  const renderSubFilter = (subFilter) => (
    <FilterItem
      key={subFilter.key}
      id={subFilter.key}
      name={subFilter.label}
      subFilters={subFilter[group]}
      checkedFilters={checkedFilters}
      group={group}
      handleClick={handleClick}
      level={level + 1}
    />
  );

  return (
    <>
      <ListItem
        key={id}
        button
        onClick={!hasChildren ? handleClick(id) : toggleOpen}
        className={listItem}
      >
        <ListItemIcon className={listItemIcon}>
          {!subLevel && hasChildren ? (
            <CaretDownIcon
              width={15}
              height={8}
              className={icon}
              style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
            />
          ) : <span />}
        </ListItemIcon>
        <ListItemText
          id={id}
          className={capitalize}
          primaryTypographyProps={{ style: { lineHeight: 1 } }}
        >
          {name}
        </ListItemText>
        <ListItemSecondaryAction>
          <Checkbox
            onClick={handleClick(id)}
            checked={checkedFilters[group] && checkedFilters[group].includes(id)}
          />
        </ListItemSecondaryAction>
      </ListItem>

      {hasChildren ? (
        <Collapse in={!!open}>
          <List classes={{ root: subList }}>
            {subFilters.map(renderSubFilter)}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

FilterItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  subFilters: PropTypes.arrayOf(PropTypes.shape()),
  level: PropTypes.number,
};

FilterItem.defaultProps = {
  subFilters: [],
  level: 1,
};

export default FilterItem;
