import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getImagesSet, formatMoney } from '../../libraries/helpers';
import useStyles from './style';

const CartSummaryItem = ({
  slug,
  dishId,
  quantity,
  unitPrice,
  title,
  grams,
  image,
}) => {
  const { t } = useTranslation('ingredients');
  const { titleItem, priceStyle } = useStyles();

  const dishUrl = `it/piatti/${slug || 'piatto'}/${dishId}`;

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
    >
      <Grid item>
        {image ? (
          <Link to={dishUrl}>
            <img
              alt={title}
              src={image.thumbnail}
              srcSet={getImagesSet(image, 'thumbnail')}
              style={{ maxHeight: 60, maxWidth: 100 }}
            />
          </Link>
        ) : null}
      </Grid>

      <Grid item xs>
        <Link to={dishUrl} underline>
          <Typography className={titleItem}>
            {`${title} | ${grams}${t('gram', { count: grams })}`}
          </Typography>
        </Link>
        <Typography>
          <span className={priceStyle}>
            {`${quantity} x ${formatMoney(unitPrice)}`}
          </span>
        </Typography>
      </Grid>

      <Grid item>
        <Typography>
          <span className={priceStyle}>
            <Typography variant="h3">
              {formatMoney(unitPrice * quantity)}
            </Typography>
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

CartSummaryItem.propTypes = {
  slug: PropTypes.string,
  dishId: PropTypes.string,
  title: PropTypes.string.isRequired,
  grams: PropTypes.number,
  unitPrice: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  image: PropTypes.shape(),
};

CartSummaryItem.defaultProps = {
  slug: '',
  dishId: '',
  grams: null,
  image: null,
};

export default CartSummaryItem;
