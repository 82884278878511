import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Food from './Food';
import useStyles from './style';

const Items = ({ data }) => {
  const { t } = useTranslation('shoppingList');
  const { itemsContainer, dividerStyle } = useStyles();

  /* eslint-disable react/prop-types */
  const renderFood = ({ food, shoppingListItems }) => (
    <Grid item xs={12} key={food ? food.id : 'other'}>
      <Food food={food || undefined} shoppingListItems={shoppingListItems} />
    </Grid>
  );

  return (
    <Grid container direction="column" className={itemsContainer}>
      <Grid item>
        <Typography variant="h3">{t('to-buy')}</Typography>
      </Grid>
      <Grid item>
        <Divider className={dividerStyle} />
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={4}>
          {data && data.length
            ? data.map(renderFood)
            : (
              <Box
                textAlign="center"
                paddingTop={4}
                paddingBottom={2}
                color="grey.dark"
              >
                {t('no-items')}
              </Box>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Items.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

Items.defaultProps = {
  data: null,
};

export default Items;
