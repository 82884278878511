import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BodyContainer from '../../components/BodyContainer';
import Section from './Section';
import Registry from './Registry';
import Access from './Access';

const UserProfile = () => {
  const { t } = useTranslation('profile');

  return (
    <BodyContainer>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">{t('user-profile')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Section
              editable
              component={Registry}
              label={t('registry')}
            />
          </Grid>

          <Grid item xs={12}>
            <Section
              editable
              component={Access}
              label={t('change-password')}
            />
          </Grid>
        </Grid>
      </Container>
    </BodyContainer>
  );
};

export default UserProfile;
