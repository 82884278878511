import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useDateFns from '../../../../hooks/useDateFns';
import useGetPlanDay from '../../../../graphql/hooks/plans/useGetPlanDay';
import { useDiaryContext } from './DiaryContext';
import LocalLoading from '../../../../components/LocalLoading';
import Tab from '../../../../components/Tab';
import TabPanel from '../../../../components/TabPanel';
import Meal from './Meal';
import Logs from './Logs';
import useStyles from './style';

const Details = () => {
  const { t } = useTranslation('dashboard');
  const { detailsContainer } = useStyles();
  const { getPlanDay, loading, data } = useGetPlanDay();
  const [selectedDate] = useDiaryContext();
  const { format } = useDateFns();
  const [activeTab, setActiveTab] = useState(0);

  const day = format(selectedDate, 'd');
  const month = format(selectedDate, 'L');
  const year = format(selectedDate, 'y');

  useEffect(() => {
    getPlanDay({ variables: { day, month, year } });
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedDate]);

  const handleTabChange = (index) => () => { setActiveTab(index); };

  const getSelectedCombination = (meal) => {
    const dataMeal = data[meal];
    if (
      !dataMeal.selected
      || !dataMeal.selected.selectedGroup
      || !dataMeal.combinations
      || !dataMeal.combinations.length
    ) {
      return false;
    }
    return dataMeal.combinations.find(({ id }) => id === dataMeal.selected.selectedGroup.id);
  };

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Tab
            index={0}
            label={t('meals')}
            currentTab={activeTab}
            onClick={handleTabChange(0)}
            contrasted
          />
        </Grid>
        <Grid item xs>
          <Tab
            index={1}
            label={t('notes')}
            currentTab={activeTab}
            onClick={handleTabChange(1)}
            contrasted
          />
        </Grid>
      </Grid>
      <Grid container className={detailsContainer}>
        <Box padding={2} width="100%">
          <TabPanel value={activeTab} index={0}>
            <Grid container spacing={2} direction="column">
              {loading || !data
                ? <LocalLoading />
                : (
                  <>
                    <Grid item>
                      <Meal
                        label={t('meal-lunch')}
                        combination={getSelectedCombination('lunch')}
                        week={data.currentWeek}
                        day={+day}
                        meal={3}
                      />
                    </Grid>
                    <Grid item>
                      <Meal
                        label={t('meal-dinner')}
                        combination={getSelectedCombination('dinner')}
                        week={data.currentWeek}
                        day={+day}
                        meal={5}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Logs notes={data && data.notes} />
          </TabPanel>
        </Box>
      </Grid>
    </>
  );
};

export default Details;
