import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ComingSoon from '../../components/ComingSoon';
import diario from '../../assets/homepage_services/diario.svg';
import lista from '../../assets/homepage_services/lista.svg';
import premi from '../../assets/homepage_services/premi.svg';
import ricette from '../../assets/homepage_services/ricette.svg';
import proIcon from '../../assets/homepage_services/pro.svg';
import useStyles from './style';

const data = [{
  label: 'recipes',
  image: ricette,
  link: 'recipes',
}, {
  label: 'list',
  image: lista,
  pro: true,
  comingSoon: true,
}, {
  label: 'diary',
  image: diario,
  pro: true,
  comingSoon: true,
}, {
  label: 'awards',
  image: premi,
  pro: true,
  comingSoon: true,
}];

const OtherServices = () => {
  const { t } = useTranslation('home');
  const { otherServicesContainer, svgImage, proBadge } = useStyles();

  return (
    <Container className={otherServicesContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t('other-services')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-between" spacing={4}>
            {data.map(({
              label,
              image,
              pro,
              comingSoon,
              link,
            }) => (
              <Grid key={label} item md={3} style={{ maxWidth: 350 }}>
                <ComingSoon disable={!comingSoon}>
                  <Grid
                    container
                    direction="column"
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    style={{ position: 'relative' }}
                  >
                    <Grid item>
                      <img src={image} className={svgImage} alt="Other services" />
                      {pro ? <img src={proIcon} className={proBadge} alt="Pro badge" /> : null}
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" align="center">
                        <b style={{ fontWeight: 900 }}>{t(`item-title-${label}`)}</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        style={{ fontWeight: 400, lineHeight: '25px' }}
                      >
                        <span
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: t(`item-${label}`) }}
                        />
                      </Typography>
                      <Typography color="secondary" variant="body1" align="center">
                        <span style={{ fontWeight: 700 }}>
                          <a href={link}>
                            {t('show-more')}
                          </a>
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </ComingSoon>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OtherServices;
