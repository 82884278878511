import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as LikeIcon } from '../../assets/icons/like.svg';
import { ReactComponent as ReplyIcon } from '../../assets/icons/reply.svg';
import useStyles from './style';

const Comment = ({ text }) => {
  const { commentBox, commentIconsContainer, commentIcon } = useStyles();

  const renderIcon = (Icon) => (
    <Avatar
      classes={{ root: commentIcon }}
      onClick={() => { console.log('asd'); }}
    >
      <Icon />
    </Avatar>
  );

  return (
    <Grid item xs={12} md={6}>
      <Box className={commentBox}>
        <Typography variant="h2" />
        <Typography>{text}</Typography>
        <Box
          className={commentIconsContainer}
          alignSelf="flex-end"
        >
          {renderIcon(LikeIcon)}
          {renderIcon(ReplyIcon)}
        </Box>
      </Box>
    </Grid>
  );
};

Comment.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Comment;
