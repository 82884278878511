import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import useGetIngredients from '../../graphql/hooks/generic/useGetIngredients';
import useUpdateShoppingList from '../../graphql/hooks/shoppingList/updateShoppingList';
import { ingredientsUnits } from '../../libraries/helpers';
import Input from '../../components/Input';
import InputDropdown from '../../components/InputDropdown';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import Ingredients from './Ingredients';
import useStyles from './style';

const AddIngredient = ({ shoppingListId }) => {
  const { t } = useTranslation(['shoppingList', 'ingredients']);
  const { itemsContainer, dividerStyle } = useStyles();
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const { getIngredients, loading, data } = useGetIngredients({ lazy: true });
  const [updateShoppingList] = useUpdateShoppingList();

  const ingredientUnitsReduced = ingredientsUnits.reduce((accumulator, ingredientUnit) => (
    [...accumulator, { label: t(`ingredients:${ingredientUnit.toLowerCase()}`), value: ingredientUnit }]
  ), []);

  const handleSearchChange = (search) => {
    getIngredients({ variables: { search: search || 'no-ingredients' } });
  };

  const handleItemNameChange = (search) => {
    setItemName(search);
    debounce(handleSearchChange, 500)(search);
  };

  const handleSelectIngredient = (ingredientId) => {
    if (ingredientId) {
      setSelectedIngredient([data.find(({ id }) => id === ingredientId)]);
    } else {
      setSelectedIngredient(null);
    }
  };

  const handleAddToList = () => {
    const item = selectedIngredient ? selectedIngredient[0].name : itemName;
    const ingredientId = selectedIngredient ? selectedIngredient[0].id : null;
    const itemType = selectedIngredient ? selectedIngredient[0].type : null;

    if (item) {
      setItemName('');
      setSelectedIngredient('');
      setQuantity('');
      setUnit('');
      handleSearchChange();

      updateShoppingList({
        variables: {
          id: shoppingListId,
          items: [{
            item,
            item_ref: ingredientId,
            item_type: itemType,
            quantity,
            unit,
          }],
        },
      });
    }
  };

  return (
    <Grid container direction="column" className={itemsContainer}>
      <Grid item>
        <Typography variant="h3">{t('add-ingredient')}</Typography>
      </Grid>
      <Grid item>
        <Divider className={dividerStyle} />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Collapse in={!selectedIngredient} mountOnEnter>
              <Input
                square
                label={t('insert-ingredient')}
                onChange={handleItemNameChange}
                controlledValue={itemName}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!!(loading || (data && data.length))} mountOnEnter>
              {loading
                ? <Loading />
                : (
                  <Ingredients
                    data={data}
                    selectedIngredient={selectedIngredient}
                    selectIngredient={handleSelectIngredient}
                  />
                )}
            </Collapse>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label={t('quantity')}
              type="number"
              square
              controlledValue={quantity}
              onChange={setQuantity}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputDropdown
              label={t('unit')}
              square
              nullable
              value={unit}
              onChange={setUnit}
              dropdownProps={{ items: ingredientUnitsReduced }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item xs={12} sm={4}>
                <Button stretched onClick={handleAddToList} filled>{t('add-to-list')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddIngredient.propTypes = {
  shoppingListId: PropTypes.string,
};

AddIngredient.defaultProps = {
  shoppingListId: null,
};

export default AddIngredient;
