const layoutLg = [{
  i: 'lastActivity',
  x: 0,
  y: 0,
  w: 3,
  h: 3,
}, {
  i: 'weight',
  x: 3,
  y: 3,
  w: 6,
  h: 2,
}, {
  i: 'points',
  x: 3,
  y: 0,
  w: 2,
  h: 1,
}, {
  i: 'achievement',
  x: 5,
  y: 0,
  w: 2,
  h: 1,
}, {
  i: 'achievement-carrot',
  x: 7,
  y: 0,
  w: 2,
  h: 1,
}];

const layoutSm = [{
  i: 'lastActivity',
  x: 0,
  y: 0,
  w: 2,
  h: 2,
}, {
  i: 'weight',
  x: 2,
  y: 0,
  w: 2,
  h: 2,
}, {
  i: 'points',
  x: 2,
  y: 2,
  w: 1,
  h: 1,
}, {
  i: 'achievement',
  x: 0,
  y: 6,
  w: 1,
  h: 1,
}, {
  i: 'achievement-carrot',
  x: 1,
  y: 6,
  w: 1,
  h: 1,
}];

const defaultLayouts = {
  lg: layoutLg,
  sm: layoutSm,
};

// eslint-disable-next-line import/prefer-default-export
export { defaultLayouts };
