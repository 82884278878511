import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const Tab = ({
  action,
  contrasted,
  currentTab,
  disabled,
  full,
  index,
  label,
  mainColor,
  onClick,
  minWidth,
  maxWidth,
}) => {
  const active = currentTab === index;
  const { tabStyle, actionStyle } = useStyles({
    active,
    contrasted,
    disabled,
    full,
    mainColor,
    minWidth,
    maxWidth,
  });

  return (
    <ButtonBase
      disabled={disabled}
      className={tabStyle}
      onClick={!active ? onClick : undefined}
    >
      {label}
      {action && active ? <span className={actionStyle}>{action}</span> : null}
    </ButtonBase>
  );
};

Tab.propTypes = {
  action: childrenPropType,
  contrasted: PropTypes.bool,
  currentTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: childrenPropType.isRequired,
  mainColor: PropTypes.oneOf(['primary', 'secondary', 'grey']),
  minWidth: PropTypes.bool,
  maxWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  action: null,
  contrasted: false,
  currentTab: null,
  disabled: false,
  full: false,
  mainColor: 'primary',
  minWidth: false,
  maxWidth: false,
};

export default Tab;
