import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, palette: { grey }, spacing }) => ({
  modalContainer: {
    padding: spacing(6, 4),
    [breakpoints.down('sm')]: {
      padding: spacing(4, 3),
    },
  },

  recapContainer: {
    backgroundColor: grey.light,
    padding: spacing(4),
    borderRadius: 30,
  },

  large: {
    width: spacing(20),
    height: spacing(20),
  },

  coaches: {
    '& > * + *': {
      marginTop: spacing(2),
    },
  },

  wipBannerStyle: {
    position: 'absolute',
    top: 0,
    left: spacing(4),
    width: 70,
    heigth: 40,
  },
}));

export default useStyles;
