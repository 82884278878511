import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { DishProvider } from '../../contexts/DishContext';
import BodyContainer from '../../components/BodyContainer';
import Main from './Main';

const Dish = () => {
  const { dishId } = useParams();

  return (
    <BodyContainer>
      <Container>
        <DishProvider dishId={dishId}>
          <Main />
        </DishProvider>
      </Container>
    </BodyContainer>
  );
};

export default Dish;
