import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  palette: {
    primary,
    white,
    grey,
    secondary,
    red,
    green,
    yellow,
  },
  spacing,
}) => ({
  cellWeekday: {
    fontWeight: 900,
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: 3,
  },

  monthNavigationButtonRoot: {
    padding: spacing(1, 2),
    borderRadius: 10,
  },

  monthNavigationButtonDisabled: {
    opacity: 0.4,
  },

  monthHeaderContainer: {
    backgroundColor: primary.dark,
    color: white.main,
    height: 50,
    fontSize: '1.4rem',
    fontWeight: 900,
    borderRadius: '20px 20px 0 0',
    '& > * + *': {
      marginLeft: spacing(2),
    },
  },

  monthBodyContainer: {
    backgroundColor: white.main,
    borderRadius: '0 0 20px 20px',
    padding: spacing(4),
  },

  cellDay: {
    backgroundColor: ({ isToday }) => (isToday ? primary.light : white.main),
    border: ({ isSelected }) => (isSelected ? `1px solid ${secondary.main}` : 'none'),
    borderRadius: 10,
    width: '100%',
    height: '100%',
    transition: 'border 300ms ease',
    opacity: ({ isValid }) => (isValid ? 1 : 0.3),
  },

  rippleChild: {
    backgroundColor: secondary.main,
  },

  dot: {
    borderRadius: '50%',
    width: 10,
    height: 10,
    backgroundColor: ({ type }) => {
      if (type === 'default') return grey.main;
      if (type === 'success') return green.main;
      if (type === 'warning') return yellow.main;
      return red.main;
    },
  },

  detailsContainer: {
    backgroundColor: white.main,
    borderRadius: '0 0 20px 20px',
    height: 'calc(100% - 50px)',
    padding: spacing(2),
  },

  combinationContainer: {
    position: 'relative',
    padding: spacing(2),
    borderRadius: 20,
    boxShadow: `0 0 8px 2px ${grey.main}`,
    minHeight: 172,
  },
}));

export default useStyles;
