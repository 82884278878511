import React from 'react';
import { dispatch } from 'use-bus';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const useInputStyles = makeStyles(({ spacing, palette: { primary, white } }) => ({
  root: {
    border: `3px solid ${primary.dark}`,
    borderRadius: '30px 0 0 30px',
    height: spacing(5),
    backgroundColor: white.main,
    flexGrow: 1,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
}));

const useButtonStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: spacing(6.5),
    height: spacing(5),
    borderRadius: '0 30px 30px 0',
    backgroundColor: palette.primary.dark,
  },
}));

const InlineInputButton = () => {
  const inputStyles = useInputStyles();
  const buttonStyles = useButtonStyles();

  const handleClick = () => {
    dispatch({ type: '@@newsletterModal/open' });
  };

  return (
    <>
      <InputBase
        placeholder="Scrivi a support@libraway.it"
        readOnly
        classes={inputStyles}
        onClick={handleClick}
      />
      <ButtonBase
        classes={buttonStyles}
        onClick={handleClick}
      >
        <FontAwesomeIcon
          size="lg"
          icon={faChevronRight}
        />
      </ButtonBase>
    </>
  );
};

export default InlineInputButton;
