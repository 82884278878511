import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { childrenPropType } from '../../../../components/propTypes';
import Divider from '../../../../components/Divider';
import { ReactComponent as MoveIconGreen } from '../../../../assets/icons/moveGreen.svg';
import { ReactComponent as MoveIconWhite } from '../../../../assets/icons/moveWhite.svg';
import useStyles from './style';

const moveIcons = {
  green: MoveIconGreen,
  white: MoveIconWhite,
};

const DraggableItem = ({
  title,
  children,
  iconColor,
  className,
  flexCenter,
}) => {
  const { container, icon, content } = useStyles({ flexCenter, title });
  const MoveIcon = moveIcons[iconColor];

  return (
    <div className={classnames(className, container)}>
      <MoveIcon className={icon} />
      {title ? (
        <>
          <Typography variant="h3">{title}</Typography>
          <Divider color="grey" marginTop={1} />
        </>
      ) : null}
      <div className={content}>
        {children}
      </div>
    </div>
  );
};

DraggableItem.propTypes = {
  title: PropTypes.string,
  children: childrenPropType.isRequired,
  iconColor: PropTypes.oneOf(['green', 'white']),
  className: PropTypes.string,
  flexCenter: PropTypes.bool,
};

DraggableItem.defaultProps = {
  title: '',
  iconColor: 'green',
  className: '',
  flexCenter: false,
};

export default DraggableItem;
