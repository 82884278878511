import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ActivatedStepContext } from './ActivatedStepContext';
import Divider from '../../components/Divider';
import useStyles from './style';

const UserInfoStep = ({
  title,
  subTitle,
  component: Component,
  index,
  lastStep,
}) => {
  const { t } = useTranslation('common');
  const { editButton, innerContainer } = useStyles({ textAlign: 'left' });
  const [activatedStep, setActivatedStep] = useContext(ActivatedStepContext);
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const isActive = activatedStep === index;
  const isPassed = activatedStep > index;

  const handleEditClick = () => { setActivatedStep(index); };
  const handleConfirmClick = () => { setActivatedStep(activatedStep + 1); };

  const editButtonRender = (
    <Grid item>
      <ButtonBase className={editButton} onClick={handleEditClick}>
        {t('edit')}
      </ButtonBase>
    </Grid>
  );

  const renderHead = () => {
    if (isActive) {
      return (
        <>
          <Typography variant="h3">{title}</Typography>
          <Typography style={{ margin: '16px 0' }}>{subTitle}</Typography>
        </>
      );
    }

    return (
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h3">{title}</Typography>
          {!isPassed && !isSmallView && <Typography>{subTitle}</Typography>}
        </Grid>
        {isPassed && !isSmallView && editButtonRender}
      </Grid>
    );
  };

  return (
    <>
      <Grid item key={title}>
        <div className={innerContainer}>
          {renderHead()}
          {Component && (isPassed || isActive) ? (
            <Component
              active={isActive}
              confirm={handleConfirmClick}
            />
          ) : null}

          {isPassed && isSmallView && editButtonRender}
        </div>
      </Grid>

      {!lastStep ? (
        <Grid item key={`divider-${title}`}>
          <Divider color="grey" />
        </Grid>
      ) : null}
    </>
  );
};

UserInfoStep.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  component: PropTypes.func,
  index: PropTypes.number.isRequired,
  lastStep: PropTypes.bool,
};

UserInfoStep.defaultProps = {
  component: null,
  lastStep: false,
};

export default UserInfoStep;
