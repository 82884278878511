import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useSignin from '../../graphql/hooks/user/useSignin';
import useLocationQuery from '../../hooks/useLocationQuery';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import Link from '../../components/Link';
import ComingSoon from '../../components/ComingSoon';
import { useAuthContext } from '../../contexts/AuthContext';
import LibrawayLogo from '../../assets/librawayLogoExtendedVertical.svg';
import useStyles from './style';

const Login = () => {
  const { t } = useTranslation('authentication');
  const history = useHistory();
  const query = useLocationQuery();
  const { REACT_APP_LOGIN_EMAIL, REACT_APP_LOGIN_PASSWORD } = process.env;
  const [username, setUsername] = useState(REACT_APP_LOGIN_EMAIL);
  const [password, setPassword] = useState(REACT_APP_LOGIN_PASSWORD);
  const { isLoggedIn, login } = useAuthContext();
  const [signin, { loading, data, errors }] = useSignin();
  const {
    container,
    formContainer,
    title,
    subButton,
    facebook,
    google,
  } = useStyles();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  useEffect(() => {
    if (data) {
      login(data, { redirectTo: query.get('redirectTo') });
    }
  }, [data]);

  const handleField = (setter) => ({ target: { value } }) => setter(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    signin({ variables: { username, password } });
  };

  const handleBack = () => {
    history.goBack();
  };

  const renderLoading = (
    <Loading size="small">
      {`${t('common:loading')}...`}
    </Loading>
  );

  if (!isLoggedIn) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={container}
      >
        <img
          src={LibrawayLogo}
          alt="libraway logo"
          style={{ height: 120, cursor: 'pointer' }}
          onClick={() => { history.push('/'); }}
          aria-hidden="true"
        />
        <Grow in>
          <Paper
            elevation={3}
            className={formContainer}
          >
            <Typography className={title}>{t('signin')}</Typography>

            <Collapse in={errors && errors.length}>
              <Alert severity="error">
                {errors && errors.length && errors[0].message}
              </Alert>
            </Collapse>

            {query.get('msg') && (
              <Alert severity={query.get('msg').split('.')[0]}>
                {query.get('msg').split('.')[1]}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                color="secondary"
                label={t('email')}
                value={username}
                disabled={loading}
                onChange={handleField(setUsername)}
              />
              <TextField
                fullWidth
                color="secondary"
                label={t('password')}
                type="password"
                value={password}
                disabled={loading}
                onChange={handleField(setPassword)}
              />
              <Button
                filled
                stretched
                disabled={loading}
                type="submit"
              >
                {loading ? renderLoading : t('signin')}
              </Button>

              <Typography className={subButton}>
                {t('forgot-password')}
                {' '}
                <Link to="/password-recovery">{t('recover-password')}</Link>
              </Typography>
              <ComingSoon position={{ top: 0, right: 30 }}>
                <Button
                  filled
                  stretched
                  disabled
                  className={facebook}
                >
                  {t('facebook-login')}
                </Button>
              </ComingSoon>
              <ComingSoon position={{ top: 0, right: 30 }}>
                <Button
                  filled
                  stretched
                  disabled
                  className={google}
                >
                  {t('google-login')}
                </Button>
              </ComingSoon>
              <Typography className={subButton}>
                {t('signup-ask')}
                {' '}
                <a href="/signup">
                  {t('signup-now')}
                </a>
              </Typography>
              <Button
                onClick={handleBack}
                stretched
                disabled={loading}
              >
                {t('common:back')}
              </Button>
            </form>
          </Paper>
        </Grow>
      </Box>
    );
  }

  return null;
};

export default Login;
