import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useAuthContext } from '../../contexts/AuthContext';
import RecipeContext from './contexts';
import useCreateComment from '../../graphql/hooks/recipes/useCreateComment';
import useReactHookForm from '../../hooks/useReactHookForm';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PrivacyCheckbox from '../../components/PrivacyCheckbox';
import useStyles from './style';

const pending = 'PENDING';
const approved = 'APPROVED';
const rejected = 'REJECTED';

const CommentsActions = () => {
  const { t } = useTranslation('recipe');
  const { commentActionContainer } = useStyles();
  const [writingMode, setWritingMode] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: 'info',
    message: '',
  });
  const { isLoggedIn } = useAuthContext();
  const { id: recipeId } = useContext(RecipeContext);
  const [createComment, { loading, data }] = useCreateComment();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const { useForm } = useReactHookForm();
  const { register } = useForm();

  const enableWriting = () => { setWritingMode(true); };
  const disableWriting = () => { setWritingMode(false); };

  useEffect(() => {
    if (data && data.status) {
      if (data.status === pending) {
        setAlert({
          open: true,
          severity: 'info',
          message: t('comment-feedback-pending'),
        });
      }

      if (data.status === rejected) {
        setAlert({
          open: true,
          severity: 'error',
          message: t('comment-feedback-rejected'),
        });
      }

      if (data.status === approved) {
        setAlert({
          open: true,
          severity: 'success',
          message: t('comment-feedback-approved'),
        });
      }

      disableWriting();
    }
  }, [data, t]);

  const handleClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createComment({
      variables: {
        name,
        email,
        message,
        recipeId,
      },
    });
  };

  return (
    <>
      <Grid
        container
        justify="center"
        spacing={4}
        className={commentActionContainer}
      >
        {!writingMode
          ? (
            <>
              <Grid item>
                <Button fitted stretched>{t('read-all-comments')}</Button>
              </Grid>
              <Grid item>
                <Button fitted stretched filled onClick={enableWriting}>
                  {t('write-a-comment')}
                </Button>
              </Grid>
            </>
          )
          : (
            <Grid item xs={12} md={7}>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                >
                  {!isLoggedIn && (
                    <Grid item container spacing={2}>
                      <Grid item xs={12} md>
                        <Input
                          square
                          label={t('comment-name')}
                          required
                          disabled={loading}
                          onChange={setName}
                          value={name}
                        />
                      </Grid>
                      <Grid item xs={12} md>
                        <Input
                          square
                          label={t('comment-email')}
                          type="email"
                          required
                          disabled={loading}
                          onChange={setEmail}
                          value={email}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Input
                      label={t('comment-message')}
                      multiline
                      required
                      disabled={loading}
                      onChange={setMessage}
                      value={message}
                    />
                  </Grid>
                  {!isLoggedIn && (
                    <PrivacyCheckbox
                      register={register}
                      loading={loading}
                    />
                  )}
                  <Grid item>
                    <Grid container justify="space-around" spacing={2}>
                      <Grid item xs={12} md="auto">
                        <Button
                          stretched
                          onClick={disableWriting}
                          disabled={loading}
                        >
                          {t('common:cancel')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <Button
                          filled
                          stretched
                          type="submit"
                          disabled={loading}
                        >
                          {t('common:submit')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
      </Grid>
      <Snackbar
        open={alert.open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={8000}
      >
        <Alert variant="filled" severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CommentsActions;
