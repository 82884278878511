import { useMutation } from '@apollo/react-hooks';
import { upgradeStandardPlanMutation } from '../../mutations/generic';

export default () => {
  const [
    upgradeStandardPlan,
    { loading, data, error },
  ] = useMutation(upgradeStandardPlanMutation);

  return [upgradeStandardPlan, { loading, data, errors: error && error.graphQLErrors }];
};
