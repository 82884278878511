import gql from 'graphql-tag';
import { orderFragment } from '../fragments/order';

const getCheckoutSession = gql`
  query CheckoutSession($orderSessionId: String) {
    checkoutSession(session: $orderSessionId) {
      id,
      value,
      order { ...MainInfoOrder }
    }
  }
  ${orderFragment}
`;

const getOrdersQuery = gql`
  query Orders($first: Int, $after: String) {
    me {
      orders(
        first: $first,
        after: $after,
      ) {
        pageInfo {
          endCursor,
          hasNextPage
        }
        edges {
          node { ...MainInfoOrder }
        }
      }
    }
  }
  ${orderFragment}
`;

export { getCheckoutSession, getOrdersQuery };
