import React from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { mealActionsData } from './propTypes';
import Notes from './Notes';
import { ReactComponent as BarcodeCircleIcon } from '../../assets/icons/barcodeCircle.svg';
import { ReactComponent as LandscapeCircleIcon } from '../../assets/icons/landscapeCircle.svg';
import { ReactComponent as CamCircleIcon } from '../../assets/icons/camCircle.svg';

const MealActions = ({ week, day, meal }) => (
  <Grid container justify="flex-end" spacing={1}>
    <Grid item>
      <ButtonBase disabled style={{ opacity: 0.4 }}>
        <BarcodeCircleIcon />
      </ButtonBase>
    </Grid>
    <Grid item>
      <ButtonBase disabled style={{ opacity: 0.4 }}>
        <LandscapeCircleIcon />
      </ButtonBase>
    </Grid>
    <Grid item>
      <ButtonBase disabled style={{ opacity: 0.4 }}>
        <CamCircleIcon />
      </ButtonBase>
    </Grid>
    <Grid item>
      <Notes
        week={week}
        day={day}
        meal={meal}
      />
    </Grid>
  </Grid>
);

MealActions.propTypes = mealActionsData;

export default MealActions;
