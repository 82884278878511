import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useAuthContext } from '../../contexts/AuthContext';
import useModalOpen from '../../hooks/useModalOpen';
import useResponsive from '../../hooks/useResponsive';
import Button from '../../components/Button';
import MainDrawer from './MainDrawer';
import { ReactComponent as CartIcon } from '../../assets/icons/carrello.svg';
import { ReactComponent as BurgerMenu } from '../../assets/icons/burgerMenu.svg';
import { ReactComponent as BurgerMenuWhite } from '../../assets/icons/burgerMenuWhite.svg';
import useStyles from './style';

const TopRightMenu = ({ contrasted, shrink }) => {
  const { t } = useTranslation('header');
  const history = useHistory();
  const { isSmallView } = useResponsive();
  const {
    iconButton,
    topRightContainer,
    signinStyle,
    burgerMenu,
    planButtonStyle,
  } = useStyles();
  const { isLoggedIn } = useAuthContext();
  const { opened, closeModal, openModal } = useModalOpen();

  const handleSigninClick = () => {
    history.push('/signin');
  };

  const renderBurgerIcon = () => {
    if (contrasted) return <BurgerMenuWhite />;

    return <BurgerMenu />;
  };

  return (
    <>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={topRightContainer}
      >
        <Grid item>
          <Grid container alignItems="center">
            {!isLoggedIn && !isSmallView && (
              <Button
                filled
                fitted
                className={planButtonStyle}
                onClick={() => { history.push('/it/come-funziona'); }}
              >
                {t('active-plan')}
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems="center">
            <ButtonBase
              disableRipple
              className={iconButton}
              onClick={() => { history.push('/checkout'); }}
            >
              <CartIcon width={20} height={30} />
            </ButtonBase>
          </Grid>
        </Grid>

        {!isLoggedIn && !isSmallView && (
          <Grid item>
            <ButtonBase
              disableRipple
              onClick={handleSigninClick}
            >
              <Typography className={signinStyle}>
                {t('signin')}
              </Typography>
            </ButtonBase>
          </Grid>
        )}

        {(isLoggedIn || isSmallView) && (
          <Grid item>
            <ButtonBase
              disableRipple
              className={burgerMenu}
              onClick={openModal}
            >
              {renderBurgerIcon()}
            </ButtonBase>
          </Grid>
        )}
      </Grid>

      <MainDrawer
        open={opened}
        onClose={closeModal}
        shrink={shrink}
      />
    </>
  );
};

TopRightMenu.propTypes = {
  contrasted: PropTypes.bool,
  shrink: PropTypes.bool,
};

TopRightMenu.defaultProps = {
  contrasted: false,
  shrink: false,
};

export default TopRightMenu;
