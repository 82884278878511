import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Responsive, WidthProvider } from 'react-grid-layout';
import breakpoints from '../../theme/main/breakpoints';
import { childrenPropType } from '../propTypes';
import 'react-grid-layout/css/styles.css';
import useStyles from './style';
// import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const interestingBreakpoints = ['lg', 'md', 'sm', 'xs'];

const DraggableLayout = ({
  children,
  isResizable,
  layouts,
  onDragStop,
  rowHeight,
}) => {
  const { spacing } = useTheme();
  const { gridLayout } = useStyles();

  const getBreakpoints = () => Object.keys(breakpoints.values)
    .filter((breakpoint) => interestingBreakpoints.includes(breakpoint))
    .reduce((accumulator, breakpoint) => ({
      ...accumulator,
      [breakpoint]: breakpoints.values[breakpoint],
    }), {});

  const getLayouts = () => {
    if (Array.isArray(layouts)) return { lg: layouts };
    return layouts;
  };

  return (
    <ResponsiveGridLayout
      className={gridLayout}
      layouts={getLayouts()}
      breakpoints={getBreakpoints()}
      isResizable={isResizable}
      onDragStop={onDragStop}
      rowHeight={rowHeight}
      margin={[spacing(2), spacing(4)]}
      cols={{
        lg: 9,
        md: 9,
        sm: 4,
        xs: 1,
      }}
    >
      {children}
    </ResponsiveGridLayout>
  );
};

const layoutPropTypes = PropTypes.arrayOf(PropTypes.shape({
  i: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  w: PropTypes.number,
  h: PropTypes.number,
}));

DraggableLayout.propTypes = {
  children: childrenPropType,
  isResizable: PropTypes.bool,
  layouts: PropTypes.oneOfType([
    PropTypes.shape({
      lg: layoutPropTypes,
      md: layoutPropTypes,
      sm: layoutPropTypes,
      xs: layoutPropTypes,
    }),
    layoutPropTypes,
  ]),
  onDragStop: PropTypes.func,
  rowHeight: PropTypes.number,
};

DraggableLayout.defaultProps = {
  children: null,
  isResizable: false,
  layouts: {},
  onDragStop: () => {},
  rowHeight: undefined,
};

export default DraggableLayout;
