import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NukaCarousel from 'nuka-carousel';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { DailyCombinationsContext } from './contexts';
import Paper from '../../../components/Paper';
import Button from '../../../components/Button';
import MealActions from '../../../components/MealActions';
import Combination from './Combination';
import { ReactComponent as CaretGreenLeft } from '../../../assets/icons/caretSlimLeft.svg';
import { ReactComponent as CaretGreenRight } from '../../../assets/icons/caretSlimRight.svg';
import { mealCombinationsPropTypes } from '../constants';
import useStyles from './style';

const Meal = ({
  label,
  mealCombinations,
  handleMealSwitch,
  preselectedIndex,
  week,
  day,
  meal,
}) => {
  const { t } = useTranslation('dashboard');
  const {
    trackable,
    disableActions,
    selected: [selectedCombinations, setSelectedCombinations],
    activated: [activatedCombinations, setActivatedCombinations],
    preselected: [preselectedCombinations, setPreselectedCombinations],
  } = useContext(DailyCombinationsContext);
  const [iterationEnabled, setIterationEnabled] = useState(true);
  const selectedCombination = selectedCombinations[label];
  const noPrev = selectedCombination === 0;
  const noNext = selectedCombination === mealCombinations.length - 1;
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('sm'));
  const isExtraSmallView = useMediaQuery(breakpoints.down('xs'));

  const {
    arrowButton,
    left,
    right,
    paperContainer,
    paperCombination,
    paperCombinationContent,
    carouselContainer,
    active,
    preselected,
    switchButton,
  } = useStyles({ noPrev, noNext });

  const handleCombinationClick = (index) => () => {
    const isSelected = selectedCombinations[label] === index;
    const isActivated = activatedCombinations[label] === index;
    const isPreselected = preselectedCombinations[label] === index;
    const isPreselectedYet = preselectedIndex === index;

    if (iterationEnabled || isMobileView) {
      if (!isSelected) {
        setSelectedCombinations({ ...selectedCombinations, [label]: index });
      } else if (!disableActions && isActivated) {
        setActivatedCombinations({ ...activatedCombinations, [label]: null });
      } else if (!disableActions && trackable && !isPreselected && isPreselectedYet) {
        setPreselectedCombinations({ ...preselectedCombinations, [label]: index });
      } else if (!disableActions && trackable && !isPreselected) {
        setActivatedCombinations({ ...activatedCombinations, [label]: index });
      } else if (isPreselected) {
        setPreselectedCombinations({ ...preselectedCombinations, [label]: null });
      }
    }
  };

  const handleBeforeSlide = (_, index) => {
    // setIterationEnabled(false);
    setSelectedCombinations({ ...selectedCombinations, [label]: index });
  };

  const handleAfterSlide = () => {
    setIterationEnabled(true);
    setActivatedCombinations({ ...activatedCombinations, [label]: null });
  };

  const renderArrow = (side) => ({ previousSlide, nextSlide }) => {
    const sides = {
      left: {
        className: left,
        clickBehaviour: previousSlide,
        disabled: noPrev,
        Icon: CaretGreenLeft,
      },
      right: {
        className: right,
        clickBehaviour: nextSlide,
        disabled: noNext,
        Icon: CaretGreenRight,
      },
    };

    const {
      Icon,
      className,
      clickBehaviour,
      disabled,
    } = sides[side];

    return (
      <ButtonBase
        className={classNames(arrowButton, className)}
        onClick={clickBehaviour}
        disabled={disabled}
      >
        <Icon />
      </ButtonBase>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderPaper = ({ name, choices }, index) => (
    <div
      key={`${name}-${index}`}
      className={paperContainer}
      role="button"
      tabIndex={index}
      onClick={handleCombinationClick(index)}
      onKeyPress={handleCombinationClick(index)}
      style={{ transform: `scale(${selectedCombination === index ? 1 : 0.75})`, outline: 'none' }}
    >
      {name ? (
        <Paper
          className={classNames(
            paperCombination,
            { [active]: index === activatedCombinations[label] },
            { [preselected]: index === preselectedCombinations[label] },
          )}
          contentClassName={paperCombinationContent}
        >
          <Combination
            title={name}
            ingredients={choices}
            selected={index === selectedCombinations[label]}
          />
        </Paper>
      ) : null}
    </div>
  );

  return (
    <Grid item container direction="column">
      <Grid item>
        <Grid container>
          <Grid item xs />
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={2} sm>
                <Grid container justify="flex-start">
                  <Typography variant="h2">{t(`meal-${label}`)}</Typography>
                </Grid>
              </Grid>

              {handleMealSwitch && (
                <Grid item xs={12} sm>
                  <Grid container justify="center" className={switchButton}>
                    <Button onClick={handleMealSwitch}>{t('switch-meals')}</Button>
                  </Grid>
                </Grid>
              )}

              {!disableActions && (
                <Grid item xs>
                  <MealActions
                    week={week}
                    day={day}
                    meal={meal}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={carouselContainer}>
          <NukaCarousel
            slidesToShow={isExtraSmallView ? 1 : 3}
            slidesToScroll={1}
            cellAlign="center"
            dragging={false}
            slideIndex={selectedCombination}
            beforeSlide={handleBeforeSlide}
            afterSlide={handleAfterSlide}
            slideWidth={isExtraSmallView ? 0.85 : 1}
            framePadding={isExtraSmallView ? '20px' : '0'}
            renderCenterLeftControls={renderArrow('left')}
            renderCenterRightControls={renderArrow('right')}
            renderBottomCenterControls={null}
          >
            {mealCombinations.map(renderPaper)}
          </NukaCarousel>
        </Grid>
      </Grid>

    </Grid>
  );
};

Meal.propTypes = {
  label: PropTypes.string.isRequired,
  handleMealSwitch: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  mealCombinations: PropTypes.arrayOf(mealCombinationsPropTypes).isRequired,
  preselectedIndex: PropTypes.number,
  week: PropTypes.number.isRequired,
};

Meal.defaultProps = {
  handleMealSwitch: false,
  preselectedIndex: null,
};

export default Meal;
