import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ReactComponent as CaretDown } from '../assets/icons/caretDown.svg';

import { childrenPropType } from './propTypes';

const useStyles = makeStyles(({ spacing, palette }) => ({
  trigger: {
    fontSize: '1rem',
    background: 'none',
    border: 0,
    cursor: ({ readOnly }) => (!readOnly ? 'pointer' : 'default'),
    fontWeight: 'bold',
    display: 'inline-block',
    width: ({ full }) => (full ? '100%' : 'auto'),
    height: ({ full }) => (full ? '100%' : 'auto'),
  },

  icon: {
    marginLeft: spacing(),
    color: palette.secondary.main,
  },
}));

const Dropdown = ({
  children,
  full,
  onClick,
  openable,
  readOnly,
  disabled,
  className,
}) => {
  const { trigger, icon } = useStyles({ full, readOnly });

  return (
    <ButtonBase
      className={classNames(className, trigger)}
      onClick={!readOnly ? onClick : undefined}
      disabled={disabled}
      disableRipple
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          {children}
        </Grid>
        <Grid item>
          {openable && !readOnly && (
          <CaretDown
            className={icon}
            width={15}
            height={8}
          />
          )}

        </Grid>
      </Grid>
    </ButtonBase>
  );
};

Dropdown.propTypes = {
  children: childrenPropType,
  onClick: PropTypes.func.isRequired,
  openable: PropTypes.bool,
  readOnly: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  children: null,
  openable: true,
  readOnly: false,
  full: false,
  disabled: false,
  className: '',
};

export default Dropdown;
