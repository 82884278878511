import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ComingSoon from '../../../components/ComingSoon';
import Button from '../../../components/Button';
import useStyles from './style';

const PlanCard = ({
  title,
  description,
  features,
  comingSoon,
  onClick,
}) => {
  const { t } = useTranslation('plans');
  const {
    featureStyle,
    planCardContainer,
    planCardHeader,
    planCardDescription,
    planCardFeatures,
    disabledStyle,
  } = useStyles();

  return (
    <ComingSoon disable={!comingSoon} position={{ right: 0, top: 0 }}>
      <Paper className={planCardContainer} elevation={3}>

        <Box className={planCardHeader}>
          {title}
        </Box>
        <Box className={planCardDescription}>
          {description}
        </Box>
        <Box className={planCardFeatures}>
          <ul>
            {Array.isArray(features) && (
              features.map(({ label, active }) => (
                <li
                  key={label}
                  className={classnames({
                    [featureStyle]: true,
                    [disabledStyle]: !active,
                  })}
                >
                  {label}
                </li>
              ))
            )}
          </ul>
        </Box>
        <Button
          fitted
          onClick={onClick}
          disabled={comingSoon}
        >
          {t('select-plan')}
        </Button>
      </Paper>
    </ComingSoon>
  );
};

PlanCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape()),
  comingSoon: PropTypes.bool,
  onClick: PropTypes.func,
};

PlanCard.defaultProps = {
  features: [],
  comingSoon: false,
  onClick: () => {},
};

export default PlanCard;
