import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import OverlayMessage from '../../../../components/OverlayMessage';
import MealActions from '../../../../components/MealActions';
import Combination from '../../DailyPlan/Combination';
import useStyles from './style';

const Meal = ({
  label,
  combination,
  week,
  day,
  meal,
}) => {
  const { t } = useTranslation('dashboard');
  const { combinationContainer } = useStyles();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item container justify="space-between">
        <Grid item>
          <Typography variant="h3">
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <MealActions
            week={week}
            day={day}
            meal={meal}
          />
        </Grid>
      </Grid>
      <Grid item>
        <div className={combinationContainer}>
          {combination ? (
            <Combination
              title={combination.name}
              ingredients={combination.choices}
              selected
            />
          ) : (
            <OverlayMessage>{t('no-combination-selected')}</OverlayMessage>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Meal.propTypes = {
  label: PropTypes.string.isRequired,
  combination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(),
  ]).isRequired,
  week: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
  meal: PropTypes.number.isRequired,
};

export default Meal;
