import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { text } }) => ({
  icon: {
    color: text.secondary,
    marginRight: spacing(2),
  },

  endAdornment: {
    right: spacing(3),
  },
}));

export default useStyles;
