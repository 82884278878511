import gql from 'graphql-tag';
import { todayFragment, weekFragment, mealFragment } from '../fragments/plans';

const getPlanRecapQuery = gql`
  query PlanRecap {
    me {
      plan {
        currentWeek: current_week,
        planWeek: plan_week,
        startDate: start_date,
        lastEntry: last_entry,
        week {
          previousTrackedCount: previous_tracked_count,
          trackedCount: tracked_count
        }
      }
    }
  }
`;

const getPlanTodayQuery = gql`
  query PlanToday {
    me {
      plan {
        today { ...Today },
        currentWeek: current_week,
      }
    }
  }
  ${todayFragment}
`;

const getPlanWeekQuery = gql`
  query PlanWeek {
    me {
      plan {
        week { ...Week }
      }
    }
  }
  ${weekFragment}
`;

const getPlanWeeksQuery = gql`
  query PlanWeeks {
    me {
      plan {
        weeks { ...Week }
      }
    }
  }
  ${weekFragment}
`;

const getPlanWeeksNumberQuery = gql`
  query PlanWeeksNumber {
    me {
      plan {
        weeks {
          num,
          planWeek: plan_week
        }
      }
    }
  }
`;

const getPlanMonthQuery = gql`
  query PlanMonth($month: Int, $year: Int) {
    getPlanMonth(month: $month, year: $year) {
      date,
      lunch { status },
      dinner { status }
    }
  }
`;

const getPlanDayQuery = gql`
  query PlanDay($day: Int, $month: Int, $year: Int) {
    getPlanDay(day: $day, month: $month, year: $year) {
      date,
      lunch { ...MainInfoMeal },
      dinner { ...MainInfoMeal },
      currentWeek: current_week,
      notes {
        id,
        category,
        day,
        text,
        meal { name }
      }
    }
  }
  ${mealFragment}
`;

const getActivitiesQuery = gql`
  query Activities($first: Int, $after: String) {
    me {
      activities(first: $first, after: $after) {
        pageInfo {
        endCursor,
        hasNextPage
      }
      edges {
        node {
          id,
          action,
          subject,
          label,
          createdAt: created_at,
        }
      }
      }
    }
  }
`;

const getWeightsQuery = gql`
  query Weights {
    me {
      weights {
        date
        weight
      }
    }
  }
`;

export {
  getActivitiesQuery,
  getPlanRecapQuery,
  getPlanTodayQuery,
  getPlanWeekQuery,
  getPlanWeeksQuery,
  getPlanWeeksNumberQuery,
  getPlanMonthQuery,
  getPlanDayQuery,
  getWeightsQuery,
};
