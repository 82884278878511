import gql from 'graphql-tag';

const createOrUpdateShoppingListMutation = gql`
  mutation CreateOrUpdateShoppingList($id: Int, $title: String) {
    createOrUpdateShoppingList(id: $id, title: $title) {
      id
    }
  }
`;

const deleteShoppingListMutation = gql`
  mutation DeleteShoppingList($id: Int!) {
    deleteShoppingList(id: $id) {
      id
    }
  }
`;

const checkShoppingListItemMutation = gql`
  mutation CheckShoppingListItem($id: Int!, $checked: Boolean) {
    checkShoppingListItem(id: $id, checked: $checked) {
      id
    }
  }
`;

const deleteShoppingListItemMutation = gql`
  mutation DeleteShoppingListItem($id: Int!) {
    deleteShoppingListItem(id: $id) {
      id
    }
  }
`;

const addShoppingListItemsMutation = gql`
  mutation AddShoppingListItems(
    $id: Int,
    $items: [ShoppingListInputItem]!,
    $type: ObjectType,
    $ref: Int,
    ) {
    addShoppingListItems(
      shopping_list_id: $id,
      items: $items,
      type: $type,
      ref: $ref,
    ) {
      id
    }
  }
`;


export {
  checkShoppingListItemMutation,
  createOrUpdateShoppingListMutation,
  deleteShoppingListItemMutation,
  deleteShoppingListMutation,
  addShoppingListItemsMutation,
};
