import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Paper from '../../../components/Paper';

const Coach = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Paper title={t('coach')} disabled>
      <Grid container>
        <Avatar>S</Avatar>
        Simone
      </Grid>
    </Paper>
  );
};

export default Coach;
