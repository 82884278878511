import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { grey, primary } }) => ({
  icon: {
    marginRight: spacing(3),
    [breakpoints.down('md')]: {
      width: 40,
      marginRight: spacing(2),
    },
  },
  titleContainer: {
    [breakpoints.down('md')]: {
      marginBottom: spacing(3),
    },
  },
  mobileCarousel: {
    width: `calc(100% + ${spacing(2)}px)`,
    display: 'flex',
    overflowX: 'scroll',
    justifyContent: 'space-between',
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
    marginBottom: spacing(3),
    paddingLeft: spacing(4),
    paddingBottom: spacing(2),
    '& > * + *': {
      marginLeft: spacing(4),
      [breakpoints.down('md')]: {
        marginLeft: spacing(2),
      },
    },
  },
  preferredContainer: {
    height: spacing(62),
    marginBottom: spacing(5),
    borderRadius: spacing(3),
    backgroundColor: grey.light,
    [breakpoints.down('md')]: {
      height: spacing(46),
    },
  },
  arrowButton: { height: '100%', width: '100%' },
  arrowContainer: { height: '100%', width: '100%' },
  left: {
    borderRadius: `${spacing(3)}px 0 0 ${spacing(3)}px`,
    '& svg .main': {
      transition: '500ms ease',
      stroke: ({ noPrevSlide }) => (noPrevSlide ? grey.dark : primary.dark),
    },
  },
  right: {
    borderRadius: `0 ${spacing(3)}px ${spacing(3)}px 0`,
    '& svg .main': {
      transition: '500ms ease',
      stroke: ({ noNextSlide }) => (noNextSlide ? grey.dark : primary.dark),
    },
  },
  slider: {
    height: '100%',
    '--content-background-color': 'transparent',
  },
  preferredSlideContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
