import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useEventListener from '@use-it/event-listener';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Input from '@material-ui/core/Input';
import useCreateOrUpdateShoppingList from '../../graphql/hooks/shoppingList/createOrUpdateShoppingList';
import { enterCode } from '../../libraries/helpers';

const TabLabel = ({ id, label }) => {
  const labelInput = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [valueInput, setValueInput] = useState(label);
  const [createOrUpdateShoppingList] = useCreateOrUpdateShoppingList();

  useEffect(() => {
    if (editMode) {
      labelInput.current.focus();
    }
  }, [editMode]);

  const setEditModeOn = () => { setEditMode(true); };
  const setEditModeOff = () => { setEditMode(false); };

  const handleInputChange = ({ target: { value } }) => { setValueInput(value); };

  const handleInputBlur = () => {
    if (valueInput !== '') {
      createOrUpdateShoppingList({ variables: { id, title: valueInput } });
      setEditModeOff();
    }
  };

  useEventListener('keydown', ({ keyCode }) => {
    if (keyCode === enterCode) {
      handleInputBlur();
    }
  });

  return (
    <>
      <Input
        inputRef={labelInput}
        type="text"
        value={valueInput}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required
        style={{ display: editMode ? 'inline-block' : 'none' }}
      />
      <ButtonBase
        onClick={setEditModeOn}
        style={{ display: !editMode ? 'inline-block' : 'none', fontSize: '1rem' }}
      >
        <Typography noWrap style={{ maxWidth: 140 }}>{valueInput}</Typography>
      </ButtonBase>
    </>
  );
};

TabLabel.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
};

export default TabLabel;
