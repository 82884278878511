import React from 'react';
import PropTypes from 'prop-types';
import TimelineDotMui from '@material-ui/lab/TimelineDot';
import useStyles from './style';

const TimelineDot = ({ action }) => {
  const { main } = useStyles({ action });

  return (
    <TimelineDotMui
      className={main}
      variant="outlined"
    />
  );
};

TimelineDot.propTypes = {
  action: PropTypes.oneOf([
    'UPDATED',
    'CREATED',
    'DELETED',
    'OTHER',
    'ACTIVITY',
    'MEAL',
  ]).isRequired,
};

export default TimelineDot;
