import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Filters from './Filters';
import MobileFilters from './MobileFilters';
import Content from './Content';

const Body = () => {
  const { breakpoints, spacing } = useTheme();
  const isDesktopView = useMediaQuery(breakpoints.up('md'));

  return (
    <Grid container>
      {isDesktopView ? (
        <Grid item xs={3}>
          <Filters />
        </Grid>
      ) : (
        <Grid item container style={{ marginBottom: spacing(3) }}>
          <MobileFilters />
        </Grid>
      )}
      <Grid item xs={12} md={9}>
        <Content />
      </Grid>
    </Grid>
  );
};

export default Body;
