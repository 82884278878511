import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import useStyles from './style';

const Checkbox = ({ checked, onClick }) => {
  const { root, checkedStyle, icon } = useStyles({ checked });

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(root, { [checkedStyle]: checked })}
    >
      <CheckIcon className={icon} />
    </button>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  onClick: () => {},
};

export default Checkbox;
