import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useReactHookForm from '../../hooks/useReactHookForm';
import librawayThemeV2 from '../../theme/v2';

const PrivacyCheckbox = ({ register, disabled }) => {
  const { t } = useTranslation('common');
  const { registers: { getRequired } } = useReactHookForm();

  return (
    <ThemeProvider theme={librawayThemeV2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControlLabel
            disabled={disabled}
            style={{ alignItems: 'flex-start' }}
            control={(
              <Checkbox
                inputProps={{ name: 'privacy' }}
                inputRef={register(getRequired('privacy'))}
                required
                color="primary"
              />
          )}
            label={(
              <Typography variant="caption">
                <span
                  style={{ lineHeight: '26px' }}
                // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: t('privacy') }}
                />
              </Typography>
          )}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            disabled={disabled}
            style={{ alignItems: 'flex-start' }}
            control={(
              <Checkbox
                inputProps={{ name: 'marketing' }}
                inputRef={register()}
                color="primary"
              />
          )}
            label={(
              <Typography variant="caption">
                <span
                  style={{ lineHeight: '26px' }}
                // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: t('marketing') }}
                />
              </Typography>
          )}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

PrivacyCheckbox.propTypes = {
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PrivacyCheckbox.defaultProps = {
  disabled: false,
};

export default PrivacyCheckbox;
