import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { black } }) => ({
  container: {
    padding: ({ noPadding }) => (noPadding ? 0 : spacing(3)),
    borderRadius: 30,
    position: 'relative',
    minWidth: ({ large, minWidth }) => {
      if (minWidth) return minWidth;
      return large ? 800 : 500;
    },
    [breakpoints.down('sm')]: {
      margin: spacing(1),
      minWidth: () => '70%',

    },
    [breakpoints.down('xs')]: {
      minWidth: () => '90%',
    },
  },

  titleContainer: {
    padding: 0,
    paddingBottom: spacing(2),
    textAlign: 'center',
    fontWeight: 900,
  },

  closeButton: {
    position: 'absolute',
    top: spacing(2.5),
    right: spacing(2),
    padding: spacing(),
    borderRadius: 10,
    '& .times-main': {
      stroke: black.dark,
    },
  },
}));

export default useStyles;
