import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { UserInfoContext } from '../UserInfoContext';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

const validationReducer = (state, { field, validation }) => ({ ...state, [field]: validation });

const DeliveryContact = ({ active, confirm }) => {
  const { t } = useTranslation(['order', 'profile']);
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const [validations, validationDispatch] = useReducer(validationReducer, {});
  const { deliveryContactState: [contactData, setDeliveryContact] } = useContext(UserInfoContext);
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    triggerValidation,
  } = useForm();

  const { email, phone, courierNote } = contactData;

  useEffect(() => {
    if (!isEmpty(contactData)) {
      confirm();
    }
  // eslint-disable-next-line
  }, []);

  const handleConfirm = (data) => {
    setDeliveryContact(data);
    confirm();
  };

  /** Validators register */
  const getRequired = (fieldName) => ({
    value: true,
    message: t('profile:field-required', { fieldName: t(`profile:${fieldName}`) }),
  });

  const getMinLength = (fieldName, minLength = 3) => ({
    value: minLength,
    message: t('profile:field-min', { fieldName: t(`profile:${fieldName}`), minLength }),
  });

  const getEmailRegister = (fieldName) => ({
    required: getRequired(fieldName),
    minLength: getMinLength(fieldName),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: t('profile:field-email', { fieldName: t(`profile:${fieldName}`) }),
    },
  });

  const getEmailConfirmRegister = (fieldName) => ({
    ...getEmailRegister(fieldName),
    validate: (value) => value === getValues('email') || t('profile:field-email-match'),
  });

  const getPhoneRegister = (fieldName) => ({
    required: getRequired(fieldName),
    minLength: getMinLength(fieldName),
    pattern: {
      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
      message: t('profile:field-phone', { fieldName: t(`profile:${fieldName}`) }),
    },
  });
  /** --- */

  const triggerField = (field) => async () => {
    validationDispatch({ field, validation: await triggerValidation(field) });
  };

  const getErrorField = (field) => {
    const foundFieldError = Object.keys(errors).find((fieldName) => fieldName === field);

    if (foundFieldError) {
      return errors[foundFieldError].message || true;
    }

    return false;
  };

  return (
    <>
      <Collapse in={!active}>
        <p>
          {phone}
          <br />
          {email}
          <br />
          {courierNote}
        </p>
      </Collapse>

      <Collapse in={active}>
        <Grid container spacing={2} justify="flex-start">
          <Grid item xs={12} sm={4}>
            <Input
              required
              label={t('profile:phone')}
              name="phone"
              type="tel"
              value={phone}
              ref={register(getPhoneRegister('phone'))}
              onChange={triggerField('phone')}
              isValidated={validations.phone}
              hasError={getErrorField('phone')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              required
              label={t('profile:email')}
              name="email"
              value={email}
              ref={register(getEmailRegister('email'))}
              onChange={triggerField('email')}
              isValidated={validations.email}
              hasError={getErrorField('email')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              required
              label={t('profile:email-confirm')}
              name="emailConfirm"
              ref={register(getEmailConfirmRegister('emailConfirm'))}
              onChange={triggerField('emailConfirm')}
              isValidated={validations.emailConfirm}
              hasError={getErrorField('emailConfirm')}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              multiline
              label={t('courier-note')}
              name="courierNote"
              value={courierNote}
              ref={register()}
              onChange={triggerField('courierNote')}
              isValidated={validations.courier}
              hasError={getErrorField('courierNote')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('required-disclaimer') }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button filled onClick={handleSubmit(handleConfirm)}>{t('common:confirm')}</Button>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

DeliveryContact.propTypes = {
  active: PropTypes.bool,
  confirm: PropTypes.func,
};

DeliveryContact.defaultProps = {
  active: false,
  confirm: () => { },
};

export default DeliveryContact;
