import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAuthContext } from '../../../contexts/AuthContext';
import { usePlanContext } from '../../../contexts/PlanContext';
import { getLocaleDateFnsFormat, getImagesSet } from '../../../libraries/helpers';
import { ReactComponent as TrophyIcon } from '../../../assets/icons/trophy.svg';
import useStyles from './style';

const PersonalInfo = () => {
  const { t, i18n: { language: currentLanguage } } = useTranslation('dashboard');
  const { breakpoints } = useTheme();
  const isExtraSmallView = useMediaQuery(breakpoints.down('xs'));
  const { mask } = useStyles();
  const { user } = useAuthContext();
  const [{ plan }] = usePlanContext();

  const renderPoints = () => (
    <>
      <TrophyIcon style={{ marginRight: 16 }} />
      <Typography variant="h3">
        {t('points', { points: '-' })}
      </Typography>
    </>
  );

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={4} sm={5}>
        <Grid container justify="center">
          <svg width="0" height="0">
            <defs>
              <clipPath id="profileClip">
                <path
                  d="M97.2526037,195.353639 L97.2502546,195.353639 C42.0779899,195.353639 9.06671405,149.088499 1.74235924,106.099656 C-6.09174059,60.1264163 12.9191649,22.0695321 50.1728349,9.15116163 C66.3014494,3.55600335 85.6036378,0.475864039 104.513534,0.475864039 C152.293321,0.475864039 190.014453,19.2018967 205.426606,50.5660749 C215.553384,71.1766204 216.124204,95.6309182 207.038058,119.417347 C195.74145,148.988085 171.468661,173.818351 142.105468,185.837667 C126.68157,192.152069 111.588889,195.353639 97.2526037,195.353639"
                />
              </clipPath>
            </defs>
          </svg>
          {user?.avatar && (
            <img
              alt="User profile"
              className={mask}
              src={user.avatar.default}
              srcSet={getImagesSet(user.avatar, 'default')}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={8} sm={7}>
        <Typography variant="h3">{t('welcome', { name: user?.name, context: user?.gender?.toLowerCase() })}</Typography>
        <Box mt={2} mb={2}>
          <Typography variant="body2">
            {t('start-diet', {
              date: plan && plan.data && plan.data.startDate ? format(
                new Date(plan.data.startDate),
                'd MMMM yyyy',
                { locale: dateFnsLocales[getLocaleDateFnsFormat(currentLanguage)] },
              ) : '-',
            })}
          </Typography>
          <Typography variant="body2">
            {t('last-input', {
              date: plan && plan.data && plan.data.lastEntry ? format(
                new Date(plan.data.lastEntry.replace(' ', 'T')),
                'd MMMM yyyy',
                // 'd MMMM yyyy H:mm',
                { locale: dateFnsLocales[getLocaleDateFnsFormat(currentLanguage)] },
              ) : '-',
            })}
          </Typography>
        </Box>
        {!isExtraSmallView ? (
          <Grid container alignItems="center">
            {renderPoints()}
          </Grid>
        ) : null}
      </Grid>
      {isExtraSmallView ? (
        <Grid item container alignItems="center">
          {renderPoints()}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default PersonalInfo;
