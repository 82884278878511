import { useMutation } from '@apollo/react-hooks';
import { updateForgottenPasswordMutation } from '../../mutations/user';

export default () => {
  const [updateForgottenPasswordFunc, {
    loading,
    data: { updateForgottenPassword } = {},
    errors,
  }] = useMutation(updateForgottenPasswordMutation);

  const { status, message } = updateForgottenPassword || {};

  return [updateForgottenPasswordFunc, { loading, data: { status, message }, errors }];
};
