import { makeStyles } from '@material-ui/core/styles';

const useConnectorStyles = makeStyles(({ palette: { secondary } }) => ({
  root: {
    top: 20,
  },

  line: {
    borderColor: secondary.light,
    borderTopWidth: 2,
  },

  active: {
    '& $line': {
      borderColor: secondary.main,
    },
  },

  completed: {
    '& $line': {
      borderColor: secondary.main,
    },
  },
}));

const useStepStyles = makeStyles(({ spacing, palette: { common, secondary, grey } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(5),
    height: spacing(5),
    borderRadius: '50%',
    color: secondary.main,
    fontWeight: 700,
    backgroundColor: secondary.light,
    zIndex: 10,
  },

  active: {
    backgroundColor: common.white,
    border: `2px solid ${secondary.main}`,
    boxSizing: 'border-box',
  },

  completed: {
    backgroundColor: secondary.main,
    color: common.white,
  },

  squared: {
    width: spacing(4),
    height: spacing(0.8),
    backgroundColor: ({ active }) => (active ? secondary.main : grey.main),
  },

  mobileContainer: {
    padding: spacing(0, 2),
  },
}));

export { useConnectorStyles, useStepStyles };
