import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { grey, primary, white } }) => ({
  container: {
    borderRadius: 20,
    width: '100%',
    minHeight: 100,
    border: ({ disabled }) => (disabled ? `3px dashed ${grey.dark}` : 0),
    backgroundColor: ({ disabled }) => (disabled ? grey.main : white.main),
    opacity: ({ disabled }) => (disabled ? '50%' : '100%'),
    transition: 'border 180ms ease',
  },

  head: {
    display: 'flex',
    alignItems: 'center',
    height: ({ disabled }) => (disabled ? '100%' : 'auto'),
    borderBottom: ({ disabled }) => (disabled ? 0 : `1px solid ${grey.main}`),
    color: ({ disabled }) => (disabled ? grey.dark : primary.dark),
    fontWeight: 900,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingRight: spacing(4),
    paddingLeft: spacing(4),
  },

  content: {
    display: ({ disabled }) => (disabled ? 'none' : 'block'),
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingRight: spacing(4),
    paddingLeft: spacing(4),
  },
}));

export default useStyles;
