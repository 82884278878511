import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { librawayStorage } from '../../libraries/helpers';
import { childrenPropType } from '../../components/propTypes';

const UserInfoContext = createContext([]);

const UserInfoProvider = ({ children }) => {
  const initialState = librawayStorage.get('userInfo') || {};

  const addressState = useState(initialState.addressState || {});
  const deliveryDateState = {
    availableState: useState([]),
    choosenState: useState(initialState.deliveryDateState || {}),
  };
  const deliveryContactState = useState(initialState.deliveryContactState || {});

  /** Update cart in localStorage */
  useEffect(() => {
    librawayStorage.set('userInfo', {
      addressState: addressState[0],
      deliveryDateState: deliveryDateState.choosenState[0],
      deliveryContactState: deliveryContactState[0],
    });
  }, [addressState, deliveryDateState, deliveryContactState]);

  const userInfoDone = (
    !isEmpty(addressState[0])
    && deliveryDateState.choosenState[0]
    && !isEmpty(deliveryContactState[0])
  );

  return (
    <UserInfoContext.Provider
      value={{
        addressState,
        deliveryDateState,
        deliveryContactState,
        userInfoDone,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

const useUserInfoContext = () => useContext(UserInfoContext);

UserInfoProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  UserInfoContext,
  UserInfoProvider,
  useUserInfoContext,
};
