import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useResponsive from '../../hooks/useResponsive';
import useStyles from './style';

const TryMenuBanner = () => {
  const { t } = useTranslation('home');
  const { isSmallView } = useResponsive();
  const { bannerBackground, bannerContainer, bannerText } = useStyles();

  return (
    <div className={bannerBackground}>
      <Grid
        container
        className={bannerContainer}
        spacing={isSmallView ? 3 : 6}
      >
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('try-menu-title') }}
            />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t(`try-menu-body${isSmallView ? '-mobile' : ''}`) }}
              className={bannerText}
            />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TryMenuBanner;
