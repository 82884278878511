import React from 'react';
import { useTranslation } from 'react-i18next';
// import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
/**
 * TODO: Needs to delete faker package
 */
// import faker from 'faker';
import aboutUsBackground from '../../assets/aboutUsBackground.png';
import MetaTags from '../../components/MetaTags';
import BodyContainer from '../../components/BodyContainer';
// import PersonSquare from './PersonSquare';
import useStyles from './style';
import { ReactComponent as FloatingImage } from '../../assets/aboutUsFloat.svg';

const AboutUs = () => {
  const { t } = useTranslation('aboutUs');
  const {
    intro,
    floating,
    title,
    divider,
    text,
    // body,
  } = useStyles();

  /**
  * TODO: Replace with actual data
  */
  // const persons = [...new Array(6)].map(() => ({
  //   name: `${faker.name.firstName()} ${faker.name.lastName()}`,
  //   jobTitle: faker.name.jobTitle(),
  //   image: `https://loremflickr.com/600/600/people?random=${faker.random.number()}`,
  // }));

  return (
    <>
      <MetaTags
        title="Chi siamo - Libraway"
        description={t('meta-description')}
      />
      <BodyContainer
        backgroundImage={aboutUsBackground}
        backgroundFixed
        position="top"
      >
        <FloatingImage className={floating} />
        <Box className={intro}>
          <Typography className={title} variant="h2" gutterBottom>{t('about-us')}</Typography>
          <div className={divider} />
          <Typography className={text}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('main-text') }}
            />
          </Typography>
        </Box>
      </BodyContainer>
      {/* <BodyContainer className={body} position="bottom">
        <Container>
          <Typography variant="h2" gutterBottom>{t('about-us')}</Typography>
          <Grid container justify="space-between">
            {persons.map(PersonSquare)}
          </Grid>
        </Container>
      </BodyContainer> */}
    </>
  );
};

export default AboutUs;
