import { useMutation } from '@apollo/react-hooks';
import { addShoppingListItemsMutation } from '../../mutations/shoppingList';

export default () => {
  const [
    addShoppingListItems,
    { loading, data, error },
  ] = useMutation(
    addShoppingListItemsMutation,
    { refetchQueries: ['ShoppingLists'] },
  );

  return [addShoppingListItems, { loading, data, errors: error && error.graphQLErrors }];
};
