import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { librawayStorage } from '../libraries/helpers';
import { childrenPropType } from '../components/propTypes';

const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const redirectTo = useRef(null);
  const history = useHistory();
  const [user, setUser] = useState(librawayStorage.get('user') || {});
  const [authentication, setAuthentication] = useState(librawayStorage.get('authentication') || {});

  useEffect(() => {
    if (authentication?.accessToken && redirectTo.current) {
      history.push(redirectTo.current);
    }
  }, [authentication]);

  /**
   * Listening auth locale state changing and set them in localstorage
   */
  useEffect(() => {
    librawayStorage.set('authentication', authentication);
    librawayStorage.set('user', user);
  }, [authentication, user]);

  /**
   * Exportable methods
   */
  const login = (authData, options) => {
    if (authData) {
      const { user: responseUser, ...responseAuthentication } = authData;

      redirectTo.current = options?.redirectTo || '/dashboard';

      setUser(responseUser);
      setAuthentication(responseAuthentication);
    }
  };

  const logout = () => {
    setUser(null);
    setAuthentication(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!authentication?.accessToken,
        login,
        logout,
        user: {
          ...user,
          hasPlan: !!user?.plan?.startDate,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  AuthContext,
  AuthProvider,
  useAuthContext,
};
