import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';

const Unit = ({ filled }) => {
  const { unit } = useStyles({ filled });

  return <Grid item><div className={unit} /></Grid>;
};

Unit.propTypes = {
  filled: PropTypes.bool,
};

Unit.defaultProps = {
  filled: false,
};

export default Unit;
