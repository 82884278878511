import PropTypes from 'prop-types';
import { nullType } from '../../libraries/helpers';

const { REACT_APP_VALUATION_SCALE: valuationScale } = process.env;

const ValuationScale = ({ children }) => `${children ? +children.toFixed(1) : nullType}/${valuationScale}`;


ValuationScale.propTypes = {
  children: PropTypes.number,
};

ValuationScale.defaultProps = {
  children: null,
};

export default ValuationScale;
