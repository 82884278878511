import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import useGetActivities from '../../../../../graphql/hooks/plans/useGetActivities';
import useDateFns from '../../../../../hooks/useDateFns';
import LocalLoading from '../../../../../components/LocalLoading';
import TimelineDot from '../../../../../components/TimelineDot';
import DraggableItem from '../DraggableItem';
import useStyles from './style';

const LastActivity = () => {
  const {
    timeline,
    typography,
    oppositeContent,
    mainContent,
  } = useStyles();
  const { t } = useTranslation('dashboard');
  const { formatDistanceToNow } = useDateFns();
  const { loading, data } = useGetActivities();

  if (loading) return <LocalLoading />;

  return (
    <DraggableItem title={t('last-activities-title')}>
      <Timeline className={timeline} align="left">
        {data && data.map(({
          node: {
            id,
            createdAt,
            action,
            label,
          },
        }) => (
          <TimelineItem key={id}>
            <TimelineOppositeContent className={oppositeContent}>
              <Typography
                className={typography}
                color="textSecondary"
                variant="caption"
              >
                {formatDistanceToNow(new Date(createdAt.replace(' ', 'T')), { addSuffix: true })}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot action={action} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={mainContent}>
              <ButtonBase>
                <Typography className={typography}>{label}</Typography>
              </ButtonBase>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </DraggableItem>
  );
};

export default LastActivity;
