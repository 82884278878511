import { useMutation } from '@apollo/react-hooks';
import { deleteShoppingListMutation } from '../../mutations/shoppingList';

export default () => {
  const [
    deleteShoppingList,
    { loading, data, error },
  ] = useMutation(
    deleteShoppingListMutation,
    { refetchQueries: ['ShoppingLists'] },
  );

  return [deleteShoppingList, { loading, data, errors: error && error.graphQLErrors }];
};
