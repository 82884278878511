import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette: { green, red, yellow } }) => ({
  main: {
    width: spacing(3),
    height: spacing(3),
    borderColor: ({ action }) => {
      if (action === 'CREATED' || action === 'MEAL') return green.main;
      if (action === 'UPDATED' || action === 'OTHER') return yellow.main;
      return red.main;
    },
  },
}));

export default useStyles;
