import { useMutation } from '@apollo/react-hooks';
import { checkShippingMutation } from '../../mutations/order';

export default () => {
  const [
    checkShipping,
    {
      loading,
      data: { checkShipping: { shippings } = {} } = {},
      errors,
    },
  ] = useMutation(checkShippingMutation);

  return [checkShipping, { loading, data: shippings, errors }];
};
