import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import { useTheme } from '@material-ui/core/styles';
import useManageVote from '../../graphql/hooks/generic/useManageVote';
import Button from '../../components/Button';

const Vote = ({
  ownVote,
  valuationScale,
  type,
  reference,
}) => {
  const { t } = useTranslation('recipe');
  const { useClasses, palette: { secondary }, spacing } = useTheme();
  const { uppercase } = useClasses();
  const [manageVote] = useManageVote();
  const [editMode, setEditMode] = useState(false);

  const setEditModeOn = () => { setEditMode(true); };

  const handleStarClick = (newRating) => {
    manageVote({
      variables: {
        value: newRating,
        type,
        ref: reference,
      },
    });
  };

  if (editMode || ownVote) {
    return (
      <StarRatings
        rating={parseInt(ownVote, 10)}
        starRatedColor={secondary.main}
        starHoverColor={secondary.main}
        changeRating={handleStarClick}
        numberOfStars={parseInt(valuationScale, 10)}
        starDimension="20px"
        starSpacing={`${spacing(0.5)}px`}
      />
    );
  }

  return (
    <Button
      filled
      fitted
      onClick={setEditModeOn}
    >
      <span className={uppercase}>{t('vote')}</span>
    </Button>
  );
};

Vote.propTypes = {
  ownVote: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valuationScale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['MENUS', 'RECIPES', 'RESTAURANTS']).isRequired,
  reference: PropTypes.string.isRequired,
};

Vote.defaultProps = {
  ownVote: 0,
  valuationScale: 5,
};

export default Vote;
