import { useMutation } from '@apollo/react-hooks';
import { createCommentMutation } from '../../mutations/recipes';

export default () => {
  const [
    createComment,
    {
      loading,
      data: { createComment: comment } = {},
      errors,
    },
  ] = useMutation(createCommentMutation);

  return [createComment, { loading, data: comment, errors }];
};
