import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DiaryProvider } from './DiaryContext';
import Calendar from './Calendar';
import Details from './Details';

const Diary = () => (
  <DiaryProvider>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7}>
        <Calendar />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Details />
      </Grid>
    </Grid>
  </DiaryProvider>
);

export default Diary;
