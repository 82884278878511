import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ingredientPropType } from '../constants';
import Dialog from '../../../components/Dialog';
import useStyles from './style';
import IngredientIcon from './IngredientIcon';

const IngredientsGroup = ({ ingredients, mainIngredientsCount, combinationSelected }) => {
  const { t } = useTranslation('ingredients');
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const {
    ingredientsContainer,
    ingredientLabel,
    showMoreButton,
  } = useStyles({ ingredientsCount: mainIngredientsCount });
  const [modalOpen, setModalOpen] = useState(false);
  const moreIngredients = ingredients.length > 1;
  const ingredientsToShow = 3;

  const handleOpen = () => { setModalOpen(true); };
  const handleClose = (event) => {
    event.stopPropagation();
    setModalOpen(false);
  };
  const handleClick = (event) => {
    event.stopPropagation();
    if (moreIngredients) {
      handleOpen();
    }
  };

  /* eslint-disable react/prop-types */
  const renderSvg = ({ ingredient: { name, icon }, quantity }) => (
    <IngredientIcon
      key={name}
      icon={icon}
      quantity={quantity || 0}
      isGrouped={moreIngredients}
    />
  );

  const renderIngredients = ({ ingredient: { name, icon }, quantity }) => (
    <Grid
      key={name}
      item
      container
      direction="column"
      justify="center"
      alignItems="center"
      xs
    >
      <Grid item>
        <IngredientIcon
          icon={icon}
          quantity={quantity || 0}
        />
      </Grid>
      <Grid item><span className={ingredientLabel}>{name}</span></Grid>
    </Grid>
  );
  /* eslint-enable react/prop-types */

  return (
    <>
      <Grid
        className={ingredientsContainer}
        container
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={5} md={12}>
          <Grid
            container
            justify={isSmallView ? 'flex-end' : 'center'}
            alignItems="center"
          >
            <Grid item style={{ lineHeight: 1 }}>
              {ingredients.slice(0, ingredientsToShow).map(renderSvg)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={12}>
          <Grid
            container
            justify={isSmallView ? 'flex-start' : 'center'}
            alignItems="center"
          >
            {moreIngredients ? (
              <ButtonBase
                onClick={handleClick}
                disabled={!combinationSelected}
                className={showMoreButton}
              >
                {t('show-more')}
              </ButtonBase>
            ) : (
              <span className={ingredientLabel}>
                {ingredients[0].ingredient.name}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={modalOpen} onClose={handleClose} closeButton={false}>
        <Grid
          container
          spacing={4}
          style={{ maxWidth: 400 }}
        >
          {ingredients.map(renderIngredients)}
        </Grid>
      </Dialog>
    </>
  );
};

IngredientsGroup.propTypes = {
  ingredients: PropTypes.arrayOf(ingredientPropType).isRequired,
  mainIngredientsCount: PropTypes.number.isRequired,
  combinationSelected: PropTypes.bool,
};

IngredientsGroup.defaultProps = {
  combinationSelected: false,
};

export default IngredientsGroup;
