import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IngredientsGroup from './IngredientsGroup';
import { choicePropTypes } from '../constants';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import useStyles from './style';

const Combination = ({ title, ingredients, selected }) => {
  const { t } = useTranslation('dashboard');
  const { breakpoints, spacing } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const { combinationTitle } = useStyles();
  const plusDimension = isSmallView ? spacing(2) : spacing(2.5);
  const marginBottom = isSmallView ? spacing(2) : 0;

  const renderIngredient = ({ ingredients: subIngredients }, index) => {
    const toRender = [
      <Grid
        key={`ingredient-group-${index}`}
        item
        xs={11}
        sm={10}
        md
        style={{ marginBottom }}
      >
        <IngredientsGroup
          ingredients={subIngredients}
          mainIngredientsCount={ingredients.length}
          combinationSelected={selected}
        />
      </Grid>,
    ];

    if (ingredients[index + 1]) {
      toRender.push(
        <Grid
          key={`plus-${index}`}
          item
          container
          justify="center"
          alignItems="center"
          style={{ width: 26, marginBottom }}
          xs={1}
          sm={2}
          md={1}
        >
          <PlusIcon width={plusDimension} height={plusDimension} />
        </Grid>,
      );
    }

    return toRender;
  };

  return (
    <div>
      <Typography variant="h1" className={combinationTitle}>
        {`${t('combination')} ${title}`}
      </Typography>
      <Grid container>
        {ingredients.map(renderIngredient)}
      </Grid>
    </div>
  );
};

Combination.propTypes = {
  title: PropTypes.string.isRequired,
  ingredients: PropTypes.arrayOf(choicePropTypes).isRequired,
  selected: PropTypes.bool,
};

Combination.defaultProps = {
  selected: false,
};

export default Combination;
