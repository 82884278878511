import React from 'react';
import PropTypes from 'prop-types';
import comingSoonIcon from '../../assets/icons/nastro_presto-online.svg';

const ComingSoon = ({ children, disable, position: { right, top } }) => {
  if (disable) return children;

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={comingSoonIcon}
        alt="Coming soon"
        style={{
          position: 'absolute',
          right,
          top,
          zIndex: 10,
          opacity: '1!important',
        }}
      />
      <div style={{ opacity: 0.5 }}>
        {children}
      </div>
    </div>
  );
};

ComingSoon.propTypes = {
  children: PropTypes.element.isRequired,
  disable: PropTypes.bool,
  position: PropTypes.shape({
    right: PropTypes.number,
    top: PropTypes.number,
  }),
};

ComingSoon.defaultProps = {
  disable: false,
  position: { right: 75, top: 50 },
};

export default ComingSoon;
