import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '../../../components/Button';
import FiltersDrawer from './FiltersDrawer';
import { ReactComponent as FiltersIcon } from '../../../assets/icons/filters.svg';

const MobileFilters = () => {
  const { t } = useTranslation('recipe');
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { spacing } = useTheme();

  const handleDrawerOpen = (command) => () => { setDrawerOpened(command !== 'undefined' ? command : !drawerOpened); };

  return (
    <>
      <FiltersDrawer
        open={drawerOpened}
        setClose={handleDrawerOpen(false)}
      />
      <Grid item container alignItems="center" xs={6}>
        <Typography variant="h2">{t('filter-by')}</Typography>
      </Grid>
      <Grid item container alignItems="center" justify="flex-end" xs={6}>
        <Button fitted filled onClick={handleDrawerOpen(true)}>
          {t('select')}
          <FiltersIcon style={{ marginLeft: spacing(2) }} />
        </Button>
      </Grid>
    </>
  );
};

export default MobileFilters;
