import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { usePlanContext } from '../../../contexts/PlanContext';
import useGetPlanWeeksNumber from '../../../graphql/hooks/plans/useGetPlanWeeksNumber';
import Dropdown from '../../../components/Dropdown';

const useStyles = makeStyles({ small: { minWidth: 0 } });

const WeeksFilter = ({ disabled }) => {
  const { small } = useStyles();
  const { t } = useTranslation('dashboard');
  const [anchorElement, setAnchorElement] = useState(null);
  const [{ plan }, { setSelectedWeek }] = usePlanContext();
  const { getPlanWeeksNumber, loading, data } = useGetPlanWeeksNumber();
  const { currentWeek, num: masterNum } = plan.data;
  useEffect(getPlanWeeksNumber, []);

  const handleDropdownClick = ({ currentTarget }) => { setAnchorElement(currentTarget); };
  const handleClose = () => { setAnchorElement(null); };
  const handleWeekSelect = ({ num, planWeek }) => () => {
    handleClose();
    setSelectedWeek({ num, planWeek });
  };

  // eslint-disable-next-line react/prop-types
  const renderWeekItem = ({ num, planWeek }) => (
    <MenuItem key={num} onClick={handleWeekSelect({ num, planWeek })}>{t('week-number', { num })}</MenuItem>
  );

  if (disabled) {
    return (
      <Typography><b>{t('week-number', { num: masterNum || currentWeek })}</b></Typography>
    );
  }

  return (
    <>
      <Dropdown onClick={handleDropdownClick} disabled={disabled || loading}>
        {t('week-number', { num: masterNum || currentWeek })}
      </Dropdown>

      {data && data.length && (
        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleClose}
          classes={{ paper: small }}
        >
          {data.map(renderWeekItem)}
        </Menu>
      )}
    </>
  );
};

WeeksFilter.propTypes = {
  disabled: PropTypes.bool,
};


WeeksFilter.defaultProps = {
  disabled: false,
};

export default WeeksFilter;
