import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DraggableItem from '../DraggableItem';
import StarIcon from '../../../../../assets/icons/trackingAchievementStar.svg';
import CarrotIcon from '../../../../../assets/icons/trackingAchievementCarrot.svg';

const variants = {
  star: StarIcon,
  carrot: CarrotIcon,
};

const Achievement = ({ variant }) => (
  <DraggableItem flexCenter>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <img src={variants[variant]} alt="achievement icon" />
      </Grid>
      <Grid item style={{ textAlign: 'center' }}>
        <Typography variant="body2"><b>- obiettivi</b></Typography>
        <Typography variant="body2">accumulati questa settimana</Typography>
      </Grid>
    </Grid>
  </DraggableItem>
);

Achievement.propTypes = {
  variant: PropTypes.oneOf(['star', 'carrot']),
};

Achievement.defaultProps = {
  variant: 'star',
};

export default Achievement;
