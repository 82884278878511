import React from 'react';
import PropTypes from 'prop-types';
import { useStepStyles } from './style';

const Squared = ({ active }) => <div className={useStepStyles({ active }).squared} />;

Squared.propTypes = {
  active: PropTypes.bool,
};

Squared.defaultProps = {
  active: false,
};

export default Squared;
