import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useSignup from '../../graphql/hooks/user/useSignup';
import useReactHookForm from '../../hooks/useReactHookForm';
import PrivacyCheckbox from '../../components/PrivacyCheckbox';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import { useAuthContext } from '../../contexts/AuthContext';
import LibrawayLogo from '../../assets/librawayLogoExtendedVertical.svg';
import useStyles from './style';

const Login = () => {
  const { t } = useTranslation(['authentication', 'howItWorks']);
  const history = useHistory();

  const { isLoggedIn } = useAuthContext();
  const [signup, { loading, data: { status }, errors }] = useSignup();

  const { useForm } = useReactHookForm();
  const { register, getValues } = useForm();

  const {
    container,
    formContainer,
    title,
    subButton,
  } = useStyles();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }

    if (status === 'SUCCESS') {
      history.push(`/signin?msg=success.${t('signin-success')}`);
    }

    if (status === 'MUST_VERIFY_EMAIL') {
      history.push(`/signin?msg=info.${t('signin-verify')}`);
    }
  }, [status, history]);

  const fields = [{
    label: 'name',
    type: 'text',
  }, {
    label: 'surname',
    type: 'text',
  }, {
    label: 'email',
    type: 'email',
  }, {
    label: 'password',
    type: 'password',
  }, {
    label: 'password-check',
    type: 'password',
  }];

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      name,
      surname,
      email,
      password,
      'password-check': passwordCheck,
      privacy,
      marketing,
    } = getValues();
    signup({
      variables: {
        name,
        surname,
        email,
        password,
        passwordCheck,
        privacy,
        marketing,
      },
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const renderLoading = (
    <Loading size="small">
      {`${t('common:loading')}...`}
    </Loading>
  );

  // eslint-disable-next-line react/prop-types
  const renderField = ({ label, type }) => (
    <TextField
      fullWidth
      color="secondary"
      name={label}
      label={t(label)}
      type={type}
      disabled={loading}
      inputRef={register()}
    />
  );
  /* eslint-enable react/prop-types */

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={container}
    >
      <img
        src={LibrawayLogo}
        alt="libraway logo"
        style={{ height: 120, cursor: 'pointer' }}
        onClick={() => { history.push('/'); }}
        aria-hidden="true"
      />
      <Grow in>
        <Paper
          elevation={3}
          className={formContainer}
        >
          <Typography className={title}>{t('signup')}</Typography>

          <Collapse in={errors && errors.length}>
            <Alert severity="error">
              {errors && errors.length && errors[0].message}
            </Alert>
          </Collapse>

          <form onSubmit={handleSubmit}>
            {fields.map(renderField)}

            <PrivacyCheckbox register={register} />

            <Button
              filled
              stretched
              disabled={loading}
              type="submit"
            >
              {loading ? renderLoading : t('signup')}
            </Button>

            <Typography className={subButton}>
              {t('signin-ask')}
              {' '}
              <a href="/signin">
                {t('signin-now')}
              </a>
            </Typography>
            <Button
              onClick={handleBack}
              stretched
              disabled={loading}
            >
              {t('common:back')}
            </Button>
          </form>
        </Paper>
      </Grow>
    </Box>
  );
};

export default Login;
