import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { primary, grey } }) => ({
  summaryContainer: {
    padding: spacing(4),
    borderRadius: 30,
  },

  checkIconContainer: {
    backgroundColor: grey.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
    width: 200,
    height: 200,
  },

  checkIconStyle: {
    color: primary.dark,
    fontSize: '12rem',
  },

  linkOrder: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
}));

export default useStyles;
