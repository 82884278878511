import React, { useEffect } from 'react';
import NukaCarousel from 'nuka-carousel';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { usePlanContext } from '../../../contexts/PlanContext';
import Paper from '../../../components/Paper';
import PersonalInfo from './PersonalInfo';
import DishesStatus from './DishesStatus';
import Coach from './Coach';
import Nutritionist from './Nutritionist';
import useStyles from './style';

const Recap = () => {
  const { breakpoints, palette: { primary } } = useTheme();
  const {
    recapContainer,
    pagingDotsContainerClassName,
    slide,
    personalInfoPaper,
  } = useStyles();
  const isExtraSmallView = useMediaQuery(breakpoints.down('xs'));
  const [, { getPlanRecap }] = usePlanContext();

  useEffect(getPlanRecap, []);

  if (isExtraSmallView) {
    return (
      <NukaCarousel
        defaultControlsConfig={{
          pagingDotsContainerClassName,
          pagingDotsStyle: { fill: primary.dark },
        }}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
      >
        <div className={slide}>
          <Paper contentClassName={personalInfoPaper}>
            <PersonalInfo />
          </Paper>
        </div>
        <div className={slide}>
          <DishesStatus />
        </div>
        <div className={slide}>
          <Coach />
        </div>
        <div className={slide}>
          <Nutritionist />
        </div>
      </NukaCarousel>
    );
  }

  return (
    <Box className={recapContainer}>
      <Grid
        container
        alignItems="center"
        spacing={4}
      >
        <Grid item md={6}>
          <PersonalInfo />
        </Grid>
        <Grid item md={3}>
          <DishesStatus />
        </Grid>
        <Grid item md={3}>
          <Grid
            container
            direction="row"
            spacing={4}
          >
            <Grid item container>
              <Coach />
            </Grid>
            <Grid item container>
              <Nutritionist />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Recap;
