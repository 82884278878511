import PropTypes from 'prop-types';

const ingredientPropType = PropTypes.shape({
  ingredient: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
});

const choicePropTypes = PropTypes.shape({
  ingredients: PropTypes.arrayOf(ingredientPropType),
});

const combinationPropTypes = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  choices: PropTypes.arrayOf(choicePropTypes),
});

const mealCombinationsPropTypes = PropTypes.shape({
  meal: PropTypes.shape({ name: PropTypes.string }),
  combinations: PropTypes.arrayOf(combinationPropTypes),
});

const dayCombinationsPropTypes = PropTypes.shape({
  day: PropTypes.string,
  lunch: mealCombinationsPropTypes,
  dinner: mealCombinationsPropTypes,
});

export {
  choicePropTypes,
  combinationPropTypes,
  dayCombinationsPropTypes,
  ingredientPropType,
  mealCombinationsPropTypes,
};
