import AboutUs from './pages/AboutUs';
import Checkout from './pages/Checkout';
import Dashboard from './pages/Dashboard';
import Dish from './pages/Dish';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import Login from './pages/Authentication/Login';
import Logout from './pages/Authentication/Logout';
import NoMatch from './pages/NoMatch';
import Notifications from './pages/Notifications';
import Dishes from './pages/OrderDishes/Dishes';
import Recipes from './pages/OrderDishes/Recipes';
import OrderDetails from './pages/OrderDetails';
import OrderHistory from './pages/OrderHistory';
import PasswordRecovery from './pages/Authentication/PasswordRecovery';
import PasswordReset from './pages/Authentication/PasswordReset';
import Recipe from './pages/Recipe';
import Registration from './pages/Authentication/Registration';
import ShoppingLists from './pages/ShoppingLists';
import UserProfile from './pages/UserProfile';

import { getPathAliases } from './libraries/helpers';

const routes = [{

  /**
   * Public routes - authenticated: false|undefined
   */
  path: '/',
  title: 'home',
  main: Home,
  exact: true,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('about-us'),
  title: 'about-us',
  main: AboutUs,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('how-it-works'),
  title: 'how-it-works',
  main: HowItWorks,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('dishes'),
  title: 'dishes',
  main: Dishes,
  exact: true,
  showBottomCart: true,
}, {
  path: getPathAliases('recipes'),
  title: 'recipes',
  main: Recipes,
  exact: true,
}, {
  path: ['/dishes/:dishId', '/it/piatti/:dishSlug/:dishId'],
  // path: getPathAliases('dishes', ':dishSlug', ':dishId'),
  main: Dish,
  showBottomCart: true,
}, {
  path: ['/recipes/:recipeId', '/it/ricette/:recipeSlug/:recipeId'],
  // path: getPathAliases('recipes', ':recipeSlug', ':recipeId'),
  main: Recipe,
}, {
  path: getPathAliases('checkout', ':response?'),
  main: Checkout,
}, {
  path: getPathAliases('order-details', ':response?'),
  main: OrderDetails,
}, {
  path: getPathAliases('notifications'),
  main: Notifications,
},

/**
 * Private routes - authenticated: true
 */
{
  path: getPathAliases('dashboard', ':sectionSlug?'),
  title: 'dashboard',
  main: Dashboard,
  authenticated: true,
}, {
  path: getPathAliases('profile'),
  title: 'user-profile',
  main: UserProfile,
  authenticated: true,
}, {
  path: getPathAliases('orders'),
  title: 'order-history',
  main: OrderHistory,
  authenticated: true,
}, {
  path: getPathAliases('shopping-lists'),
  title: 'shopping-lists',
  main: ShoppingLists,
  authenticated: true,
},

/**
 * Routes without header + footer
 */
{
  path: getPathAliases('signin'),
  title: 'signin',
  main: Login,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('signup'),
  title: 'signup',
  main: Registration,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('signout'),
  title: 'signout',
  main: Logout,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('password-recovery'),
  title: 'password-recovery',
  main: PasswordRecovery,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('password-reset'),
  title: 'password-reset',
  main: PasswordReset,
  outOfLayoutRoutes: true,
},

/**
 * Handling errors
 */
{
  title: '404',
  main: NoMatch,
}];

export default routes;
