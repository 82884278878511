import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import reviews from '../../assets/icons/recensioni.svg';
import useStyles from './style';

const data = [{
  message: 'first',
  sign: 'Alice, Rimini',
}, {
  message: 'second',
  sign: 'Riccardo, Milano',
}, {
  message: 'third',
  sign: 'Luca, Monza',
}];

const AboutUs = () => {
  const { t } = useTranslation('home');
  const {
    aboutUsGridContainer,
    aboutUsContainer,
    cardContainer,
    card,
    cardMessage,
    cardSign,
    doubleQuotation,
  } = useStyles();

  return (
    <div className={aboutUsContainer}>
      <Container className={aboutUsGridContainer}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant="h2">
              {t('about-us')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justify="space-around"
              spacing={8}
              className={cardContainer}
            >
              {data.map(({ message, sign }) => (
                <Grid key={sign} item md={4}>
                  <Paper className={card}>
                    <img src={reviews} className={doubleQuotation} alt="Review icon" />
                    <Typography className={cardMessage}>
                      {t(`message-${message}`)}
                    </Typography>
                    <Typography className={cardSign}>
                      {sign}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
