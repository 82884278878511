import React from 'react';
import { useTranslation } from 'react-i18next';
import * as dateFnsLocales from 'date-fns/locale';
import { styled } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Switch, Route, Redirect } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import librawayApolloClient from './libraries/apollo';
import { getLocaleDateFnsFormat } from './libraries/helpers';
import routes from './routes';
import { useAuthContext } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { NotificationProvider } from './contexts/NotificationContext';
import RasaWidget from './components/RasaWidget';
import WebNotification from './components/WebNotification';
import VersionBanner from './components/VersionBanner';
import WelcomeModal from './components/WelcomeModal';
import Page from './components/Page';
import Header from './layout/Header';

const FullContainer = styled('div')({ width: '100%' });

const App = () => {
  const { i18n: { language: currentLanguage } } = useTranslation();
  const locale = dateFnsLocales[getLocaleDateFnsFormat(currentLanguage)];
  const { isLoggedIn } = useAuthContext();

  /* eslint-disable react/prop-types */
  const mapRoutes = ({
    path,
    title,
    exact,
    main,
    authenticated,
    showBottomCart,
    whiteBackgroundHeader,
    contrastedHeader,
    outOfLayoutRoutes,
  }) => (
    <Route
      key={`${path}-${title}`}
      path={path}
      exact={exact}
      render={({ location }) => (
        authenticated && !isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        ) : (
          <>
            {!outOfLayoutRoutes && (
              <Header
                contrasted={contrastedHeader}
                whiteBackground={whiteBackgroundHeader}
              />
            )}
            <Page
              component={main}
              title={title}
              showBottomCart={showBottomCart}
            />
          </>
        )
      )}
    />
  );
  /* eslint-enable react/prop-types */

  return (
    <ApolloProvider client={librawayApolloClient}>
      <NotificationProvider>
        <CartProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <WebNotification />
            <FullContainer>
              <WelcomeModal />
              <VersionBanner />
              <Switch>
                {routes.map(mapRoutes)}
              </Switch>
            </FullContainer>
          </MuiPickersUtilsProvider>
        </CartProvider>
      </NotificationProvider>
    </ApolloProvider>
  );
};

export default App;
