import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import propTypes from './propTypes';
import Dropdown from '../Dropdown';

const useStyles = makeStyles({ small: { minWidth: 0 } });

const DropdownMenu = ({
  className,
  disabled,
  readOnly,
  full,
  items,
  label,
  onChange,
}) => {
  const { small } = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);

  const handleDropdownClick = ({ currentTarget }) => { setAnchorElement(currentTarget); };
  const handleClose = () => { setAnchorElement(null); };
  const handleItemClick = (value, onClick) => () => {
    handleClose();
    onChange(value);
    if (onClick) {
      onClick(value);
    }
  };

  // eslint-disable-next-line react/prop-types
  const renderItem = ({ onClick, label: itemLabel, value }) => (
    <MenuItem
      key={value}
      dense
      onClick={handleItemClick(value, onClick)}
    >
      {itemLabel}
    </MenuItem>
  );

  return (
    <>
      <Dropdown
        onClick={handleDropdownClick}
        readOnly={readOnly}
        full={full}
        disabled={disabled}
        className={className}
      >
        {label}
      </Dropdown>

      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        classes={{ paper: small }}
      >
        {items.map(renderItem)}
      </Menu>
    </>
  );
};

DropdownMenu.propTypes = propTypes;

DropdownMenu.defaultProps = {
  disabled: false,
  readOnly: false,
  full: false,
  items: [],
  onChange: () => {},
  className: '',
};

export default DropdownMenu;
