import gql from 'graphql-tag';

const getUserFilters = gql`
  query UserFilters {
    userFilters { id, label }
  }
`;

const getWholeFilters = gql`
  query WholeFilters($type: WholeFiltersType!) {
    wholeFilters(type: $type) {
      type,
      label,
      items {
        key,
        label
        items {
          key,
          label
        }
      }
    }
  }`
;


// eslint-disable-next-line import/prefer-default-export
export { getUserFilters, getWholeFilters };
