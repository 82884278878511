import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import selezione from '../../assets/homepage_steps/gif_selezione.gif';
import delivery from '../../assets/homepage_steps/gif_consegna.gif';
import storage from '../../assets/homepage_steps/gif_frigo.gif';
import useStyles from './style';

const data = [{
  label: 'click',
  image: selezione,
}, {
  label: 'delivery',
  image: delivery,
}, {
  label: 'storage',
  image: storage,
}];

const Steps = () => {
  const { t } = useTranslation('home');
  const { stepsContainer, gifImage, stepsNumber } = useStyles();

  return (
    <Container className={stepsContainer}>
      <Grid container justify="space-around" spacing={4}>
        {data.map(({ label, image }, index) => (
          <Grid item md={3} key={label}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <img src={image} className={gifImage} alt="What can we do item" />
              </Grid>
              <Grid item>
                <Typography variant="h2" align="center" className={stepsNumber}>
                  {`${index + 1}.`}
                </Typography>
                <Typography variant="body2" align="center">
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t(`item-${label}`) }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Steps;
