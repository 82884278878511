import { useReducer, useEffect } from 'react';
import findLast from 'lodash/findLast';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import useGetBoxes from '../graphql/hooks/generic/useGetBoxes';
import { librawayStorage } from '../libraries/helpers';

const initialState = librawayStorage.get('cart') || {
  type: 'BOX',
  items: [],
  coupon: null,
  orderId: null,
};

const cartReducer = (state, { type, payload }) => {
  switch (type) {
    case 'add':
      return {
        ...state,
        items: [...state.items, payload],
      };

    case 'updateQuantity':
      return {
        ...state,
        items: state.items.map((item) => (
          payload.id === item.id
            ? { ...item, quantity: payload.quantity }
            : item
        )),
      };

    case 'remove':
      return {
        ...state,
        items: [...state.items.filter(({ id }) => !(id === payload.id))],
      };

    case 'clean':
      return {
        ...state,
        items: [],
      };

    case 'updateCoupon':
      return {
        ...state,
        coupon: payload,
      };

    case 'cleanCoupon':
      return {
        ...state,
        coupon: {},
      };

    case 'updateOrderId':
      return {
        ...state,
        orderId: payload,
      };

    case 'cleanOrderId':
      return {
        ...state,
        orderId: null,
      };

    default:
      throw new Error();
  }
};

const useCart = () => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [getBoxes, { boxes, loading }] = useGetBoxes();

  useEffect(getBoxes, []);

  /** Update cart in localStorage */
  useEffect(() => {
    librawayStorage.set('cart', {
      type: state.type,
      items: state.items,
      orderId: state.orderId,
    });
  }, [state]);

  /* Coupon */
  const getCoupon = (prop) => {
    if (!prop) return state.coupon;
    return !isEmpty(state.coupon) ? state.coupon[prop] : null;
  };

  const updateCoupon = (couponData) => {
    dispatch({ type: 'updateCoupon', payload: couponData });
  };

  const cleanCoupon = () => {
    dispatch({ type: 'cleanCoupon' });
  };

  /* Items */
  const cartLimit = boxes ? Math.max(...boxes.map(({ quantity }) => quantity)) : 0;

  const itemsCount = sumBy(state.items, ({ quantity = 0 }) => quantity);

  const voidSpacesLeft = Math.max(0, cartLimit - itemsCount);

  const itemsPrice = state.items.reduce((a, { unitPrice, quantity }) => (
    a + unitPrice * quantity
  ), 0);

  const boxCompleted = boxes && findLast(boxes, ({ quantity }) => itemsCount === quantity);

  const couponAmount = () => {
    const { type, amount } = state.coupon || {};
    if (type === 'FIXED') return amount;
    if (type === 'PERCENTAGE') return (itemsPrice / 100) * amount;
    return 0;
  };

  const cartPrice = (
    !boxCompleted || (state.coupon && state.coupon.freeShipping === true)
      ? 0
      : boxCompleted.shippingPrice
  ) + itemsPrice - couponAmount();

  const addSingleItemCart = ({
    id,
    dishId,
    title,
    price,
    dimension,
    grams,
    image,
    quantity,
  }) => {
    const { items } = state;
    const foundItem = items.find((item) => id === item.id);

    if (voidSpacesLeft > 0) {
      if (foundItem) {
        dispatch({ type: 'updateQuantity', payload: { id, quantity: foundItem.quantity + quantity } });
      } else {
        dispatch({
          type: 'add',
          payload: {
            id,
            dishId,
            dimension,
            grams,
            title,
            unitPrice: price,
            quantity,
            image,
          },
        });
      }
    }
  };

  const addCart = (itemsToAdd) => {
    if (Array.isArray(itemsToAdd)) {
      itemsToAdd.forEach(addSingleItemCart);
    } else {
      addSingleItemCart(itemsToAdd);
    }
  };

  const removeItem = ({ id }) => {
    dispatch({ type: 'remove', payload: { id } });
  };

  const updateQuantityCart = (itemToUpdate) => {
    const { id, quantity } = itemToUpdate;
    const { items } = state;
    const foundItem = items.find((item) => id === item.id);

    if (quantity && !foundItem) {
      addSingleItemCart(itemToUpdate);
    } else if (quantity) {
      dispatch({ type: 'updateQuantity', payload: { id, quantity } });
    } else {
      removeItem({ id });
    }
  };

  const cleanCart = () => {
    dispatch({ type: 'clean' });
  };

  const updateOrderId = (orderId) => {
    dispatch({ type: 'updateOrderId', payload: orderId });
  };

  const cleanOrderId = () => {
    dispatch({ type: 'cleanOrderId' });
  };

  return [{
    boxesLoading: loading,
    cartItems: state.items,
    cartPrice,
    couponAmount: couponAmount(),
    boxCompleted,
    boxes,
    getCoupon,
    itemsCount,
    itemsPrice,
    orderId: state.orderId,
    voidSpacesLeft,
  }, {
    addCart,
    cleanCart,
    cleanCoupon,
    cleanOrderId,
    removeItem,
    updateCoupon,
    updateOrderId,
    updateQuantityCart,
  }];
};

export default useCart;
