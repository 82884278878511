import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RecipeContext from '../contexts';
import DosesContext from './contexts';
import { renderIngredientQuantity, ingredientsUnits } from '../../../libraries/helpers';

const Ingredient = ({ ingredient, quantity, unit }) => {
  const { people } = useContext(RecipeContext);
  const [doses] = useContext(DosesContext);
  const { bold, uppercase } = useTheme().useClasses();
  const { t } = useTranslation('ingredients');

  return (
    <>
      <Grid item container justify="center" xs={1} />
      <Grid item xs={7}>
        <span className={`${bold} ${uppercase}`}>{ingredient}</span>
      </Grid>
      <Grid item xs={4}>
        {renderIngredientQuantity(quantity, doses, people, unit, t)}
      </Grid>
    </>
  );
};

Ingredient.propTypes = {
  ingredient: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  unit: PropTypes.oneOf(ingredientsUnits).isRequired,
};

Ingredient.defaultProps = {
  quantity: 0,
};

export default Ingredient;
