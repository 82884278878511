import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';
import OrderCart from './OrderCart';
import OrderUserInfo from './OrderUserInfo';
import OrderSummary from './OrderSummary';
import Stepper from './Stepper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const InnerContent = () => {
  const sliderRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [count, setCount] = useState(0);

  /**
   * FIXME: Change timeout system to update slider and so update slider height
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setCount((prevCount) => prevCount + 1);
    }, 500);
    return () => clearTimeout(timer);
  }, [count]);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  const nextStep = () => {
    setActiveStep((actualActiveStep) => actualActiveStep + 1);
  };

  return (
    <>
      <Grid item xs={12}>
        <Stepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Grid>
      <Grid item xs={12}>
        <Slider
          ref={sliderRef}
          dots={false}
          arrows={false}
          infinite={false}
          draggable={false}
          swipe={false}
          lazyLoad="progressive"
          adaptiveHeight
        >
          <OrderCart nextStep={nextStep} />
          <OrderUserInfo nextStep={nextStep} />
          <OrderSummary />
        </Slider>
      </Grid>
    </>
  );
};

export default InnerContent;
