import { useMutation } from '@apollo/react-hooks';
import { setTrackingMutation } from '../../mutations/plans';
import { refetchQueries } from '../../constants';

export default () => {
  const [setTracking, { loading, data, errors }] = useMutation(
    setTrackingMutation,
    { refetchQueries },
  );

  return {
    setTracking,
    loading,
    data: data && data.setTracking,
    errors,
  };
};
