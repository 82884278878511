import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import useGetCheckoutSession from '../../graphql/hooks/order/useGetCheckoutSession';
import { CartContext } from '../../contexts/CartContext';
import LocalLoading from '../../components/LocalLoading';
import Button from '../../components/Button';
import useStyles from './style';

const PaymentLanding = () => {
  const { response } = useParams();
  const { loading, data } = useGetCheckoutSession({ orderSessionId: response });
  const {
    summaryContainer,
    checkIconContainer,
    checkIconStyle,
    linkOrder,
  } = useStyles();
  const { t } = useTranslation('order');
  const [, { cleanCart, cleanOrderId }] = useContext(CartContext);

  useEffect(() => {
    if (response) {
      cleanCart();
      cleanOrderId();
    }
  // eslint-disable-next-line
  }, [response]);

  if (!response) {
    return (
      <Redirect
        to={{
          pathname: '/checkout',
          state: { from: 'payment' },
        }}
      />
    );
  }

  if (loading) return <LocalLoading />;

  const { order: { id: orderId } } = data;

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      alignItems="center"
      className={summaryContainer}
    >
      <Grid item xs={12} container justify="center">
        <Typography variant="h3" align="center">{t('payment-success-title')}</Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <div className={checkIconContainer}>
          <CheckIcon className={checkIconStyle} />
        </div>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Link to={`/order-details/${response}`}>
          <Typography className={linkOrder}>{t('order-n', { orderId })}</Typography>
        </Link>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Typography align="center" variant="body2">
          {t('payment-success-instruction')}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Typography variant="h6">
          {t('enjoy-your-meal')}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
          <Button filled>
            {t('go-to-plan')}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default PaymentLanding;
