import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import isEqual from 'lodash/isEqual';
import { format } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import useEventListener from '@use-it/event-listener';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import { DailyCombinationsContext } from './contexts';
import { usePlanContext } from '../../../contexts/PlanContext';
import { escapeCode, uselessFunction, getLocaleDateFnsFormat } from '../../../libraries/helpers';
import LocalLoading from '../../../components/LocalLoading';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Meal from './Meal';
import { dayCombinationsPropTypes } from '../constants';
import useStyles from './style';

const DailyPlan = ({
  dayCombinations,
  disableActions,
  isCurrentWeek,
  mealSwitchable,
  notPrimary,
  setSwitchMode,
  swapable,
  switchMode,
  switchWithToday,
  trackable,
  week,
  day,
}) => {
  const { t, i18n: { language: currentLanguage } } = useTranslation('dashboard');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleConfirmDialogClose = () => { setConfirmDialog(false); };

  const isSwitchTarget = !!(swapable && switchMode && switchMode !== dayCombinations.day);
  const isSwitchSource = !!(swapable && switchMode && switchMode === dayCombinations.day);
  const dayDate = new Date(dayCombinations.date);
  const today = new Date();
  const todayWeekDayString = format(today, 'EEEE');
  const {
    container,
    switchModeElement,
    header,
    buttonHeader,
    fakeButtonHeader,
    body,
  } = useStyles({ notPrimary, isSwitchTarget, isSwitchSource });
  const isToday = todayWeekDayString.toLowerCase() === dayCombinations.day.toLowerCase();

  const [, { setTracking, switchMeal, switchDay }] = usePlanContext();

  /* Switch days */
  const switchModeOn = () => { setSwitchMode(dayCombinations.day); };
  const switchModeOff = () => { setSwitchMode(''); };
  useEventListener('keydown', ({ keyCode }) => {
    if (keyCode === escapeCode) {
      switchModeOff();
    }
  });

  /* Handle selected and active combination */
  const initialActivatedCombinationsState = { lunch: null, dinner: null };
  const isLunchSelectedYet = (
    dayCombinations.lunch.selected
    && dayCombinations.lunch.selected.selectedGroup
  );
  const isDinnerSelectedYet = (
    dayCombinations.dinner.selected
    && dayCombinations.dinner.selected.selectedGroup
  );
  const initialPreselectedActiveCombinations = {
    lunch: isLunchSelectedYet
      ? dayCombinations.lunch.combinations.findIndex(
        ({ id }) => id === dayCombinations.lunch.selected.selectedGroup.id,
      )
      : null,
    dinner: isDinnerSelectedYet
      ? dayCombinations.dinner.combinations.findIndex(
        ({ id }) => id === dayCombinations.dinner.selected.selectedGroup.id,
      )
      : null,
  };
  const selectedCombinationsState = useState({
    lunch: isLunchSelectedYet ? initialPreselectedActiveCombinations.lunch : 0,
    dinner: isDinnerSelectedYet ? initialPreselectedActiveCombinations.dinner : 0,
  });
  const activatedCombinationsState = useState(initialActivatedCombinationsState);
  const [activatedCombinations, setActivatedCombinations] = activatedCombinationsState;
  const preselectedActiveCombinationsState = useState(initialPreselectedActiveCombinations);
  const [
    preselectedActiveCombinations,
    setPreselectedActiveCombinations,
  ] = preselectedActiveCombinationsState;
  const preselectedRef = useRef(preselectedActiveCombinations);

  const cleanActivatedCombinations = () => {
    setActivatedCombinations(initialActivatedCombinationsState);
  };

  useEffect(() => {
    cleanActivatedCombinations();
    setPreselectedActiveCombinations(initialPreselectedActiveCombinations);
    preselectedRef.current = initialPreselectedActiveCombinations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayCombinations]);

  const thereAreEdits = (
    activatedCombinations.lunch !== null
    || activatedCombinations.dinner !== null
    || !isEqual(preselectedRef.current, preselectedActiveCombinations)
  );

  /* Visibility sensor handler */
  const [dayHasBeenVisible, setDayHasBeenVisible] = useState(false);
  const checkRecommendedIsVisible = (isVisible) => {
    if (isVisible && !dayHasBeenVisible) {
      setDayHasBeenVisible(true);
    }
  };

  const getDayString = (day) => t(`common:${day.toLowerCase()}`);

  const handleSwitchMeal = () => {
    cleanActivatedCombinations();
    switchMeal({ day: dayCombinations.day });
  };

  const handleSwitchDay = () => {
    switchModeOff();
    switchDay({
      fromDay: switchMode,
      toDay: dayCombinations.day,
    });
  };

  const handleSetTracking = () => {
    setTracking({
      day: dayCombinations.day,
      meals: Object.keys(activatedCombinations)
        .map((meal) => ({
          meal_id: dayCombinations[meal].meal.id,
          group_id: (
            activatedCombinations[meal] !== null
              || preselectedActiveCombinations[meal] !== null
          )
            ? dayCombinations[meal].combinations[
              activatedCombinations[meal] !== null
                ? activatedCombinations[meal]
                : preselectedActiveCombinations[meal]
            ].id
            : null,
        })),
    });
    handleConfirmDialogClose();
  };

  const handleConfirmClick = () => {
    if (Object.keys(activatedCombinations).find((meal) => (
      activatedCombinations[meal] !== null
      && preselectedActiveCombinations[meal] !== null
    ) || !isEqual(preselectedRef.current, preselectedActiveCombinations))) {
      setConfirmDialog(true);
    } else {
      handleSetTracking();
    }
  };

  const renderTitle = () => {
    const weekDayString = getDayString(dayCombinations.day);
    const formatDayDate = format(dayDate, 'd LLLL', { locale: dateFnsLocales[getLocaleDateFnsFormat(currentLanguage)] });
    if (isToday && isCurrentWeek) return `${t('common:today')} | ${weekDayString}`;
    if (!isCurrentWeek) return `${weekDayString} ${formatDayDate}`;
    return weekDayString;
  };

  const renderHeader = () => {
    if (switchWithToday) {
      return (
        <Grid item xs>
          <ButtonBase
            onClick={switchWithToday || uselessFunction}
            className={buttonHeader}
          >
            {renderTitle()}
            <Box className={fakeButtonHeader}>{t('switch-with-today')}</Box>
          </ButtonBase>
        </Grid>
      );
    }

    if (isSwitchSource) {
      return (
        <Grid item xs>
          <ButtonBase
            onClick={switchModeOff}
            className={buttonHeader}
          >
            {t('choose-target')}
            <Box className={fakeButtonHeader}>{t('common:cancel')}</Box>
          </ButtonBase>
        </Grid>
      );
    }

    if (isSwitchTarget) {
      return (
        <Grid item xs>
          <ButtonBase
            onClick={handleSwitchDay}
            className={buttonHeader}
          >
            {t('switch-with', { dayFrom: getDayString(switchMode), dayTo: getDayString(dayCombinations.day) })}
            <Box className={fakeButtonHeader}>{t('common:confirm')}</Box>
          </ButtonBase>
        </Grid>
      );
    }

    return (
      <Grid item xs>
        <ButtonBase
          disabled={!swapable}
          onClick={switchModeOn}
          className={buttonHeader}
        >
          {renderTitle()}
          {swapable ? <Box className={fakeButtonHeader}>{t('switch')}</Box> : null}
        </ButtonBase>
      </Grid>
    );
  };

  return (
    <DailyCombinationsContext.Provider
      value={{
        trackable,
        disableActions,
        selected: selectedCombinationsState,
        activated: activatedCombinationsState,
        preselected: preselectedActiveCombinationsState,
      }}
    >
      <VisibilitySensor
        partialVisibility
        offset={{ top: 300 }}
        onChange={checkRecommendedIsVisible}
      >
        <Grid item container className={container}>
          {/* Day Switch Mode overlay */}
          <Fade in={isSwitchTarget}>
            <Box
              className={switchModeElement}
              width="100%"
              height="100%"
              position="absolute"
              display="flex"
              justifyContent="center"
              alignItems="center"
            />
          </Fade>

          <Grid item className={header} xs>
            <Grid container justify="center" spacing={1}>
              {renderHeader()}
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={body}
          >
            {dayHasBeenVisible ? (
              <>
                <Meal
                  label="lunch"
                  mealCombinations={dayCombinations.lunch.combinations}
                  preselectedIndex={initialPreselectedActiveCombinations.lunch}
                  week={week}
                  day={day}
                  meal={3}
                />
                <Meal
                  label="dinner"
                  handleMealSwitch={!disableActions && mealSwitchable && handleSwitchMeal}
                  mealCombinations={dayCombinations.dinner.combinations}
                  preselectedIndex={initialPreselectedActiveCombinations.dinner}
                  week={week}
                  day={day}
                  meal={5}
                />
                <Collapse in={thereAreEdits}>
                  <Grid item container justify="center">
                    <Button
                      fat
                      filled
                      hidden={!thereAreEdits}
                      onClick={handleConfirmClick}
                    >
                      {t('confirm-selection')}
                    </Button>
                  </Grid>
                </Collapse>
              </>
            ) : <LocalLoading />}
          </Grid>
        </Grid>
      </VisibilitySensor>

      <Dialog open={confirmDialog} onClose={handleConfirmDialogClose}>
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <Typography variant="h3">{t('common:caution')}</Typography>
          </Grid>
          <Grid item>
            <Typography>{t('dialog-confirm-selection')}</Typography>
          </Grid>
          <Grid item style={{ marginTop: 20 }}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button onClick={handleConfirmDialogClose}>{t('common:cancel')}</Button>
              </Grid>
              <Grid item>
                <Button onClick={handleSetTracking} filled>{t('common:confirm')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </DailyCombinationsContext.Provider>
  );
};

DailyPlan.propTypes = {
  dayCombinations: dayCombinationsPropTypes.isRequired,
  notPrimary: PropTypes.bool,
  disableActions: PropTypes.bool,
  mealSwitchable: PropTypes.bool,
  swapable: PropTypes.bool,
  switchMode: PropTypes.string,
  switchWithToday: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  setSwitchMode: PropTypes.func,
  trackable: PropTypes.bool,
  isCurrentWeek: PropTypes.bool,
  week: PropTypes.number,
  day: PropTypes.number,
};

DailyPlan.defaultProps = {
  notPrimary: false,
  disableActions: false,
  mealSwitchable: false,
  swapable: false,
  switchMode: '',
  switchWithToday: false,
  setSwitchMode: () => {},
  trackable: false,
  isCurrentWeek: false,
  week: null,
  day: null,
};

export default DailyPlan;
