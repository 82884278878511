import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import { useStepStyles } from './style';

const StepIcon = ({ active, completed, index }) => {
  const { root, completed: completedStyle, active: activeStyle } = useStepStyles();

  return (
    <div
      className={classNames(root, {
        [completedStyle]: completed,
        [activeStyle]: active,
      })}
    >
      {completed ? <CheckIcon /> : index}
    </div>
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  completed: PropTypes.bool,
};

StepIcon.defaultProps = {
  active: false,
  index: null,
  completed: false,
};

export default StepIcon;
