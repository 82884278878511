import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ButtonBase from '@material-ui/core/ButtonBase';
import { uselessFunction } from '../../libraries/helpers';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';
import useStyles from './style';

const Ingredients = ({ data, selectedIngredient, selectIngredient }) => {
  const { capitalize } = useTheme().useClasses();
  const {
    iconContainer,
    ingredientIcon,
    ingredientPrimaryText,
    actionStyle,
    timesIconStyle,
  } = useStyles();
  const mainData = selectedIngredient || data;

  const handleClick = (id) => () => { selectIngredient(id); };
  const handleDeselectIngredient = () => { selectIngredient(); };

  /* eslint-disable react/prop-types */
  const renderListItem = ({
    icon,
    id,
    name,
    food,
  }) => (
    <ListItem
      key={id}
      button={!selectedIngredient}
      onClick={selectedIngredient ? uselessFunction : handleClick(id)}
    >
      <ListItemIcon className={iconContainer}>
        {(icon || food.image) && (
          <img
            className={ingredientIcon}
            src={(icon && icon.url) || food.image.url}
            alt="ingredient icon"
          />
        )}
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={food && food.name}
        classes={{
          primary: classNames(ingredientPrimaryText, capitalize),
          secondary: capitalize,
        }}
      />
      <ListItemSecondaryAction>
        <Zoom in={!!selectedIngredient}>
          <ButtonBase
            onClick={handleDeselectIngredient}
            className={actionStyle}
          >
            <TimesIcon className={timesIconStyle} />
          </ButtonBase>
        </Zoom>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <List>
      {mainData.map(renderListItem)}
    </List>
  );
};

Ingredients.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  selectedIngredient: PropTypes.arrayOf(PropTypes.shape()),
  selectIngredient: PropTypes.func.isRequired,
};

Ingredients.defaultProps = {
  data: [],
  selectedIngredient: null,
};

export default Ingredients;
