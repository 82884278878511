import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import CheckIcon from '@material-ui/icons/Check';
import { useCartContext } from '../../contexts/CartContext';
import { ReactComponent as BoxIcon } from '../../assets/icons/boxBlank.svg';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  titleIconStyle: { color: primary.dark },
}));

const CartTitle = () => {
  const { t } = useTranslation('order');
  const [{ boxCompleted, itemsCount }] = useCartContext();

  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
      style={{ height: 60 }}
    >
      <Grid item>
        <BoxIcon height={44} className={useStyles().titleIconStyle} />
      </Grid>

      <Grid item>
        <Typography variant="h3">
          {t('n-dishes', { count: itemsCount })}
        </Typography>
      </Grid>

      <Grid item>
        <Grow in={!!boxCompleted}>
          <CheckIcon
            fontSize="large"
            className={useStyles().titleIconStyle}
          />
        </Grow>
      </Grid>
    </Grid>
  );
};

export default CartTitle;
