import CartIcon from '../../components/CartIcon';
import NotificationIcon from '../../components/NotificationIcon';
import { ReactComponent as DashboardIcon } from '../../assets/icons/user.svg';
import { ReactComponent as OrderDishesIcon } from '../../assets/icons/orderDishes.svg';
import { ReactComponent as ShoppingListIcon } from '../../assets/icons/shoppingList.svg';
import { ReactComponent as DishesIcon } from '../../assets/icons/ordina.svg';
import { ReactComponent as RecipesIcon } from '../../assets/icons/ricette.svg';
import { ReactComponent as PlanIcon } from '../../assets/icons/piano.svg';
import { ReactComponent as PointsIcon } from '../../assets/icons/punti.svg';
import { ReactComponent as TodayIcon } from '../../assets/icons/today.svg';

/**
 * Main App bar items
 *
 * name: localization label
 * link: route to on click
 * icon: icon to show next to
 * disabled: show but set inactive
 * justAuthUserIs: [Filter]
 *    'undefined' show always,
 *    'true' show if user is logged in,
 *    'false' show if user is logged out
 * justUserPlanIs: [Filter]
 *    'undefined' show always,
 *    'true' show if user has plan,
 *    'false' show if user has not plan
 */
const navigationItems = [{
  name: 'plan-today',
  link: 'dashboard',
  icon: TodayIcon,
  justUserPlanIs: true,
}, {
  name: 'calendar',
  link: 'dashboard/week',
  icon: PlanIcon,
  justUserPlanIs: true,
}, {
  name: 'dishes',
  link: 'it/piatti',
  icon: DishesIcon,
}, {
  name: 'recipes',
  link: 'it/ricette',
  icon: RecipesIcon,
}, {
  name: 'how-it-works',
  link: 'it/come-funziona',
  justUserPlanIs: false,
}, {
  name: 'about-us',
  link: 'it/chi-siamo',
  justUserPlanIs: false,
}, {
  name: 'points',
  icon: PointsIcon,
  justUserPlanIs: true,
  disabled: true,
}];

const userItems = [
  { name: 'orders' },
  { name: 'shopping-lists' },
  { name: 'favorites', disabled: true },
  { name: 'profile', divider: true },
  { name: 'contacts', disabled: true },
  { name: 'faq', divider: true, disabled: true },
];

const quickItems = [{
  name: 'dishes',
  render: OrderDishesIcon,
  justOnMobile: true,
}, {
  name: 'dashboard',
  authenticated: true,
  items: [
    { name: 'today' },
    { name: 'week' },
    { name: 'diary' },
    { name: 'tracking', disabled: true },
    { name: 'map', disabled: true },
    { name: 'fitness', disabled: true },
  ],
  render: DashboardIcon,
}, {
  name: 'cart',
  link: 'checkout',
  render: CartIcon,
}, {
  name: 'shopping-list',
  link: 'shopping-lists',
  render: ShoppingListIcon,
  authenticated: true,
}, {
  name: 'notifications',
  render: NotificationIcon,
  selfHandleClick: true,
  authenticated: true,
  hideOnMobile: true,
}];

/**
 * Main drawer items
 *
 * @param {String} name - Localizatied label
 * @param {String} link - On click route to
 * @param {Element} icon - Icon to show next to
 * @param {Function} onClick - Override on click behaviour
 * @param {Number} specialAction - Identify item style
 * @param {Boolean} disabled - Show but set inactive
 * @param {Boolean} comingSoon - Coming soon badge
 * @param {Boolean} divider - Display a divider
 * @param {Boolean} justAuthUserIs - [Filter]
 *    'undefined' show always,
 *    'true' show if user is logged in,
 *    'false' show if user is logged out
 * @param {Boolean} justUserPlanIs - [Filter]
 *    'undefined' show always,
 *    'true' show if user has plan,
 *    'false' show if user has not plan
 * @param {Boolean} justDeviceMobileIs - [Filter]
 *    'undefined' show always,
 *    'true' show if device is mobile,
 *    'false' show if device is not mobile
 */
const drawerItems = [{
  name: 'dishes',
  link: '/it/piatti',
  justDeviceMobileIs: true,
}, {
  name: 'recipes',
  link: '/it/ricette',
  justDeviceMobileIs: true,
}, {
  name: 'how-it-works',
  link: '/it/come-funziona',
}, {
  divider: true,
  justDeviceMobileIs: true,
}, {
  name: 'drawer-plan',
  link: '/dashboard',
  justAuthUserIs: true,
  justUserPlanIs: true,
}, {
  name: 'drawer-map',
  disabled: true,
  comingSoon: true,
  justUserPlanIs: true,
}, {
  name: 'drawer-shopping-lists',
  link: '/shopping-lists',
  justAuthUserIs: true,
  justUserPlanIs: true,
}, {
  divider: true,
  justAuthUserIs: true,
  justUserPlanIs: true,
}, {
  name: 'drawer-orders',
  link: '/orders',
  justAuthUserIs: true,
}, {
  name: 'drawer-profile',
  link: '/profile',
  justAuthUserIs: true,
}, {
  divider: true,
  justAuthUserIs: true,
}, {
  name: 'about-us',
  link: '/it/chi-siamo',
}, {
  name: 'drawer-contacts',
  link: '/it/chi-siamo',
  onClick: () => { window.open('mailto:support@libraway.com', '_blank'); },
}, {
  name: 'drawer-faq',
  disabled: true,
  comingSoon: true,
}, {
  name: 'drawer-exit',
  link: '/signout',
  justAuthUserIs: true,
  specialAction: 1,
}, {
  name: 'signin',
  link: '/signin',
  justAuthUserIs: false,
  specialAction: 1,
}];

export {
  drawerItems,
  navigationItems,
  quickItems,
  userItems,
};
