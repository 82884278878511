import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import { itemsFilter } from '../../libraries/helpers';
import { useAuthContext } from '../../contexts/AuthContext';
import TopRightMenu from './TopRightMenu';
import MenuItem from './MenuItem';
import { navigationItems } from './items';
import librawayLogo from '../../assets/librawayLogo.svg';
import librawayLogoContrasting from '../../assets/librawayLogoContrasting.svg';
import useStyles from './style';

/**
 * Component
 */
const Header = ({ contrasted, whiteBackground }) => {
  const { spacing, breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('sm'));
  const position = useWindowScrollPosition({ throttle: 0 });
  const shrink = position.y >= spacing(5.5);
  const {
    appbar,
    headerHeight,
    container,
    logoContainer,
    logo,
    menuItemsContainer,
  } = useStyles({ shrink, contrasted, whiteBackground });
  const { isLoggedIn, user: { hasPlan } } = useAuthContext();

  const getLogo = () => {
    if (shrink) return librawayLogo;
    if (contrasted) return librawayLogoContrasting;

    return librawayLogo;
  };

  const footerFilter = ({ justFooter }) => !justFooter;

  return (
    <AppBar
      position="fixed"
      color="default"
      className={classNames(appbar, headerHeight)}
      elevation={0}
    >
      <Container className={container}>
        <Toolbar style={{ padding: 0 }}>
          <RouterLink to="/" className={logoContainer}>
            <img
              src={getLogo()}
              alt="Libraway logo"
              className={logo}
              style={{ height: shrink && 40 }}
            />
          </RouterLink>

          <Grid container alignItems="center" justify="space-between">
            {!isMobileView ? (
              <Grid item>
                <Grid container alignItems="center" className={menuItemsContainer}>
                  {navigationItems
                    .filter(itemsFilter(isLoggedIn, 'justAuthUserIs'))
                    .filter(itemsFilter(hasPlan, 'justUserPlanIs'))
                    .filter(footerFilter)
                    .map(({
                      link,
                      name,
                      icon,
                      disabled,
                    }) => (
                      <Grid key={link || name} item>
                        <MenuItem
                          link={link}
                          name={name}
                          icon={icon}
                          disabled={disabled}
                          contrasted={contrasted}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ) : <Grid item />}

            <Grid item>
              <TopRightMenu
                contrasted={contrasted}
                shrink={shrink}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  contrasted: PropTypes.bool,
  whiteBackground: PropTypes.bool,
};

Header.defaultProps = {
  contrasted: false,
  whiteBackground: false,
};

export default Header;
