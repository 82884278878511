import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import ComingSoon from '../../components/ComingSoon';
import useResponsive from '../../hooks/useResponsive';
import nigefLogo from '../../assets/nigef.png';
import combinationsDesktopImage from '../../assets/combinazioni_desktop.png';
import combinationsMobileImage from '../../assets/combinazioni_mobile_orizzontale.png';
import useStyles from './style';

const HowItWorksPlans = () => {
  const { t } = useTranslation('howItWorks');
  const { isSmallView } = useResponsive();
  const {
    innerContainer,
    nigefLogoStyle,
    howItWorksPlansTitle,
    combinationsDesktopStyle,
    mobileFakeCarousel,
  } = useStyles();

  return (
    <Container className={innerContainer} style={{ textAlign: isSmallView ? 'center' : 'left' }}>
      <Grid container alignItems="center" spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom variant="h2">
            {t('how-it-works')}
          </Typography>

          <Grid container spacing={isSmallView ? 2 : 6} justify="center">
            <Grid item xs={3} md={3}>
              <img src={nigefLogo} alt="Nigef" className={nigefLogoStyle} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: t('how-it-works-text') }}
                />
              </Typography>
            </Grid>
          </Grid>

          <Hidden smUp>
            <Grid container>
              <div className={mobileFakeCarousel}>
                <img
                  src={combinationsMobileImage}
                  alt="Desktop combinations"
                  className={combinationsDesktopStyle}
                />
              </div>
            </Grid>
          </Hidden>

          <Typography
            variant="h3"
            className={howItWorksPlansTitle}
          >
            {t('plan')}
          </Typography>
          <Typography>
            {t('plan-text')}
          </Typography>
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} md={6}>
            <Grid item container justify="center">
              <ComingSoon position={{ right: 0, top: 0 }}>
                <img
                  src={combinationsDesktopImage}
                  alt="Desktop combinations"
                  width="100%"
                  className={combinationsDesktopStyle}
                />
              </ComingSoon>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default HowItWorksPlans;
