import { makeStyles } from '@material-ui/core/styles';
import { cardSizes } from '../../theme/common/constants';

const radius = 10;

const getSplitColor = (color = '', index) => color.split('.')[index];

const useStyles = makeStyles(({ breakpoints, spacing, palette }) => {
  const {
    secondary,
    grey,
    black,
    red,
    common,
  } = palette;

  return ({
    container: {
      borderRadius: radius,
      cursor: ({ cursor }) => (cursor ? 'pointer' : 'default'),
      minWidth: cardSizes.xl.width,
      maxWidth: cardSizes.xl.width,
      height: cardSizes.xl.height,
      outline: 'none',
      userSelect: 'none',
      WebkitTapHighlightColor: 'transparent',
      boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
      [breakpoints.down('lg')]: {
        minWidth: cardSizes.lg.width,
        maxWidth: cardSizes.lg.width,
      },
      [breakpoints.down('md')]: {
        minWidth: cardSizes.md.width,
        maxWidth: cardSizes.md.width,
        height: cardSizes.md.height,
        fontSize: '0.8rem',
      },
      [breakpoints.down('xs')]: {
        minWidth: cardSizes.xs.width,
        maxWidth: cardSizes.xs.width,
        height: cardSizes.xs.height,
        fontSize: '0.8rem',
      },
    },

    imageStyle: {
      position: 'relative',
      height: '100%',
      width: '100%',
      borderRadius: `${radius}px ${radius}px 0 0`,
      overflow: 'hidden',
      backgroundColor: grey.main,
      backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: '50%',
    },

    fullHeight: { height: '100%' },

    foodStyle: {
      pointerEvents: 'none',
      height: 35,
      [breakpoints.down('md')]: {
        height: 25,
      },
      [breakpoints.down('sm')]: {
        height: 20,
      },
    },

    body: {
      maxHeight: '50%',
      padding: spacing(2),
      [breakpoints.down('md')]: {
        padding: spacing(1, 1.5),
      },
    },

    titleStyle: {
      flexGrow: 1,
      fontSize: '1.15rem',
      fontWeight: 900,
      overflow: 'hidden',
      maxHeight: 77,
      lineHeight: 1.4,
      [breakpoints.down('md')]: {
        fontSize: '0.9rem',
        textTransform: 'lowercase',
        '&::first-letter': { textTransform: 'uppercase' },
      },
      [breakpoints.down('xs')]: {
        maxHeight: 41,
        fontSize: '0.75rem',
        lineHeight: 1.2,
      },
    },

    valuationStyle: {
      width: 'auto',
      flexGrow: 1,
    },

    valuationText: {
      lineHeight: `${spacing(3.5)}px`,
      [breakpoints.down('md')]: {
        lineHeight: '23px',
      },
      [breakpoints.down('xs')]: {
        lineHeight: `${spacing(1.5)}px`,
      },
    },

    footerIcon: {
      marginRight: spacing(1.5),
      [breakpoints.down('md')]: {
        marginRight: spacing(),
        width: 20,
        height: 20,
      },
      [breakpoints.down('xs')]: {
        marginRight: spacing(),
        width: 12,
        height: 12,
      },
    },

    cartButton: {
      padding: spacing(1.5, 3),
      height: 'auto',
      fontSize: '1.1rem',
      [breakpoints.down('md')]: {
        fontSize: ({ alreadyInCart }) => (alreadyInCart ? '0.9rem' : '1.1rem'),
        padding: ({ alreadyInCart }) => (alreadyInCart ? spacing(0.9, 2) : spacing(0.7, 2)),
      },
    },

    cartButtonIcon: {
      marginLeft: ({ alreadyInCart }) => (alreadyInCart ? spacing() : 0),
      [breakpoints.down('md')]: {
        height: 15,
        marginLeft: () => 0,
      },
      '& path': {
        fill: ({ alreadyInCart }) => (alreadyInCart ? common.white : black.dark),
      },
    },

    captionPrice: {
      color: grey.dark,
      textTransform: 'uppercase',
      display: 'block',
      [breakpoints.down('sm')]: {
        fontSize: '0.5rem',
      },
    },

    finalPrice: {
      fontWeight: 900,
      lineHeight: 1,
      fontSize: '1.4rem',
      display: 'inline-block',
      [breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '0.8rem',
      },
    },

    oldPrice: {
      color: red.main,
      lineHeight: 1,
      display: 'inline-block',
      textDecoration: 'line-through',
      marginLeft: spacing(),
      fontSize: '0.8rem',
      [breakpoints.down('md')]: {
        display: 'block',
        marginLeft: 0,
        fontSize: '0.8rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.6rem',
      },
    },

    favouriteIcon: {
      cursor: 'pointer',
      width: spacing(4),
      height: spacing(4),
      backgroundColor: ({ favourited }) => (favourited ? secondary.main : common.white),
      borderRadius: 999,
      position: 'absolute',
      bottom: spacing(2),
      right: spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 200ms ease',
      '& svg': {
        height: 16,
        width: 16,
      },
      '& svg path': {
        stroke: ({ favourited }) => (favourited ? common.white : common.black),
      },
      [breakpoints.down('xs')]: {
        width: spacing(3),
        height: spacing(3),
        bottom: spacing(1),
        right: spacing(1),
        '& svg': {
          height: 12,
          width: 12,
        },
      },
    },

    badgeBox: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: spacing(0, 1.5),
      color: common.white,
      textTransform: 'uppercase',
      backgroundColor: ({ badgeColor }) => (
        badgeColor
          ? palette[getSplitColor(badgeColor, 0)][getSplitColor(badgeColor, 1)]
          : 'transparent'
      ),
      borderRadius: `${radius}px 0`,
      [breakpoints.down('sm')]: {
        padding: spacing(0.75, 1.5),
      },
    },
  });
});

export default useStyles;
