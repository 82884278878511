import gql from 'graphql-tag';

const checkShippingMutation = gql`
  mutation CheckShipping(
    $name: String!,
    $surname: String!,
    $address: String!,
    $zip: String!,
    $city: String!,
    $state: String,
  ) {
    checkShipping(address: {
      first_name: $name,
      last_name: $surname,
      address: $address,
      zip: $zip,
      city: $city,
      state: $state,
    }) {
      shippings {
        date,
        courierName: courier_name,
        shippingHours: shipping_hours,
      },
    }
  }
`;

const checkCouponMutation = gql`
  mutation CheckCoupon($code: String) {
    checkCoupon(code: $code) {
      id,
      label,
      code,
      type,
      amount,
      freeShipping: free_shipping
    }
  }
`;

const createOrderMutation = gql`
  mutation CreateOrder(
    $id: ID,
    $cart: [CartRequest]!,
    $shippingAddress: AddressInput!,
    $shippingDate: ShippingDateInput!,
    $shippingContact: ShippingContact!,
    $privacyPrivate: Boolean,
    $privacyCommercial: Boolean,
    $coupon: ID,
  ) {
    createOrder(order: {
      id: $id,
      cart: $cart,
      shipping_address: $shippingAddress,
      shipping_date: $shippingDate,
      shipping_contact: $shippingContact,
      privacy_consent: $privacyPrivate,
      marketing_consent: $privacyCommercial,
      coupon: $coupon,
    }) {
      order {
        id,
        label,
      }
      session
    }
  }
`;

export { checkShippingMutation, checkCouponMutation, createOrderMutation };
