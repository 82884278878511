import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import useStyles from './style';

const defaultIngredientIcon = 'https://media.libraway.com/135/5e8736796bcdd_Pasta.svg';

const IngredientIcon = ({ icon, quantity, isGrouped }) => {
  const { ingredientIcon, quantityBadge } = useStyles();

  return (
    <Badge
      classes={{ badge: quantityBadge }}
      badgeContent={`x${quantity}`}
      overlap="circle"
      invisible={isGrouped || quantity === 0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <img
        alt="ingredient-icon"
        src={icon ? icon.url : defaultIngredientIcon}
        className={ingredientIcon}
      />
    </Badge>
  );
};

IngredientIcon.propTypes = {
  icon: PropTypes.shape(),
  quantity: PropTypes.number,
  isGrouped: PropTypes.bool,
};

IngredientIcon.defaultProps = {
  icon: null,
  quantity: 0,
  isGrouped: false,
};

export default IngredientIcon;
