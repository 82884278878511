import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import useRequestStandardPlan from '../../../graphql/hooks/generic/useRequestStandardPlan';
import useUpgradeStandardPlan from '../../../graphql/hooks/generic/useUpgradeStandardPlan';
import useReactHookForm from '../../../hooks/useReactHookForm';
import useDateFns from '../../../hooks/useDateFns';
import { useAuthContext } from '../../../contexts/AuthContext';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import PrivacyCheckbox from '../../../components/PrivacyCheckbox';
import CollapseError from '../../../components/CollapseError';
import DatePicker from '../../../components/DatePicker';
import useStyles from './style';

const PremiumPlanForm = () => {
  const { t } = useTranslation(['howItWorks', 'profile', 'authentication']);
  const history = useHistory();
  const { modalContainer, planText } = useStyles();
  const { login, isLoggedIn, user } = useAuthContext();
  const { format } = useDateFns();
  const [currentPassword, setCurrentPassword] = useState(null);
  const [currentBirthdate, setCurrentBirthdate] = useState(null);
  const [requestStandardPlan, {
    loading: loadingRequest,
    data: dataRequest,
    errors: errorsRequest,
  }] = useRequestStandardPlan();
  const [upgradeStandardPlan, {
    loading: loadingUpgrade,
    data: dataUpgrade,
    errors: errorsUpgrade,
  }] = useUpgradeStandardPlan();
  const {
    useForm,
    registers: {
      getRequiredEmail,
      getRequired,
      getMatch,
      getRequiredMinLength,
    },
  } = useReactHookForm();
  const {
    register,
    getValues,
    errors,
    triggerValidation,
  } = useForm();

  const isLoading = loadingRequest || loadingUpgrade;

  useEffect(() => {
    if (dataRequest?.requestStandardPlan?.status === 'SUCCESS') {
      login(dataRequest.requestStandardPlan.tokens, { redirectTo: '/dashboard' });
    }
    if (dataUpgrade?.upgradeStandardPlan?.status === 'SUCCESS') {
      history.push('/dashboard');
    }
  }, [dataRequest, dataUpgrade]);

  const getErrorField = (field) => {
    const foundFieldError = Object.keys(errors).find((fieldName) => fieldName === field);

    if (foundFieldError) {
      return errors[foundFieldError].message || true;
    }

    return false;
  };

  const handleConfirm = async () => {
    const validation = await triggerValidation();

    if (validation) {
      const {
        email,
        gender,
        height,
        marketing,
        name,
        password,
        'password-check': passwordCheck,
        privacy,
        surname,
        weight,
      } = getValues();

      if (!isLoggedIn) {
        requestStandardPlan({
          variables: {
            input: {
              name,
              last_name: surname,
              email,
              password,
              password_confirmation: passwordCheck,
              privacy_check: privacy,
              marketing_check: marketing,
              weight,
              gender: gender?.toUpperCase(),
              birthdate: format(currentBirthdate, 'yyyy-MM-dd'),
              height,
            },
          },
        });
      } else {
        upgradeStandardPlan({
          variables: {
            input: {
              weight,
              gender: gender?.toUpperCase(),
              birthdate: format(currentBirthdate, 'yyyy-MM-dd'),
              height,
            },
          },
        });
      }
    }
  };

  return (
    <div className={modalContainer}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            {t('base-plan')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="left">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('base-plan-text') }}
              className={planText}
            />
          </Typography>
        </Grid>

        {!isLoggedIn && (
          <>
            <Grid item xs={12} sm={6}>
              <Input
                required
                name="name"
                label={t('profile:name')}
                ref={register(getRequired('name'))}
                hasError={getErrorField('name')}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                name="surname"
                label={t('profile:surname')}
                ref={register(getRequired('surname'))}
                hasError={getErrorField('surname')}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                name="email"
                label={t('email')}
                type="email"
                ref={register(getRequiredEmail('email'))}
                hasError={getErrorField('email')}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                name="password"
                label={t('authentication:password')}
                type="password"
                ref={register(getRequiredMinLength('password', 8))}
                hasError={getErrorField('password')}
                onChange={setCurrentPassword}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                name="password-check"
                label={t('authentication:password-check')}
                type="password"
                ref={register({
                  ...getRequiredMinLength('password-check', 8),
                  ...getMatch(currentPassword),
                })}
                hasError={getErrorField('password-check')}
                disabled={isLoading}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <FormControl disabled={isLoading}>
            <Typography>{t('profile:gender')}</Typography>
            <RadioGroup
              required
              direction="row"
              defaultValue={isLoggedIn && user?.gender?.toLowerCase()}
              hasError={getErrorField('gender')}
            >
              <FormControlLabel
                value="male"
                control={(
                  <Radio
                    name="gender"
                    inputRef={register(getRequired('gender'))}
                  />
                )}
                label={t('profile:male')}
              />
              <FormControlLabel
                value="female"
                control={(
                  <Radio
                    name="gender"
                    inputRef={register(getRequired('gender'))}
                  />
                )}
                label={t('profile:female')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            required
            name="birthdate"
            label={t('profile:birthdate')}
            inputRef={register(getRequired('birthdate'))}
            value={currentBirthdate}
            onChange={setCurrentBirthdate}
            hasError={getErrorField('birthdate')}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            type="number"
            min={0}
            max={999}
            name="height"
            label={t('profile:height')}
            endAdornment="cm"
            ref={register(getRequired('height'))}
            hasError={getErrorField('height')}
            defaultValue={isLoggedIn && user?.height}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            type="number"
            min={0}
            max={999}
            step={5}
            name="weight"
            label={t('profile:weight')}
            endAdornment="kg"
            ref={register(getRequired('weight'))}
            hasError={getErrorField('weight')}
            defaultValue={isLoggedIn && user?.weight}
            disabled={isLoading}
          />
        </Grid>

        {!isLoggedIn && <PrivacyCheckbox register={register} />}

        <Grid item xs={12}>
          <CollapseError errors={errorsRequest || errorsUpgrade} />
        </Grid>

        <Grid item>
          <Button
            filled
            onClick={handleConfirm}
          >
            {t('create-your-plan')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PremiumPlanForm;
