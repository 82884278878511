import gql from 'graphql-tag';

const getShoppingListsQuery = gql`
  query ShoppingLists {
    me {
      shoppingLists: shopping_lists {
        id,
        title,
        shoppingListGroupedItems: shopping_list_grouped_items {
          food {
            id,
            name,
            image { url }
          },
          shoppingListItems: shopping_list_items {
            id,
            item,
            selected,
            quantity,
            unit
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { getShoppingListsQuery };
