import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import librawayV2Theme from '../../theme/v2';
import BodyContainer from '../../components/BodyContainer';
import MetaTags from '../../components/MetaTags';
import Presentation from './Presentation';
import WhatCanWeDo from './WhatCanWeDo';
import TryMenuBanner from './TryMenuBanner';
import Steps from './Steps';
import Dishes from './Dishes';
import OtherServices from './OtherServices';
import AboutUs from './AboutUs';
import sharePreview from '../../assets/sharePreview.png';

const Home = () => {
  const { t } = useTranslation('home');

  return (
    <>
      <MetaTags
        title="Homepage - Libraway"
        description={t('meta-description')}
        image={sharePreview}
      />
      <ThemeProvider theme={librawayV2Theme}>
        <BodyContainer backgroundFixed style={{ paddingBottom: 0 }}>
          <Presentation />
          <WhatCanWeDo />
          <TryMenuBanner />
          <Steps />
          <Dishes />
          <OtherServices />
          <AboutUs />
        </BodyContainer>
      </ThemeProvider>
    </>
  );
};

export default Home;
