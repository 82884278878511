import React, { useEffect } from 'react';
import {
  eachDayOfInterval,
  getDaysInMonth,
  startOfMonth,
  subMonths,
  addMonths,
  setDate,
  max,
  min,
  isWithinInterval,
  lastDayOfMonth,
} from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import ButtonBase from '@material-ui/core/ButtonBase';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { capitalize } from '../../../../libraries/helpers';
import useGetPlanMonth from '../../../../graphql/hooks/plans/useGetPlanMonth';
import useDateFns from '../../../../hooks/useDateFns';
import { usePlanContext } from '../../../../contexts/PlanContext';
import LocalLoading from '../../../../components/LocalLoading';
import { useDiaryContext } from './DiaryContext';
import CellDay from './CellDay';
import { ReactComponent as CaretLeftIcon } from '../../../../assets/icons/caretSlimWhiteLeft.svg';
import { ReactComponent as CaretRightIcon } from '../../../../assets/icons/caretSlimWhiteRight.svg';
import useStyles from './style';

const Calendar = () => {
  const { breakpoints } = useTheme();
  const { getPlanMonth, loading, data } = useGetPlanMonth();
  const {
    cellWeekday,
    monthNavigationButtonRoot,
    monthNavigationButtonDisabled,
    monthHeaderContainer,
    monthBodyContainer,
  } = useStyles();
  const [{ plan }] = usePlanContext();
  const [selectedDate, setSelectedDate] = useDiaryContext();
  const { format, startOfWeek, endOfWeek } = useDateFns();
  const today = new Date();
  const selectedMonth = format(selectedDate, 'L');
  const selectedYear = format(selectedDate, 'y');
  const weekdayFormat = useMediaQuery(breakpoints.down('sm')) ? 'eeeee' : 'eee';

  useEffect(() => {
    getPlanMonth({
      variables: {
        month: selectedMonth,
        year: selectedYear,
      },
    });
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedMonth, selectedYear]);

  const planStartDate = plan && plan.data && new Date(plan.data.startDate);
  const validInterval = { start: planStartDate || today, end: today };
  const minValidDate = max([subMonths(selectedDate, 1), planStartDate]);
  const maxValidDate = min([addMonths(selectedDate, 1), today]);
  const disablePrevMonth = !isWithinInterval(
    lastDayOfMonth(subMonths(selectedDate, 1)),
    validInterval,
  );
  const disableNextMonth = !isWithinInterval(
    startOfMonth(addMonths(selectedDate, 1)),
    validInterval,
  );

  const handlePrevMonth = () => { setSelectedDate(minValidDate); };
  const handleNextMonth = () => { setSelectedDate(maxValidDate); };

  const renderWeekday = (weekday) => (
    <Grid className={cellWeekday} item key={format(weekday, 'eee')}>
      {format(weekday, weekdayFormat)}
    </Grid>
  );

  const renderGridDay = (day, index) => (
    <Grid item key={`${day}-${index}`}>
      {Number.isInteger(day) && (
        <CellDay
          date={setDate(selectedDate, day + 1)}
          status={data && !!data[day] ? {
            lunch: data[day].lunch.status,
            dinner: data[day].dinner.status,
          } : undefined}
          isValid={isWithinInterval(
            setDate(selectedDate, day + 1),
            validInterval,
          )}
        />
      )}
    </Grid>
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid
          className={monthHeaderContainer}
          container
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <ButtonBase
              classes={{
                root: monthNavigationButtonRoot,
                disabled: monthNavigationButtonDisabled,
              }}
              onClick={handlePrevMonth}
              disabled={disablePrevMonth}
            >
              <CaretLeftIcon />
            </ButtonBase>
          </Grid>
          <Grid item>{capitalize(format(selectedDate, 'LLLL'))}</Grid>
          <Grid item>
            <ButtonBase
              classes={{
                root: monthNavigationButtonRoot,
                disabled: monthNavigationButtonDisabled,
              }}
              onClick={handleNextMonth}
              disabled={disableNextMonth}
            >
              <CaretRightIcon />
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={monthBodyContainer}>
          <GridList cellHeight={70} cols={7} style={{ width: '100%', overflow: 'hidden' }}>
            {eachDayOfInterval({
              start: startOfWeek(selectedDate),
              end: endOfWeek(selectedDate),
            }).map(renderWeekday)}
            {loading
              ? <LocalLoading />
              : [
                ...new Array(parseInt(format(startOfMonth(selectedDate), 'i'), 10) - 1),
                ...new Array(getDaysInMonth(selectedDate)).keys(),
              ].map(renderGridDay)}
          </GridList>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Calendar;
