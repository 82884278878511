import { makeStyles } from '@material-ui/core/styles';
import { headerHeight } from '../../../libraries/helpers';
import ListBullet from '../../../assets/listBullet.png';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { primary, secondary, white } }) => ({
  title: {
    marginBottom: spacing(6),
  },

  firstPanel: {
    backgroundPositionX: 0,
    [breakpoints.down('md')]: {
      backgroundPositionX: -700,
    },
  },
  intro: { height: `calc(100vh - ${spacing(headerHeight)}px)` },
  body: { paddingTop: spacing(4) },
  description: { marginBottom: spacing(4) },

  planCardContainer: {
    position: 'relative',
    borderRadius: 15,
    textAlign: 'center',
    paddingBottom: spacing(4),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(4),
    },
  },
  planCardHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px 15px 0 0',
    backgroundColor: secondary.main,
    padding: spacing(1, 0),
    color: white.main,
    fontWeight: 900,
    fontSize: '25px',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      height: '42px',
      fontSize: '17px',
    },
  },
  planCardDescription: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: spacing(4),
    paddingBottom: 10,
    fontWeight: 900,
    fontSize: '1.5rem',
    [breakpoints.down('md')]: {
      paddingBottom: 10,
      fontSize: '17px',
    },
  },
  planCardFeatures: {
    textAlign: 'left',
    display: 'inline-block',
    width: '100%',
    paddingLeft: spacing(3),
    minHeight: 230,
    '& > ul': {
      listStyleImage: `url(${ListBullet})`,
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      [breakpoints.down('sm')]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      '& > li': {
        minHeight: spacing(4),
        paddingLeft: spacing(),
        paddingBottom: spacing(1.5),
      },
    },
  },
  disabledStyle: { opacity: 0.4 },
  formContainer: {
    borderRadius: 30,
    padding: spacing(4),
  },
  formRow: {
    marginBottom: spacing(4),
    [breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },

  modalContainer: {
    margin: spacing(4, 2),
    minWidth: 500,
    [breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },

  featureStyle: {
    fontSize: '16px',
    lineHeight: '25px',
  },

  planText: {
    '& a': {
      color: primary.dark,
    },
  },
}));

export default useStyles;
