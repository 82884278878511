import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  timeline: {
    padding: 0,
  },

  typography: {
    lineHeight: 2,
  },

  oppositeContent: { flexGrow: 1 },
  mainContent: {
    flexGrow: 2.5,
    textAlign: 'left',
  },
});

export default useStyles;
