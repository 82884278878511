import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StepperMui from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Typography } from '@material-ui/core';
import StepIcon from './StepIcon';
import Mobile from './Mobile';
import useStyles from '../style';
import { useConnectorStyles } from './style';

const Stepper = ({ activeStep, setActiveStep }) => {
  const { t } = useTranslation('order');
  const { stepperContainer } = useStyles({ border: true });
  const connectorStyles = useConnectorStyles();
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('sm'));

  const steps = [t('step-cart'), t('step-delivery'), t('step-summary')];

  const isStepComplete = (step) => activeStep > step;

  const handleStepButtonClick = (index) => () => {
    setActiveStep(index);
  };

  /**
   * Mobile version
   */
  if (isMobileView) {
    return (
      <Mobile
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    );
  }

  /**
   * Desktop version
   */
  return (
    <div className={stepperContainer}>
      <StepperMui
        alternativeLabel
        activeStep={activeStep}
        connector={<StepConnector classes={connectorStyles} />}
      >
        {steps.map((step, index) => (
          <Step key={step}>
            <StepButton
              completed={isStepComplete(index)}
              onClick={handleStepButtonClick(index)}
            >
              <StepLabel
                StepIconComponent={({ active, completed }) => (
                  <StepIcon
                    active={active}
                    completed={completed}
                    index={index + 1}
                  />
                )}
              >
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: index === activeStep ? 700 : 300,
                    fontSize: 13.33,
                    lineHeight: 1,
                  }}
                >
                  {step}
                </Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </StepperMui>
    </div>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
};

Stepper.defaultProps = {
  activeStep: 0,
  setActiveStep: () => {},
};

export default Stepper;
