import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { secondary }, breakpoints }) => ({
  labelStyle: {
    paddingBottom: spacing(),
  },

  root: {
    border: `2px solid ${secondary.main}`,
    padding: `5px ${spacing(3)}px`,
    borderRadius: 999,
    minHeight: ({ fat }) => (fat ? spacing(9) : 0),
    fontWeight: 900,

    [breakpoints.down('sm')]: {
      padding: spacing(0, 3),
      '& select': {
        minHeight: 28,
      },
    },

    '& select:focus': {
      backgroundColor: 'transparent',
    },

    '& .MuiSvgIcon-root': {
      right: spacing(3),
    },
  },
}));

export default useStyles;
