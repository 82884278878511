import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const Select = forwardRef(({
  children,
  defaultValue,
  label,
  name,
  onChange,
  small,
  value,
}, ref) => {
  const { labelStyle, root } = useStyles({ small });

  return (
    <Grid container direction="column">
      {label && <span className={labelStyle}>{label}</span>}
      <NativeSelect
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        className={root}
        inputProps={{ name, ref }}
        disableUnderline
      >
        {children}
      </NativeSelect>
    </Grid>
  );
});

Select.propTypes = {
  children: childrenPropType,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  small: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Select.defaultProps = {
  children: null,
  defaultValue: undefined,
  label: '',
  name: '',
  onChange: undefined,
  small: false,
  value: undefined,
};

export default Select;
