import i18n from 'i18next';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import Pusher from 'pusher-js';
import { librawayStorage } from './helpers';
import PusherLink from './pusher';

const { accessToken, tokenType } = librawayStorage.get('authentication') || {};
const completeAccessToken = accessToken ? `${tokenType} ${accessToken}` : '';

const {
  REACT_APP_SERVER_HTTP_URL: serverUri,
  REACT_APP_GRAPHQL_ROUTE: graphqlRoute,
  REACT_APP_PUSHER_API_KEY: pusherApiKey,
  REACT_APP_PUSHER_CLUSTER: pusherCluster,
} = process.env;

const uri = `${serverUri}${graphqlRoute}`;

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  const {
    accessToken: authAccessToken,
    tokenType: authTokenType,
  } = librawayStorage.get('authentication') || {};

  return ({
    headers: {
      ...headers,
      authorization: authAccessToken ? `${authTokenType} ${authAccessToken}` : '',
      language: i18n.language,
    },
  });
});

const pusherLink = new PusherLink({
  pusher: new Pusher(pusherApiKey, {
    cluster: pusherCluster,
    authEndpoint: `${uri}/subscriptions/auth`,
    auth: {
      headers: {
        authorization: completeAccessToken,
      },
    },
  }),
});

const librawayApolloClient = new ApolloClient({
  cache: new InMemoryCache(),

  link: ApolloLink.from([pusherLink, authLink.concat(httpLink)]),
  // link: ApolloLink.from([pusherLink, httpLink]),
  // link: authLink.concat(httpLink),
  // link: authLink.concat(pusherLink).concat(httpLink),

  name: 'libraway-main-client',
  version: '1.0',
  defaultOptions: {
    watchQuery: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
});

export default librawayApolloClient;
