import { useState } from 'react';

const useAppState = () => {
  const headerContrasted = useState(false);
  const orderDishesFilters = useState({ dataType: 'dishes', search: '' });

  return { headerContrasted, orderDishesFilters };
};

export default useAppState;
