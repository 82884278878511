import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import WeeksFilter from './WeeksFilter';
import { ReactComponent as StarIcon } from '../../../assets/icons/starFilled.svg';
import useStyles from './style';

const HeaderPlan = ({ weeksDisabled }) => {
  const { t } = useTranslation('dashboard');
  const { breakpoints } = useTheme();
  const isExtraSmallView = useMediaQuery(breakpoints.down('xs'));
  const { innerIcon, mobileScroll } = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const mealsPillow = [
    {
      slug: 'breakfast',
      label: t('breakfast'),
      disabled: true,
    }, {
      slug: 'morningSnack',
      label: t('morning-snack'),
      disabled: true,
    }, {
      slug: 'afternoonSnack',
      label: t('afternoon-snack'),
      disabled: true,
    }, {
      slug: 'extra',
      label: [t('extra'), <StarIcon key="icon" className={innerIcon} />],
      disabled: true,
    },
  ];

  const handleOpen = () => { setModalOpen(true); };
  const handleClose = () => { setModalOpen(false); };

  // eslint-disable-next-line react/prop-types
  const renderPillow = ({ label, slug, disabled }) => (
    <Button
      key={slug}
      disabled={disabled}
      noBorder
      fitted
      fat
      filled="white"
      onClick={handleOpen}
    >
      {label}
    </Button>
  );

  return (
    <>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} md={3}>
          <Input placeholder={t('search-ingredients')} disabled />
        </Grid>
        <Grid item container md={6} justify="space-evenly">
          {isExtraSmallView ? (
            <div className={mobileScroll}>
              {mealsPillow.map(renderPillow)}
            </div>
          ) : mealsPillow.map(renderPillow)}
        </Grid>
        <Grid item container alignItems="center" justify="flex-end" md={3}>
          <WeeksFilter disabled={weeksDisabled} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid xs={7} item>
              <Button
                fat={!isExtraSmallView}
                fitted={isExtraSmallView}
                filled
                stretched
              >
                {t('display-alimentary-plan')}
              </Button>
            </Grid>
            <Grid xs={5} item>
              <Button
                fat={!isExtraSmallView}
                fitted={isExtraSmallView}
                filled
                stretched
                disabled
              >
                {t('display-dishes')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog onClose={handleClose} open={modalOpen}>
        ok
      </Dialog>
    </>
  );
};

HeaderPlan.propTypes = {
  weeksDisabled: PropTypes.bool,
};

HeaderPlan.defaultProps = {
  weeksDisabled: false,
};

export default HeaderPlan;
