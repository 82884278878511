import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles(({ spacing, palette: { white, grey } }) => ({
  icon: {
    marginRight: ({ dense }) => spacing(dense ? 0 : 3),
    width: ({ dense }) => (dense ? 25 : 'auto'),
  },

  button: {
    width: ({ stretched }) => (stretched ? '100%' : 'auto'),
    height: spacing(7),
    border: `1px solid ${grey.main}`,
    borderRadius: spacing(4),
    boxShadow: `0 0 8px 2px ${grey.main}`,
    padding: ({ dense }) => dense && spacing(2),
    paddingLeft: ({ dense }) => spacing(dense ? 0 : 3),
    paddingRight: ({ dense }) => spacing(dense ? 0 : 3),
    fontSize: '0.8rem',
    backgroundColor: white.main,
    color: white.contrastText,
    fontWeight: 900,
    letterSpacing: '0.06rem',
  },

  opacity: {
    opacity: ({ active }) => (active ? 1 : 0.5),
    transition: 'opacity 150ms ease',
  },

  textContainer: {
    textAlign: 'center',
    fontWeight: 900,
  },
}));

const Pillow = ({
  active,
  dense,
  disabled,
  icon: Icon,
  onClick,
  stretched,
  text,
}) => {
  const {
    icon,
    button,
    textContainer,
    opacity,
  } = useStyles({ active, dense, stretched });

  if (!dense) {
    return (
      <ButtonBase
        onClick={onClick}
        className={classNames(button, opacity)}
        disabled={disabled}
      >
        <Icon className={icon} />
        <span>{text}</span>
      </ButtonBase>
    );
  }

  return (
    <ButtonBase
      onClick={onClick}
      className={opacity}
      disabled={disabled}
      style={{ padding: 5 }}
    >
      <Grid
        container
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={button}
          >
            <Icon className={icon} />
          </Box>
        </Grid>
        <Grid item className={textContainer}>
          <span>{text}</span>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

Pillow.propTypes = {
  active: PropTypes.bool,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  stretched: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

Pillow.defaultProps = {
  active: false,
  dense: false,
  disabled: false,
  icon: () => null,
  onClick: () => {},
  stretched: false,
};

export default Pillow;
