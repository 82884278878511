import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { white, grey } }) => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: `${white.main}99`,
    filter: 'blur(8px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  label: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.2rem',
    fontWeight: 900,
    color: grey.dark,
  },
}));

export default useStyles;
