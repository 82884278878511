import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../propTypes';

const TabPanel = ({ children, value, index }) => {
  if (value === index) {
    return <div>{children}</div>;
  }

  return null;
};

TabPanel.propTypes = {
  children: childrenPropType,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
