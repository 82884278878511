import React from 'react';
import PropTypes from 'prop-types';
import { isToday, isEqual } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import useDateFns from '../../../../hooks/useDateFns';
import { useDiaryContext } from './DiaryContext';
import Dot from './Dot';
import { trackingStatus } from './constants';
import useStyles from './style';

const CellDay = ({ date, isValid, status }) => {
  const [selectedDate, setSelectedDate] = useDiaryContext();
  const { cellDay, rippleChild } = useStyles({
    isValid,
    isToday: isToday(date),
    isSelected: isEqual(date, selectedDate),
  });
  const { format } = useDateFns();

  const handleDayClick = () => { setSelectedDate(date); };

  return (
    <ButtonBase
      onClick={handleDayClick}
      className={cellDay}
      disabled={!isValid}
      TouchRippleProps={{ classes: { child: rippleChild } }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          {parseInt(format(date, 'd'), 10)}
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container justify="center">
                <Dot type={status.lunch} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justify="center">
                <Dot type={status.dinner} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

CellDay.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isValid: PropTypes.bool,
  status: PropTypes.shape({
    lunch: PropTypes.oneOf(trackingStatus),
    dinner: PropTypes.oneOf(trackingStatus),
  }),
};

CellDay.defaultProps = {
  isValid: true,
  status: { lunch: 'NONE', dinner: 'NONE' },
};

export default CellDay;
