import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../../components/Dialog';
import useResponsive from '../../../hooks/useResponsive';
import useModalOpen from '../../../hooks/useModalOpen';
import { uselessFunction } from '../../../libraries/helpers';
import PlanCard from './PlanCard';
import PremiumPlanForm from './PremiumPlanForm';
import BasePlanForm from './BasePlanForm';
import useCommonStyles from '../style';
import useStyles from './style';

const plansOptions = [{
  type: 'base',
  comingSoon: false,
  ModalElement: BasePlanForm,
}, {
  type: 'pro',
  comingSoon: true,
}, {
  type: 'premium',
  ModalElement: PremiumPlanForm,
}];

const Plans = () => {
  const { t } = useTranslation('plans');
  const { innerContainer } = useCommonStyles();
  const { title: titleStyle, modalContainer } = useStyles();
  const { isSmallView } = useResponsive();
  const { closeModal, openModal, opened } = useModalOpen();
  const [elementToOpen, setElementToOpen] = useState();

  const findPlanByType = (typeToFind) => plansOptions.find((option) => option.type === typeToFind);

  const ElementToOpen = findPlanByType(elementToOpen)?.ModalElement;

  const plansData = t('plans', { returnObjects: true });

  return (
    <>
      <Container className={innerContainer}>
        <Typography
          align="center"
          variant="h2"
          className={titleStyle}
        >
          {t('title')}
        </Typography>

        <Grid container justify="space-between" spacing={isSmallView ? 4 : 9}>
          {plansData.map(({
            type,
            title,
            description,
            features,
          }) => {
            const { comingSoon, ModalElement } = findPlanByType(type);
            const clickBehaviour = ModalElement ? (
              () => {
                setElementToOpen(type);
                openModal();
              }
            ) : uselessFunction;

            return (
              <Grid key={type} item xs={12} md={4}>
                <PlanCard
                  title={title}
                  description={description}
                  features={features}
                  comingSoon={comingSoon}
                  onClick={clickBehaviour}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      {opened && (
        <Dialog
          large
          open={opened}
          onClose={closeModal}
          className={modalContainer}
        >
          <ElementToOpen />
        </Dialog>
      )}
    </>
  );
};

export default Plans;
