import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Item from './Item';
import useStyles from './style';

const Food = ({ food: { name, image }, shoppingListItems }) => {
  const { t } = useTranslation('shoppingList');
  const { itemContainer } = useStyles();
  const { capitalize } = useTheme().useClasses();

  const renderName = () => (name === 'other' ? t(name) : name);

  /* eslint-disable react/prop-types */
  const renderItem = ({
    id,
    item,
    quantity,
    selected,
    unit,
  }) => (
    <Grid item key={id} className={itemContainer}>
      <Item
        id={id}
        label={item}
        quantity={quantity}
        selected={selected}
        unit={unit}
      />
    </Grid>
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="flex-start" spacing={2}>
          <Grid item xs={1}>
            <Grid container alignItems="center" justify="center">
              {image && <img src={image.url} alt="food icon" style={{ height: 40 }} />}
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="h3" className={capitalize}>{renderName()}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {shoppingListItems.map(renderItem)}
    </Grid>
  );
};

Food.propTypes = {
  food: PropTypes.shape(),
  shoppingListItems: PropTypes.arrayOf(PropTypes.shape()),
};

Food.defaultProps = {
  food: {
    id: 'other',
    name: 'other',
  },
  shoppingListItems: [],
};

export default Food;
