import { useLazyQuery } from '@apollo/react-hooks';
import { getRelatedRecipesByRecipeQuery } from '../../queries/recipes';

export default ({ recipesLimit, recipeId }) => {
  const [
    getRelatedRecipesByRecipe,
    { loading, data },
  ] = useLazyQuery(getRelatedRecipesByRecipeQuery, {
    variables: {
      first: recipesLimit,
      recipeId,
    },
  });

  const { recipes: { edges } } = data || { recipes: {} };

  return {
    getRelatedRecipesByRecipe,
    loading,
    data: edges || data,
  };
};
