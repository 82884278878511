import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCartContext } from '../../contexts/CartContext';
import BoxesProgress from '../../components/BoxesProgress';
import CartTitle from './CartTitle';

const BoxesSection = () => {
  const { t } = useTranslation('order');
  const [{ boxCompleted, boxes, itemsCount }] = useCartContext();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <CartTitle />
      </Grid>

      <Grid item style={{ paddingRight: 15 }}>
        {boxes ? (
          <BoxesProgress
            itemsCount={itemsCount}
            boxes={boxes}
            boxCompleted={!!boxCompleted}
          />
        ) : null}
      </Grid>

      <Grid item>
        <Typography style={{ marginBottom: 8 }} variant="body2">
          {t(boxCompleted ? 'box-completed' : 'box-need-to-complete')}
        </Typography>
        <Typography variant="caption">{t('box-disclaimer')}</Typography>
      </Grid>
    </Grid>
  );
};

export default BoxesSection;
