import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette: { secondary, common } }) => ({
  datePickerStyle: {
    borderColor: ({ readOnly }) => (readOnly ? common.white : secondary.main),
    width: '100%',
  },
}));

export default useStyles;
