import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { grey, black } }) => ({
  container: {
    paddingRight: spacing(4),
  },
  subList: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    backgroundColor: grey.light,
    borderBottom: `1px solid ${black.light}`,
  },
  listItem: {
    backgroundColor: 'transparent',
    height: spacing(6),
  },
  listItemIcon: {
    minWidth: spacing(4),
  },
  icon: {
    transition: '300ms ease',
  },
  groupContainer: {
    marginBottom: spacing(4),
  },
  moreButton: {
    justifyContent: 'center',
    fontWeight: 900,
  },
}));

export default useStyles;
