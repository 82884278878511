import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCartContext } from '../../contexts/CartContext';
import { formatMoney } from '../../libraries/helpers';
import PromoCode from './PromoCode';

const OrderSummaryPartials = () => {
  const { t } = useTranslation('order');
  const { useClasses, breakpoints } = useTheme();
  const { lineThrough, link } = useClasses();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const [{
    boxCompleted,
    couponAmount,
    getCoupon,
    itemsPrice,
  }] = useCartContext();

  const getShippingPrice = () => (
    boxCompleted.shippingPrice ? formatMoney(boxCompleted.shippingPrice) : t('free-shipping')
  );

  return (
    <>
      <Grid item container justify="space-between">
        <Grid item>
          <Typography variant="h3">{t('subtotal')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3">
            {formatMoney(itemsPrice)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justify="space-between">
        <Grid item>
          <Typography variant="h3">
            {t('shipping')}
            {boxCompleted && boxCompleted.name && (
              <Typography variant="caption">{` (${boxCompleted.name})`}</Typography>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          className={classNames({ [lineThrough]: boxCompleted && getCoupon('freeShipping') })}
        >
          <Typography variant="h3">
            {boxCompleted ? getShippingPrice() : 'nd'}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justify="space-between" spacing={1}>
        <Grid item xs={getCoupon() || 12} md>
          <Typography variant="h3">{t('promo-code')}</Typography>
        </Grid>

        {getCoupon() ? (
          <Grid item xs md="auto">
            <Typography variant="h3" align="right">
              {`- ${formatMoney(couponAmount)}`}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} md="auto">
            <PromoCode />
          </Grid>
        )}

        <Grid item container justify="flex-end">
          <ButtonBase disabled style={{ opacity: 0.4 }}>
            <Typography variant="caption" className={link}>
              {t('view-enable-promos')}
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderSummaryPartials;
