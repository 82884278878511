import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import useGetRecipeQuery from '../../graphql/hooks/recipes/useGetRecipeQuery';
import RecipeContext from './contexts';
import BodyContainer from '../../components/BodyContainer';
import LocalLoading from '../../components/LocalLoading';
import MetaTags from '../../components/MetaTags';
import Main from './Main';

const Recipe = () => {
  const { recipeId } = useParams();
  const { loading, recipe = {} } = useGetRecipeQuery({ recipeId });
  const { title: recipeTitle = '', image = [{ default: '' }] } = recipe;

  return (
    <>
      <MetaTags
        title={recipeTitle}
        image={image && image.length && image[0].default}
      />
      <BodyContainer>
        <Container>
          {loading
            ? <LocalLoading />
            : (
              <RecipeContext.Provider value={recipe}>
                <Main />
              </RecipeContext.Provider>
            )}
        </Container>
      </BodyContainer>
    </>
  );
};

export default Recipe;
