import { useLazyQuery } from '@apollo/react-hooks';
import { getPlanWeekQuery } from '../../queries/plans';

export default () => {
  const [
    getPlanWeek,
    {
      loading,
      data,
      refetch,
      networkStatus,
    },
  ] = useLazyQuery(getPlanWeekQuery, { notifyOnNetworkStatusChange: true });

  return {
    getPlanWeek,
    loading,
    data: data && data.me.plan.week,
    refetch,
    networkStatus,
  };
};
