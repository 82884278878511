import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import useLocationQuery from '../../hooks/useLocationQuery';
import { useAppContext } from '../../contexts/AppContext';
import Input from '../../components/Input';

const TopFilterSearch = () => {
  const query = useLocationQuery();
  const { t } = useTranslation('orderDishes');
  const { orderDishesFilters: [filters, setFilters] } = useAppContext();
  const [inputValue, setInputValue] = useState(filters.search || query.get('search') || '');

  useEffect(() => {
    setFilters({ ...filters, search: inputValue });
  }, [inputValue]);

  const handleSearchChange = (searchValue) => {
    setInputValue(searchValue);
  };

  return (
    <Input
      placeholder={t('search')}
      type="search"
      value={inputValue}
      onChange={debounce(handleSearchChange, 800)}
    />
  );
};

export default TopFilterSearch;
