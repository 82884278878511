import React, { createContext, useContext } from 'react';
import { childrenPropType } from '../components/propTypes';
import usePlan from '../hooks/usePlan';

const PlanContext = createContext([]);

const PlanProvider = ({ children }) => {
  const planState = usePlan();

  return (
    <PlanContext.Provider value={planState}>
      {children}
    </PlanContext.Provider>
  );
};

const usePlanContext = () => useContext(PlanContext);

PlanProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  PlanContext,
  PlanProvider,
  usePlanContext,
};
