import { useLazyQuery } from '@apollo/react-hooks';
import { getPlanWeeksQuery } from '../../queries/plans';

export default () => {
  const [
    getPlanWeeks,
    {
      loading,
      data,
      refetch,
      networkStatus,
    },
  ] = useLazyQuery(getPlanWeeksQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    getPlanWeeks,
    loading,
    data: data && data.me.plan.weeks,
    refetch,
    networkStatus,
  };
};
