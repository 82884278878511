import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { usePlanContext } from '../../../contexts/PlanContext';
import Paper from '../../../components/Paper';
import { ReactComponent as DishesIcon } from '../../../assets/icons/dishesCount.svg';

const DishesStatus = () => {
  const { t } = useTranslation('dashboard');
  const [{ plan = { data: { week: {} } } }] = usePlanContext();

  const { trackedCount = '-', previousTrackedCount = 0 } = plan.data.week;

  return (
    <Paper title={t('dishes-input')}>
      <Grid container direction="column" alignItems="center">
        <DishesIcon />
        <Typography variant="h3">
          {`${trackedCount}/14`}
        </Typography>
        {previousTrackedCount ? (
          <Typography>
            {`${previousTrackedCount} la scorsa settimana`}
          </Typography>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default DishesStatus;
