import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useAuthContext } from '../../../contexts/AuthContext';
import { defaultUpdateQuery, getOrderDishesFilters } from '../../../libraries/helpers';
import { getRecipesQuery, getUserRecipesQuery } from '../../queries/recipes';

export default ({ filters, lazy = false }) => {
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const { isLoggedIn } = useAuthContext();
  const [getRecipes, { loading, data, fetchMore }] = useLazyQuery(
    isLoggedIn ? getUserRecipesQuery : getRecipesQuery,
    {
      variables: {
        first: process.env.REACT_APP_DEFAULT_PAGINATION,
        filter: getOrderDishesFilters(filters),
      },
    },
  );

  useEffect(() => {
    if (!lazy) {
      getRecipes();
    }
  }, [getRecipes, filters, lazy]);

  const { recipes: { edges, pageInfo } } = data || { recipes: {} };

  return {
    getRecipes,
    loading,
    data: edges || data,
    loadingFetchMore,
    hasNextPage: pageInfo && pageInfo.hasNextPage,
    fetchMore: data
      ? () => {
        setLoadingFetchMore(true);
        fetchMore({
          variables: { after: pageInfo ? pageInfo.endCursor : null },
          updateQuery: defaultUpdateQuery('recipes', setLoadingFetchMore),
        });
      }
      : fetchMore,
  };
};
