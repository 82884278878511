import gql from 'graphql-tag';

const getRegistryQuery = gql`
  query Registry {
    me {
      name,
      surname: last_name,
      height,
      weight,
      gender,
      birthdate,
      email
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { getRegistryQuery };
