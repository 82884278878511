const typographyEnhancer = (muiTheme) => {
  const theme = muiTheme;
  const fontFamily = 'Nunito Sans, sans-serif';

  theme.typography.fontFamily = fontFamily;

  theme.typography.h1 = {
    fontSize: '40px',
    fontWeight: 900,
    lineHeight: '55px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '32px',
      letterSpacing: 0,
    },
  };

  theme.typography.h2 = {
    fontSize: '27px',
    fontWeight: 900,
    lineHeight: '27px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
      lineHeight: '29px',
    },
  };

  theme.typography.h3 = {
    fontSize: '20px',
    fontWeight: 900,
  };

  theme.typography.h6 = {
    fontSize: '15px',
    fontWeight: 700,
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
  };

  theme.typography.body1 = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  };

  theme.typography.body2 = {
    fontSize: '17px',
    fontWeight: 700,
    lineHeight: '24px',
  };

  return theme;
};

export default typographyEnhancer;
