import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import librawayV2Theme from '../../theme/v2';
import { useDishContext } from '../../contexts/DishContext';
import Tab from '../../components/Tab';
import TabPanel from '../../components/TabPanel';
import { ReactComponent as CaretDownIcon } from '../../assets/icons/caretDown.svg';
import useStyles from './style';

const TabsContainer = () => {
  const { t } = useTranslation('dish');
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('md'));
  const { container, panelContainer, accordion } = useStyles();
  const [tab, setTab] = useState(0);
  const {
    data: {
      ingredientTab,
      preparationTab,
      nutritionalTab,
      allergensTab,
    },
  } = useDishContext();

  const tabs = [{
    slug: 'ingredientTab',
    label: t('tab-ingredient'),
    content: ingredientTab,
  }, {
    slug: 'preparationTab',
    label: t('tab-preparation'),
    content: preparationTab,
  }, {
    slug: 'nutritionalTab',
    label: t('tab-nutritional'),
    content: nutritionalTab,
  }, {
    slug: 'allergensTab',
    label: t('tab-allergens'),
    content: allergensTab,
  }];

  const handleTabChange = (index) => () => { setTab(index); };

  // eslint-disable-next-line react/prop-types
  const renderAccordion = ({ slug, label, content }) => (
    content ? (
      <ThemeProvider theme={librawayV2Theme}>
        <Accordion key={slug} className={accordion}>
          <AccordionSummary expandIcon={<CaretDownIcon />}>
            {label}
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ width: '100%', maxWidth: 500 }}>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </ThemeProvider>
    ) : null
  );

  // eslint-disable-next-line react/prop-types
  const renderTab = ({ slug, label, content }, index) => (
    content ? (
      <Grid key={slug} item>
        <Tab
          index={index}
          label={label}
          currentTab={tab}
          onClick={handleTabChange(index)}
          mainColor="grey"
        />
      </Grid>
    ) : null
  );

  // eslint-disable-next-line react/prop-types
  const renderTabPanel = ({ slug, content }, index) => (
    content ? (
      <TabPanel key={slug} value={tab} index={index}>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </TabPanel>
    ) : null
  );

  if (isMobileView) {
    return (
      <div className={container}>
        {tabs.map(renderAccordion)}
      </div>
    );
  }

  return (
    <ThemeProvider theme={librawayV2Theme}>
      <Grid container direction="column" className={container}>
        <Grid item container>
          {tabs.map(renderTab)}
        </Grid>
        <div className={panelContainer}>
          {tabs.map(renderTabPanel)}
        </div>
      </Grid>
    </ThemeProvider>
  );
};

export default TabsContainer;
