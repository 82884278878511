import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  gridLayout: {
    '& .react-grid-placeholder': {
      backgroundColor: primary.dark,
      borderRadius: 20,
    },
  },
}));

export default useStyles;
