import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { uselessFunction } from '../../libraries/helpers';
import dropdownMenuPropTypes from '../DropdownMenu/propTypes';
import DropdownMenu from '../DropdownMenu';
import useInputStyles from '../Input/style';

const voidItem = { label: '--', value: '' };

const InputDropdown = forwardRef(({
  label,
  dense,
  disabled,
  fat,
  name,
  nullable,
  onChange,
  readOnly,
  required,
  square,
  value,
  dropdownProps,
}, ref) => {
  const { root, labelStyle } = useInputStyles({
    dense,
    fat,
    readOnly,
    square,
  });
  const [dropdownLabel, setDropdownLabel] = useState(value);

  const getDropdownLabel = () => {
    const foundDropdownLabel = dropdownProps.items.find(({ value: itemValue }) => (
      itemValue === value
    ));

    return foundDropdownLabel && foundDropdownLabel.value !== '' && foundDropdownLabel.label;
  };

  const handleDropDownChange = (newValue) => {
    setDropdownLabel(newValue);
    onChange(newValue);
  };

  const renderLabel = () => (
    <span className={labelStyle}>
      {label}
      {required && <b>&nbsp;*</b>}
    </span>
  );

  return (
    <Grid container direction="column">
      {label && renderLabel()}
      <div className={root}>
        <DropdownMenu
          label={getDropdownLabel()}
          items={nullable ? [voidItem, ...dropdownProps.items] : dropdownProps.items}
          disabled={disabled}
          readOnly={readOnly}
          onChange={handleDropDownChange}
          full
        />
      </div>
      <input
        name={name}
        type="hidden"
        value={dropdownLabel}
        ref={ref}
      />
    </Grid>
  );
});

InputDropdown.propTypes = {
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  fat: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  nullable: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  square: PropTypes.bool,
  value: PropTypes.string,
  dropdownProps: PropTypes.shape(dropdownMenuPropTypes),
};

InputDropdown.defaultProps = {
  dense: false,
  disabled: false,
  fat: false,
  label: null,
  name: null,
  nullable: false,
  onChange: uselessFunction,
  readOnly: false,
  required: false,
  square: false,
  value: '',
  dropdownProps: { items: [voidItem] },
};

export default InputDropdown;
