import { useQuery } from '@apollo/react-hooks';
import { getPreferredRecipesQuery } from '../../queries/recipes';

export default ({ recipesLimit }) => {
  const { loading, data } = useQuery(getPreferredRecipesQuery, {
    variables: {
      first: recipesLimit,
    },
  });

  const { recipes: { edges } } = data || { recipes: {} };

  return { loading, data: edges || data };
};
