import React, { useContext } from 'react';
import xor from 'lodash/xor';
import { useTranslation } from 'react-i18next';
import useFiltersQuery from '../../../graphql/hooks/filters/useGetFiltersQuery';
import useUserFiltersQuery from '../../../graphql/hooks/filters/useGetUserFiltersQuery';
import LocalLoading from '../../../components/LocalLoading';
import Group from './Group';
import { useAppContext } from '../../../contexts/AppContext';
import { DataContext } from '../contexts';
import useStyles from './style';

const Filters = () => {
  const { t } = useTranslation('orderDishes');
  const { container } = useStyles();
  const { dataType } = useContext(DataContext);
  const { orderDishesFilters: [checkedFilters, setCheckedFilters] } = useAppContext();
  const { loading: wholeLoading, data: wholeData } = useFiltersQuery(dataType.toUpperCase());
  const { loading: userLoading, data: userData } = useUserFiltersQuery();

  if (wholeLoading || userLoading) return <LocalLoading />;

  const { wholeFilters } = wholeData;
  const { userFilters } = userData;

  const handleClick = (group) => (id) => () => {
    setCheckedFilters({ ...checkedFilters, [group]: xor(checkedFilters[group], [id]) });
  };

  // eslint-disable-next-line react/prop-types
  const renderFilterGroup = ({ type, label, items }) => (
    <Group
      key={label}
      items={items}
      title={label}
      name={type}
      handleClick={handleClick(type)}
    />
  );

  return (
    <div className={container}>
      {dataType === 'recipes' ? (
        renderFilterGroup({
          label: t('user-filters'),
          type: 'userFilters',
          items: userFilters.map(({ id, label }) => ({ key: id, label })),
        })
      ) : null}
      {wholeFilters.map(renderFilterGroup)}
    </div>
  );
};

export default Filters;
