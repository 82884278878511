import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { librawayStorage } from '../../libraries/helpers';
import useModalOpen from '../../hooks/useModalOpen';
import useResponsive from '../../hooks/useResponsive';
import { usePlanContext } from '../../contexts/PlanContext';
import LocalLoading from '../../components/LocalLoading';
import Dialog from '../../components/Dialog';
import Recap from './Recap';
import TabsContainer from './TabsContainer';
import InfoPlanContent from './InfoPlanContent';
import infoIcon from '../../assets/icons/info.png';
import librawayThemeV2 from '../../theme/v2';

const Content = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const { isSmallView } = useResponsive();
  const { opened, closeModal, openModal } = useModalOpen(!librawayStorage.get('planInfoModal'));
  const [{ plan: { data } = {} }, { getPlanRecap }] = usePlanContext();

  useEffect(getPlanRecap, []);

  if (data === null) {
    history.push('/it/come-funziona');
  }

  if (data) {
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Recap />
          </Grid>

          <Grid item xs={12} style={{ marginTop: isSmallView ? 24 : 0 }}>
            <Grid
              container
              justify={isSmallView ? 'center' : 'flex-end'}
              align="center"
              spacing={2}
            >
              <Grid item>
                <ButtonBase onClick={openModal} disableRipple>
                  <Typography variant="h3">{t('how-it-works')}</Typography>
                </ButtonBase>
              </Grid>
              <Grid item>
                <ButtonBase onClick={openModal} disableRipple>
                  <img src={infoIcon} alt="info icon" />
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TabsContainer />
          </Grid>
        </Grid>

        <Dialog
          open={opened}
          onClose={closeModal}
          noPadding
          minWidth={600}
        >
          <ThemeProvider theme={librawayThemeV2}>
            <InfoPlanContent onClose={closeModal} />
          </ThemeProvider>
        </Dialog>
      </>
    );
  }

  return <LocalLoading />;
};

export default Content;
