import { useLazyQuery } from '@apollo/react-hooks';
import { getPlanMonthQuery } from '../../queries/plans';

export default () => {
  const [getPlanMonth, { loading, data }] = useLazyQuery(getPlanMonthQuery, { fetchPolicy: 'no-cache' });

  return {
    getPlanMonth,
    loading,
    data: data && data.getPlanMonth,
  };
};
