import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AwesomeSlider from 'react-awesome-slider';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '../../../components/Card';
import LocalLoading from '../../../components/LocalLoading';
import { ReactComponent as CaretSlimLeft } from '../../../assets/icons/caretSlimLeft.svg';
import { ReactComponent as CaretSlimRight } from '../../../assets/icons/caretSlimRight.svg';
import useStyles from './style';

const Carousel = ({ loading, data, handleCardClick }) => {
  const cardPerSlide = 4;
  const dataLimit = data.length;
  const lastSlide = Math.ceil(dataLimit / cardPerSlide);
  const [slideIndex, setSlideIndex] = useState(0);
  const noPrevSlide = slideIndex === 0;
  const noNextSlide = (slideIndex + 1) === lastSlide;
  const {
    slider,
    arrowButton,
    arrowContainer,
    left,
    right,
    preferredSlideContainer,
  } = useStyles({ noPrevSlide, noNextSlide });

  const slidePrev = () => {
    if (!noPrevSlide) {
      setSlideIndex(slideIndex - 1);
    }
  };

  const slideNext = () => {
    if (!noNextSlide) {
      setSlideIndex(slideIndex + 1);
    }
  };

  /* eslint-disable react/prop-types */
  const renderCard = ({
    id,
    slug,
    title,
    image,
    foods,
    commentsCount,
    avgVote,
  }) => (
    <Grid
      key={id}
      md
      item
      container
      justify="center"
    >
      <Card
        title={title}
        type="recipes"
        image={image}
        foods={foods}
        commentsCount={commentsCount}
        valuation={avgVote}
        handleClick={handleCardClick(id, slug)}
      />
    </Grid>
  );
  /* eslint-enable react/prop-types */

  const renderPreferredRecipes = (accumulator, { node: recipe }, index) => {
    const actualIndex = Math.floor(index / cardPerSlide);
    const result = accumulator;

    if (Array.isArray(result[actualIndex])) {
      result[actualIndex].push(renderCard(recipe));
    } else {
      result[actualIndex] = [renderCard(recipe)];
    }

    return result;
  };

  const mapComponent = (slide, index) => (
    <Grid key={`${index}-grid`} className={preferredSlideContainer}>{slide}</Grid>
  );

  return (
    <>
      <Grid
        item
        container
        xs
        zeroMinWidth
        justify="center"
        className={arrowContainer}
      >
        <ButtonBase
          className={`${arrowButton} ${left}`}
          onClick={slidePrev}
          disabled={noPrevSlide}
        >
          <CaretSlimLeft />
        </ButtonBase>
      </Grid>
      <Grid item container xs={11} style={{ height: '100%' }}>
        {loading
          ? <LocalLoading />
          : (
            <AwesomeSlider
              selected={slideIndex}
              className={slider}
              bullets={false}
              organicArrows={false}
              infinite={false}
              fillParent={false}
            >
              {
                data
                  .reduce(renderPreferredRecipes, [])
                  .map(mapComponent)
              }
            </AwesomeSlider>
          )}
      </Grid>
      <Grid
        item
        container
        xs
        zeroMinWidth
        justify="center"
        className={arrowContainer}
      >
        <ButtonBase
          className={`${arrowButton} ${right}`}
          onClick={slideNext}
          disabled={noNextSlide}
        >
          <CaretSlimRight />
        </ButtonBase>
      </Grid>
    </>
  );
};

Carousel.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  handleCardClick: PropTypes.func.isRequired,
};

Carousel.defaultProps = {
  loading: false,
  data: {},
};

export default Carousel;
