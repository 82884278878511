import React from 'react';
import PropTypes from 'prop-types';
import ReactMetaTags from 'react-meta-tags';
import { capitalize } from '../libraries/helpers';

const MetaTags = ({
  title,
  ogTitle,
  description,
  image,
}) => (
  <ReactMetaTags>
    <title>{capitalize(title)}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={ogTitle ? capitalize(ogTitle) : capitalize(title)} />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content={image} />
    <meta property="og:type" content="website" />
  </ReactMetaTags>
);

MetaTags.propTypes = {
  title: PropTypes.string,
  ogTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

MetaTags.defaultProps = {
  title: null,
  ogTitle: null,
  description: null,
  image: null,
};

export default MetaTags;
