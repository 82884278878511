import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useRequestPremiumPlan from '../../../graphql/hooks/generic/useRequestPremiumPlan';
import useReactHookForm from '../../../hooks/useReactHookForm';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import LocalLoading from '../../../components/LocalLoading';
import PrivacyCheckbox from '../../../components/PrivacyCheckbox';
import CollapseError from '../../../components/CollapseError';
import nutritionistIcon from '../../../assets/icona_nutrizionista.svg';
import useStyles from './style';

const PremiumPlanForm = () => {
  const { t } = useTranslation('howItWorks');
  const { modalContainer } = useStyles();
  const [requestPremiumPlan, { loading, data, errors: apiErrors }] = useRequestPremiumPlan();
  const { useForm, registers: { getRequiredEmail } } = useReactHookForm();
  const {
    register,
    getValues,
    errors,
    triggerValidation,
  } = useForm();

  const getErrorField = (field) => {
    const foundFieldError = Object.keys(errors).find((fieldName) => fieldName === field);

    if (foundFieldError) {
      return errors[foundFieldError].message || true;
    }

    return false;
  };

  const handleConfirm = async () => {
    const validation = await triggerValidation();

    if (validation) {
      const {
        name: firstName,
        surname: lastName,
        email,
        phone,
        privacy: privacyCheck,
        marketing: marketingCheck,
      } = getValues();

      requestPremiumPlan({
        variables: {
          firstName,
          lastName,
          email,
          phone,
          privacyCheck,
          marketingCheck,
        },
      });
    }
  };

  if (loading) return <LocalLoading />;

  if (data && data.requestPremiumPlan) {
    return (
      <div className={modalContainer}>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <img src={nutritionistIcon} alt="Nutritionist" />
          </Grid>

          <Grid item>
            <Typography variant="h2">
              {t('request-send')}
            </Typography>
          </Grid>

          <Grid item>
            <Typography>
              {t('request-send-text')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={modalContainer}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            {t('premium-plan')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            {t('premium-plan-text')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            name="name"
            label={t('name')}
            ref={register()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="surname"
            label={t('surname')}
            ref={register()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="email"
            label={t('email')}
            type="email"
            ref={register(getRequiredEmail('email'))}
            hasError={getErrorField('email')}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="phone"
            label={t('phone')}
            type="tel"
            ref={register()}
          />
        </Grid>

        <PrivacyCheckbox register={register} />

        <Grid item xs={12}>
          <CollapseError errors={apiErrors} />
        </Grid>

        <Grid item>
          <Button
            filled
            onClick={handleConfirm}
          >
            {t('request-submit')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PremiumPlanForm;
