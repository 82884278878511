import React from 'react';
import { styled } from '@material-ui/core/styles';
import Loading from './Loading';


const Container = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 999,
  backgroundColor: '#FFFFFF99',
});

const GlobalLoading = () => <Container><Loading /></Container>;

export default GlobalLoading;
