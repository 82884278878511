import React from 'react';
import { dispatch } from 'use-bus';
import ButtonBase from '@material-ui/core/ButtonBase';
import { mealActionsData } from './propTypes';
import { ReactComponent as TextCircleIcon } from '../../assets/icons/textCircle.svg';

const Notes = ({ week, day, meal }) => {
  const handleClick = () => {
    dispatch({
      type: '@@rasa/addNote',
      payload: {
        selected_week: week,
        selected_day: day,
        meal_id: meal,
      },
    });
  };

  return (
    <ButtonBase onClick={handleClick}>
      <TextCircleIcon />
    </ButtonBase>
  );
};

Notes.propTypes = mealActionsData;

export default Notes;
