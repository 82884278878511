import gql from 'graphql-tag';

const mealFragment = gql`
  fragment MainInfoMeal on PlanWeekMeal {
    meal { id, name },
    selected { selectedGroup: selected_group { id } },
    combinations {
      id,
      name,
      type,
      choices {
        ingredients {
          quantity,
          ingredient: item {
            name,
            icon { url }
          }
        }
      }
    }
  }
`;

const dayFragment = gql`
  fragment Day on PlanWeekDay {
    day,
    date,
    lunch { ...MainInfoMeal },
    dinner {  ...MainInfoMeal },
    switchable,
    trackable,
    alternatives { day }
  }
  ${mealFragment}
`;

const todayFragment = gql`
  fragment Today on PlanToday {
    day { ...Day },
    alternatives { ...Day }
  }
  ${dayFragment}
`;

const weekFragment = gql`
  fragment Week on PlanWeek {
    days { ...Day },
    num,
    planWeek: plan_week
  }
  ${dayFragment}
`;

export {
  mealFragment,
  dayFragment,
  todayFragment,
  weekFragment,
};
