import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import useDateFns from '../../../hooks/useDateFns';
import { usePlanContext } from '../../../contexts/PlanContext';
import DailyPlan from '../DailyPlan';
import GlobalLoading from '../../../components/GlobalLoading';
import LocalLoading from '../../../components/LocalLoading';
import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import HeaderPlan from './HeaderPlan';
import useStyles from './style';

const Today = () => {
  const { t } = useTranslation('dashboard');
  const { dailyPlanContainer } = useStyles();
  const todayDailyTop = useRef(null);
  const { format } = useDateFns();
  const [
    { today, loading, plan },
    { getPlanToday, switchToday },
  ] = usePlanContext();
  useEffect(getPlanToday, []);

  const [showRestOfTheWeek, setShowRestOfTheWeek] = useState(false);

  if (isEmpty(today.data)) return <LocalLoading />;

  const { day, alternatives } = today.data || {};

  const handleShowRestClick = () => {
    setShowRestOfTheWeek(!showRestOfTheWeek);
    window.scrollTo({
      top: todayDailyTop.current.offsetTop,
      left: 0,
    });
  };

  const showRestButton = alternatives && alternatives.length;

  const renderDay = (dayCombinations) => (
    <DailyPlan
      key={dayCombinations.day}
      dayCombinations={dayCombinations}
      disableActions
      switchWithToday={() => { switchToday(dayCombinations.day); }}
      mealSwitchable={dayCombinations.day.switchable}
      trackable={dayCombinations.day.trackable}
      isCurrentWeek
    />
  );

  return (
    <Grid container direction="column" spacing={4} style={{ position: 'relative' }}>
      {(today.loading || loading) && <GlobalLoading />}
      <Grid item xs>
        <HeaderPlan weeksDisabled />
      </Grid>
      <Grid item ref={todayDailyTop}>
        <div className={dailyPlanContainer}>
          <DailyPlan
            dayCombinations={day}
            mealSwitchable={day && day.switchable}
            trackable={day && day.trackable}
            week={plan.data.currentWeek}
            day={+format(new Date(today.data.day.date), 'd')}
            isCurrentWeek
          />
          {showRestOfTheWeek && <Divider margin={8} />}
          <Collapse in={showRestOfTheWeek} classes={{ wrapperInner: dailyPlanContainer }}>
            {alternatives && alternatives.map(renderDay)}
          </Collapse>
          {showRestButton ? (
            <Button fat filled onClick={handleShowRestClick}>
              {t(showRestOfTheWeek ? 'hide-rest-of-the-week' : 'show-rest-of-the-week')}
            </Button>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};

export default Today;
