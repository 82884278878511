import React from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { defaultLayouts } from './constants';
import DraggableLayout from '../../../../components/DraggableLayout';
import LastActivity from './LastActivity';
import Points from './Points';
import Weight from './Weight';
import Achievement from './Achievement';
import 'react-grid-layout/css/styles.css';

const Tracking = ({ width }) => {
  const handleDrag = (newLayout) => {
    // Needs to call server and pass these info
    console.log('width:', width);
    console.log('handleDrag', newLayout);
  };

  return (
    <DraggableLayout
      layouts={defaultLayouts}
      onDragStop={handleDrag}
      rowHeight={220}
    >
      <div key="lastActivity"><LastActivity /></div>
      <div key="weight"><Weight /></div>
      <div key="points"><Points /></div>
      <div key="achievement"><Achievement /></div>
      <div key="achievement-carrot"><Achievement variant="carrot" /></div>
    </DraggableLayout>
  );
};

Tracking.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(Tracking);
