import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import placeholderImageDish from '../../assets/placeholderImageDish.jpg';
import placeholderImageRecipe from '../../assets/placeholderImageRecipe.jpg';
import { ReactComponent as HearthIcon } from '../../assets/icons/hearth.svg';
import { useAuthContext } from '../../contexts/AuthContext';
import useStyles from './style';

const Image = ({ type, image, badge }) => {
  const { isLoggedIn } = useAuthContext();
  const placeholderImage = useMemo(() => (type === 'recipes' ? placeholderImageRecipe : placeholderImageDish), [type]);
  const { imageStyle, favouriteIcon, badgeBox } = useStyles({
    backgroundImage: image.length ? image[0].thumbnail : placeholderImage,
    badgeColor: badge.color,
  });

  return (
    <Box className={imageStyle}>
      {badge ? (
        <Box className={badgeBox}>
          <Typography>
            <b>
              {badge.label}
            </b>
          </Typography>
        </Box>
      ) : null}
      {isLoggedIn && type === 'recipes' && (
        <Box className={favouriteIcon}>
          <HearthIcon />
        </Box>
      )}
    </Box>
  );
};

Image.propTypes = {
  type: PropTypes.oneOf(['dishes', 'recipes']).isRequired,
  image: PropTypes.arrayOf(PropTypes.shape()),
  badge: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
  }),
};

Image.defaultProps = {
  image: [],
  badge: {},
};

export default Image;
