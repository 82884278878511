import { common } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { grey, primary }, breakpoints }) => ({
  mainContainer: {
    padding: spacing(6, 0),
  },

  summaryContainer: {
    padding: spacing(0, 8),
    [breakpoints.down('sm')]: {
      padding: spacing(0, 2),
    },
  },

  quantityButtonGroup: {
    height: 26,
    backgroundColor: grey.main,
    boxShadow: 'none',
    '& button': {
      padding: spacing(1),
      lineHeight: 1,
    },
    '& button:first-child, button:last-child': {
      minWidth: 30,
    },
  },

  small: {
    minWidth: 0,
  },

  editButton: {
    fontSize: '0.9rem',
    borderRadius: 10,
    fontWeight: 700,
    padding: spacing(1, 2),
    [breakpoints.down('sm')]: {
      padding: 0,
      textDecoration: 'underline',
    },
  },

  boxContainer: {
    borderRadius: 20,
    boxShadow: `0px 0px 10px 10px ${grey.main}`,
    [breakpoints.down('sm')]: {
      borderRadius: '20px 20px 0 0',
      boxShadow: `0px -10px 10px 0 ${grey.main}`,
    },
  },

  innerContainer: {
    padding: spacing(4, 8),
    textAlign: ({ textAlign = 'center' }) => textAlign,
    borderBottom: ({ border }) => (border ? `1px solid ${grey.main}` : 0),
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },

  stepperContainer: {
    padding: spacing(3, 20),
    textAlign: ({ textAlign = 'center' }) => textAlign,
    borderBottom: ({ border }) => (border ? `1px solid ${grey.main}` : 0),
  },

  titleIconStyle: {
    color: primary.dark,
  },

  titleItem: {
    color: common.black,
    fontWeight: 700,
  },

  cartTotalContainer: {
    backgroundColor: grey.light,
    width: '100%',
    padding: spacing(2),
  },

  totalTypography: {
    textTransform: 'uppercase',
  },

  summaryInnerContainer: {
    backgroundColor: grey.light,
    padding: spacing(2, 4),
    borderRadius: 10,
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },
}));

export default useStyles;
