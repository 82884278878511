import { useLazyQuery } from '@apollo/react-hooks';
import { getPlanWeeksNumberQuery } from '../../queries/plans';

export default () => {
  const [getPlanWeeksNumber, { loading, data }] = useLazyQuery(getPlanWeeksNumberQuery);

  return {
    getPlanWeeksNumber,
    loading,
    data: data && data.me.plan.weeks,
  };
};
