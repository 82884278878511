import { makeStyles } from '@material-ui/styles';
import howItWorksBackground from '../../assets/howItWorksBackground.jpg';
import dnaMap from '../../assets/mappa_dna_2.png';
import dnaMapMobile from '../../assets/mappa_dna_2_lunga.png';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { common, grey } }) => ({
  background: {
    backgroundImage: `url(${howItWorksBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    [breakpoints.down('sm')]: {
      backgroundAttachment: 'local',
    },
  },

  presentationContainer: {
    position: 'relative',
    paddingTop: spacing(16),
    paddingBottom: spacing(16),
    color: common.white,

    [breakpoints.down('sm')]: {
      padding: spacing(4, 0),
    },
  },

  presentationNigefLogoStyle: {
    width: 120,
    marginBottom: spacing(4),
  },

  presentationTitleContainer: {
    width: '100%',
    padding: spacing(8, 0),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: common.black,
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.9) 40%, rgba(255, 255, 255, 0.9) 60%, rgba(255, 255, 255, 0) 90%)',
    [breakpoints.down('sm')]: {
      paddingRight: spacing(4),
      paddingLeft: spacing(4),
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0.4) 100%)',
    },
  },

  titleClaim: {
    width: '100%',
    maxWidth: 600,
  },

  presentationText: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  greyInner: {
    backgroundColor: grey.light,
  },

  innerContainer: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
  },

  nigefLogoStyle: {
    width: '100%',
    maxWidth: 200,
  },

  howItWorksPlansTitle: {
    marginTop: spacing(6),
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },

  dnaBackground: {
    position: 'absolute',
    right: '50%',
    width: '50%',
    height: 450,
    backgroundPositionX: '100%',
    backgroundPositionY: '30px',
    backgroundSize: 'auto 400px',
    backgroundImage: `url(${dnaMap})`,
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('sm')]: {
      right: 0,
      width: '100%',
      marginTop: spacing(4),
      marginBottom: spacing(4),
      backgroundImage: `url(${dnaMapMobile})`,
      backgroundSize: 'auto 190px',
      backgroundPositionX: 'center',
      backgroundPositionY: 0,
    },
  },

  combinationsDesktopStyle: {
    marginTop: spacing(4),
    height: 'intrinsic',
  },

  mobileFakeCarousel: {
    minWidth: '100%',
    display: 'flex',
    overflowX: 'scroll',
    justifyContent: 'space-between',
    marginTop: spacing(6),
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
    paddingLeft: spacing(2),
  },
}));

export default useStyles;
