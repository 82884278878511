import { useLazyQuery } from '@apollo/react-hooks';
import { getPlanRecapQuery } from '../../queries/plans';

export default () => {
  const [getPlanRecap, { loading, data }] = useLazyQuery(getPlanRecapQuery);

  return {
    getPlanRecap,
    loading,
    data: data && data.me.plan,
  };
};
