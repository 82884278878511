import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { grey, secondary, white } }) => ({
  iconContainer: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    borderRadius: 999,
    backgroundColor: ({ noBorder }) => (noBorder ? 'transparent' : white.main),
    border: ({ noBorder }) => (noBorder ? 0 : `1px solid ${grey.dark}`),
    '& .stroke': {
      stroke: grey.dark,
    },
    '&.active': {
      borderColor: secondary.main,
      backgroundColor: secondary.main,
      '& .stroke': {
        stroke: white.main,
      },
    },
  },
}));

export default useStyles;
