import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import useStyles from './style';

const CounterPillow = ({
  value,
  handleChange,
  min,
  max,
}) => {
  const noMoreDown = value === min;
  const noMoreUp = value === max;

  const {
    container,
    button,
    substractionButton,
    additionButton,
    label,
  } = useStyles({ noMoreDown, noMoreUp });

  const substract = () => {
    if (!noMoreDown) {
      handleChange(value - 1);
    }
  };

  const add = () => {
    if (!max || !noMoreUp) {
      handleChange(value + 1);
    }
  };

  return (
    <div className={container}>
      <ButtonBase
        className={`${button} ${substractionButton}`}
        disabled={noMoreDown}
        onClick={substract}
      >
        -
      </ButtonBase>
      <Box className={label} component="span">{value}</Box>
      <ButtonBase
        className={`${button} ${additionButton}`}
        disabled={noMoreUp}
        onClick={add}
      >
        +
      </ButtonBase>
    </div>
  );
};

CounterPillow.propTypes = {
  value: PropTypes.number,
  handleChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};

CounterPillow.defaultProps = {
  value: 0,
  handleChange: () => {},
  min: 1,
  max: null,
};

export default CounterPillow;
