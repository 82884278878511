import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Paper from '../../../components/Paper';

const Nutritionist = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Paper title={t('nutritionist')} disabled>
      <Grid container>
        <Avatar>C</Avatar>
        Cristina
      </Grid>
    </Paper>
  );
};

export default Nutritionist;
