import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, palette: { grey }, spacing }) => ({
  recapContainer: {
    backgroundColor: grey.light,
    padding: spacing(4),
    borderRadius: 30,
  },

  mask: {
    // width: 220,
    height: 200,
    clipPath: 'url(#profileClip)',
    [breakpoints.down('xs')]: {
      clipPath: 'none',
      width: '100%',
      height: 'auto',
      borderRadius: '50%',
    },
  },

  pagingDotsContainerClassName: {
    top: `${spacing(4)}px !important`,
    '& .paging-dot': {
      width: 12,
      height: 12,
      '& circle': {
        r: 6,
        cx: 6,
        cy: 6,
      },
    },
  },

  slide: {
    width: '100%',
    padding: spacing(),
    fontSize: '0.8em',
  },

  personalInfoPaper: {
    padding: spacing(2),
  },
}));

export default useStyles;
