import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { secondary, white } }) => ({
  header: {
    width: '100%',
    minHeight: spacing(12),
    color: white.main,
    backgroundColor: secondary.main,
    display: 'flex',
    padding: spacing(2),
    fontWeight: 900,
  },

  filterContainer: {
    paddingTop: spacing(4),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    marginBottom: 120,
    width: '100%',
  },

  button: {
    position: 'fixed',
    alignSelf: 'center',
    bottom: spacing(4),
  },
}));

export default useStyles;
