import React, { Suspense } from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { AppProvider } from './contexts/AppContext';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import GlobalLoading from './components/GlobalLoading';
import ScrollToTop from './components/ScrollToTop';
import * as serviceWorker from './serviceWorker';

import librawayTheme from './theme/main';
import './libraries/i18n';

import './styles/index.scss';
const {
  // NODE_ENV,
  // REACT_APP_LIBRAWAY_VERSION,
  REACT_APP_GTM_ID,
} = process.env;

// Sentry.init({
//   dsn: 'https://037c7f830fd94d05aa66d0dce9c2c5f2@log.appfactory.it/4',
//   autoSessionTracking: true,
//   environment: NODE_ENV,
//   debug: NODE_ENV === 'development',
//   release: `libraway@${REACT_APP_LIBRAWAY_VERSION}`,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

TagManager.initialize({ gtmId: REACT_APP_GTM_ID });

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('183152556666333', {}, options);

render(
  <BrowserRouter>
    <ScrollToTop />
    <ThemeProvider theme={librawayTheme}>
      <CssBaseline />
      <Suspense fallback={<GlobalLoading />}>
        <AuthProvider>
          <AppProvider>
            <App />
          </AppProvider>
        </AuthProvider>
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.register();
