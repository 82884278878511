import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Input from '../Input';
import PrivacyCheckbox from '../PrivacyCheckbox';

const useStyles = makeStyles(({ spacing }) => ({
  privacyContainer: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
    lineHeight: '16px',
  },
}));

const Form = ({ fields, register, loading }) => {
  const { t } = useTranslation('newsletter');
  const { privacyContainer } = useStyles();

  return (
    <Grid container justify="center" spacing={1}>
      {fields.map(({
        label,
        type,
        required,
        getRegister = () => {},
        xs,
        sm,
      }) => (
        <Grid item xs={xs} sm={sm}>
          <Input
            name={label}
            label={t(`common:${label}`)}
            type={type}
            disabled={loading}
            required={required}
            ref={register(getRegister())}
          />
        </Grid>
      ))}

      <div className={privacyContainer}>
        <PrivacyCheckbox
          register={register}
          disabled={loading}
          style={{ lineHeight: '16px' }}
        />
      </div>
    </Grid>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Form.defaultProps = {
  loading: false,
};

export default Form;
