import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DraggableItem from '../DraggableItem';
import PointsIcon from '../../../../../assets/icons/trackingPoints.svg';

const Achievement = () => {
  const { t } = useTranslation();

  return (
    <DraggableItem flexCenter>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img src={PointsIcon} alt="achievement icon" />
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant="h4">- punti</Typography>
          <Typography variant="body2">accumulati questa settimana</Typography>
        </Grid>
      </Grid>
    </DraggableItem>
  );
};

export default Achievement;
