import { useMutation } from '@apollo/react-hooks';
import { requestStandardPlanMutation } from '../../mutations/generic';

export default () => {
  const [
    requestStandardPlan,
    { loading, data, error },
  ] = useMutation(requestStandardPlanMutation);

  return [requestStandardPlan, { loading, data, errors: error && error.graphQLErrors }];
};
