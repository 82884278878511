import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  breakpoints,
  spacing,
  palette: {
    primary,
    secondary,
    grey,
  },
}) => ({

  container: {
    marginTop: spacing(4),
  },

  imageContainer: {
    height: 450,
    borderRadius: 30,
    overflow: 'hidden',
    backgroundColor: grey.dark,
    position: 'relative',
    '& > img': {
      position: 'absolute',
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    [breakpoints.down('md')]: {
      height: 230,
      borderRadius: 0,
      marginRight: 0,
    },
  },

  detailContainer: {
    height: 450,
    position: 'relative',
    [breakpoints.down('sm')]: {
      height: 'auto',
    },
    '& > div': {
      height: `calc(100% + ${spacing(6)}px)`,
      [breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
  },

  ingredientIcon: {
    height: 35,
  },

  ingredientLabel: {
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    textAlign: 'center',
    display: 'inline-block',
  },

  deliveryContainer: {
    color: secondary.main,
    fontWeight: 900,
    fontSize: '1.2rem',
  },

  modalContainer: {
    minWidth: 500,
    [breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },

  modalSubtitle: {
    borderBottom: `1px solid ${primary.dark}`,
  },

  panelContainer: {
    maxWidth: '100%',
    backgroundColor: grey.light,
    padding: spacing(4),
    '& table td + td': {
      paddingLeft: spacing(2),
      textAlign: 'right',
    },
  },

  accordion: {
    backgroundColor: grey.light,
    '&:first-child': {
      borderRadius: '20px 20px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 20px 20px',
    },
    '& figure': {
      width: '100%',
      margin: 0,
      '& table': {
        width: '100%',
        '& td + td': {
          textAlign: 'right',
        },
      },
    },
  },

  oldPrice: {
    marginBottom: -spacing(),
    color: grey.dark,
  },
}));

export default useStyles;
