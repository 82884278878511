import itRoutes from '../locales/it/routes.json';

const nullType = '-';
const escapeCode = 27;
const enterCode = 13;

/* Intended as Mui spacing multiplier */
const headerHeight = 16;
const headerMobileHeight = 10;

const orderDishesQueryAvoidKey = ['dataType'];

const ingredientsUnits = [
  'GRAM',
  'MILLILITER',
  'SPOON',
  'TEASPOON',
  'CLOVE',
  'QB',
  'INGREDIENT',
  'SLICE',
];

const uselessFunction = () => {};

const languages = [
  { slug: 'it', code: 'it-IT', name: 'Italiano' },
  { slug: 'en', code: 'en-GB', name: 'English' },
];

/**
 * GraphQL helper to loadMore items
 *
 * @param {String} itemName Name of updating item
 * @param {Function} setLoadingFetchMore Function to set loading state
 */
const defaultUpdateQuery = (itemName, setLoadingFetchMore) => (
  previousItems,
  { fetchMoreResult: { [itemName]: { edges, pageInfo } } },
) => {
  if (setLoadingFetchMore) {
    setLoadingFetchMore(false);
  }

  return (edges.length
    ? {
      [itemName]: {
        // eslint-disable-next-line no-underscore-dangle
        __typename: previousItems[itemName].__typename,
        edges: [...previousItems[itemName].edges, ...edges],
        pageInfo,
      },
    }
    : previousItems
  );
};

/**
 * Filters handler to send whitin query
 *
 * @param {Object} filters Object of array containing all the filters to apply in query
 * @return {Object}
 */
const getOrderDishesFilters = (filters) => (
  filters && Object.keys(filters).reduce((accumulator, filterKey) => {
    if (!filters[filterKey].length) {
      accumulator[filterKey] = null;
    } else if (!orderDishesQueryAvoidKey.includes(filterKey)) {
      accumulator[filterKey] = filters[filterKey];
    }

    return accumulator;
  }, {})
);

/**
 * Create html srcSet image by a key
 *
 * @param {Object} images Images set
 * @param {String} key Key to reduce
 * @return {String}
 */
const getImagesSet = (images, key) => (
  Object.keys(images)
    .filter((item) => item.includes(key))
    .map((image) => `${images[image]} ${image.split(key)[1]}`)
    .join(',')
);

/**
 * Return currency format number
 *
 * @param {Number} amount
 * @param {String} decimal
 * @param {String} thousands
 * @return {String}
 */
const formatMoney = (amount = 0, decimal = ',', thousands = '.') => {
  try {
    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(Math.abs(Number(amount) || 0).toFixed(2), 10).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return `${negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimal + Math.abs(amount - i).toFixed(2).slice(2))} €`;
  } catch (e) {
    return 'error';
  }
};

const getLocaleDateFnsFormat = (code) => code.split('-').reduce((language, country) => {
  if (language.toLowerCase() === country.toLowerCase()) {
    return language.toLowerCase();
  }

  return `${language.toLowerCase()}${country.toUpperCase()}`;
});

const librawayStorage = {
  get: (key) => localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)),
  set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  clearItem: (key) => localStorage.setItem(key, null),
  removeItem: (key) => localStorage.removeItem(key),
  clearStorage: () => localStorage.clear(),
};

const librawaySessionStorage = {
  get: (key) => (
    window.sessionStorage.getItem(key) && JSON.parse(window.sessionStorage.getItem(key))
  ),
  set: (key, value) => window.sessionStorage.setItem(key, JSON.stringify(value)),
  clearItem: (key) => window.sessionStorage.setItem(key, null),
  removeItem: (key) => window.sessionStorage.removeItem(key),
  clearStorage: () => window.sessionStorage.clear(),
};

/**
 * Set page title
 *
 * @param {String} title Page title to set in browser
 */
const setDocumentTitle = (title) => {
  window.document.title = `${title} - Libraway`;
};

/**
 * Capitalize a string
 *
 * @param {String} string String to capitalize
 * @return {String}
 */
const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

/**
 * Get quantity and unit render due doses and people
 *
 * @param {Integer} quantity
 * @param {Integer} doses
 * @param {Integer} people
 * @param {Enumerator} unit
 * @param {Function} t
 */
const renderIngredientQuantity = (quantity, doses, people, unit, t) => {
  const fractions = {
    1.33: '¾',
    2.00: '½',
    4.00: '¼',
  };
  const dosedQuantity = quantity * (doses / people);

  if (
    unit === 'INGREDIENT'
    || unit === 'SPOON'
    || unit === 'TEASPOON'
    || unit === 'CLOVE'
  ) {
    const decimal = dosedQuantity % 1;
    const integer = parseInt(dosedQuantity, 10);
    const fraction = parseFloat((10 / ((decimal) * 10)).toFixed(2));
    const fractionedTotal = `${integer > 0 ? `${integer} ` : ''}${fractions[fraction] ? fractions[fraction] : ''}`;

    if (
      unit === 'SPOON'
      || unit === 'TEASPOON'
      || unit === 'CLOVE'
    ) {
      if (decimal !== 0) return `${fractionedTotal} ${t(unit.toLowerCase(), { count: integer || Math.ceil(dosedQuantity) })}`;
      return `${dosedQuantity} ${t(`ingredients:${unit.toLowerCase()}`, { count: Math.ceil(dosedQuantity) })}`;
    }

    if (decimal !== 0) {
      return fractionedTotal;
    }

    return dosedQuantity;
  }

  if (unit === 'MILLILITER') {
    if (dosedQuantity >= 1000) return `${dosedQuantity / 1000} ${t('ingredients:liter')}`;
    if (dosedQuantity >= 10) return `${dosedQuantity / 10} ${t('ingredients:centiliter')}`;
    return `${dosedQuantity} ${t(`ingredients:${unit.toLowerCase()}`)}`;
  }

  if (unit === 'QB') return `${t(`ingredients:${unit.toLowerCase()}`, { count: dosedQuantity })}`;

  return `${dosedQuantity} ${t(`ingredients:${unit.toLowerCase()}`, { count: dosedQuantity })}`;
};

/**
 * @param {String} slug
 * @param {String} params
 */
const getPathAliases = (slug, ...params) => {
  const enhancedParams = (param) => (param ? `/${param}` : '');

  return ([
    `/${slug}${params.map(enhancedParams).join('')}`,
    `/it/${itRoutes[slug]}${params.map(enhancedParams).join('')}`,
  ]);
};

/**
 * Used to filter navigation items (app bar, drawer)
 *
 * @param {*} externalValue Value to compare
 * @param {String} itemProp Object params to filter
 * @returns {Boolean}
 */
const itemsFilter = (externalValue, itemProp) => ({ [itemProp]: itemPropFound }) => (
  itemPropFound === undefined
  || (externalValue && itemPropFound)
  || (!externalValue && !itemPropFound)
);

export {
  capitalize,
  defaultUpdateQuery,
  enterCode,
  escapeCode,
  formatMoney,
  getImagesSet,
  getLocaleDateFnsFormat,
  getOrderDishesFilters,
  getPathAliases,
  headerHeight,
  headerMobileHeight,
  ingredientsUnits,
  itemsFilter,
  languages,
  librawayStorage,
  librawaySessionStorage,
  nullType,
  renderIngredientQuantity,
  setDocumentTitle,
  uselessFunction,
};
