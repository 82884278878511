import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DatePicker as DatePickerMui } from '@material-ui/pickers';
import InputBase from '@material-ui/core/InputBase';
import EventIcon from '@material-ui/icons/Event';
import { uselessFunction } from '../../libraries/helpers';
import useInputStyles from '../Input/style';
import useStyles from './style';

const DatePicker = ({
  disabled,
  icon,
  inputRef,
  format,
  label,
  name,
  onChange,
  readOnly,
  required,
  value,
}) => {
  const { root, labelStyle } = useInputStyles();
  const { datePickerStyle } = useStyles({ readOnly });

  const renderLabel = () => (
    <span className={labelStyle}>
      {label}
      {required && <b>&nbsp;*</b>}
    </span>
  );

  return (
    <Grid container direction="column">
      {label && renderLabel()}
      <DatePickerMui
        disabled={disabled}
        classes={{ root }}
        className={datePickerStyle}
        value={value}
        format={format}
        name={name}
        inputRef={inputRef}
        onChange={onChange}
        TextFieldComponent={({ ...props }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <InputBase {...props} endAdornment={icon && <EventIcon />} />
        )}
      />
    </Grid>
  );
};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  inputRef: PropTypes.func,
  format: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
};

DatePicker.defaultProps = {
  disabled: false,
  icon: true,
  inputRef: undefined,
  format: 'dd MMMM yyyy',
  label: undefined,
  name: undefined,
  onChange: uselessFunction,
  readOnly: false,
  required: false,
  value: undefined,
};

export default DatePicker;
