import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { librawayStorage, uselessFunction } from '../../libraries/helpers';
import wipBadge from '../../assets/icons/nastro_wip.svg';
import useStyles from './style';
import Button from '../../components/Button';

const InfoPlanContent = ({ onClose }) => {
  const { t } = useTranslation('dashboard');
  const { modalContainer, wipBannerStyle } = useStyles();

  useEffect(() => {
    librawayStorage.set('planInfoModal', true);
  }, []);

  return (
    <>
      <img src={wipBadge} alt="WIP" className={wipBannerStyle} />
      <Grid
        container
        justify="center"
        className={modalContainer}
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography>
            <span
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('plan-info') }}
            />
          </Typography>
        </Grid>

        <Grid item>
          <Button filled onClick={onClose}>{t('go-to-your-plan')}</Button>
        </Grid>
      </Grid>
    </>
  );
};

InfoPlanContent.propTypes = {
  onClose: PropTypes.func,
};

InfoPlanContent.defaultProps = {
  onClose: uselessFunction,
};

export default InfoPlanContent;
