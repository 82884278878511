import React from 'react';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import useGetNotifications from '../../graphql/hooks/notifications/useGetNotifications';
import BodyContainer from '../../components/BodyContainer';
import LocalLoading from '../../components/LocalLoading';
import NotifyItem from '../../components/NotificationIcon/NotifyItem';

const Notifications = () => {
  const { loading, data } = useGetNotifications();

  if (loading) return <LocalLoading />;

  return (
    <BodyContainer>
      <Container>
        <List>
          {data && data.length && data.map(
            ({
              node: {
                id,
                title,
                message,
                readAt,
                createdAt,
              },
            }) => (
              <NotifyItem
                key={id}
                id={id}
                title={title}
                message={message}
                readAt={readAt}
                createdAt={createdAt}
              />
            ),
          )}
        </List>
      </Container>
    </BodyContainer>
  );
};

export default Notifications;
