import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useResponsive from '../../hooks/useResponsive';
import mapExample from '../../assets/mappa_esempio.jpg';
import mapExampleMobile from '../../assets/mappa_esempio_mobile.jpg';
import useStyles from './style';

const FoodMap = () => {
  const { t } = useTranslation('howItWorks');
  const { isSmallView } = useResponsive();
  const { innerContainer, greyInner, dnaBackground } = useStyles();

  return (
    <div className={greyInner}>
      <div className={dnaBackground} />
      <Container
        className={innerContainer}
        style={{ textAlign: isSmallView ? 'center' : 'left' }}
      >
        <Grid container alignItems="center" spacing={6}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ height: isSmallView ? 200 : 400 }}
          />

          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              style={{ lineHeight: isSmallView ? 1.5 : 'inherit' }}
            >
              {t('food-map')}
            </Typography>
            <Typography gutterBottom variant="h6">
              {t('food-map-sub')}
            </Typography>
            <Typography gutterBottom>
              {t('food-map-text')}
            </Typography>
            <img
              src={isSmallView ? mapExampleMobile : mapExample}
              alt="Dna map example"
              width="100%"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FoodMap;
