import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { formatMoney } from '../../libraries/helpers';
import { useCartContext } from '../../contexts/CartContext';
import Button from '../../components/Button';
import BoxesSection from './BoxesSection';
import CartItems from './CartItems';
import useStyles from './style';

const OrderCart = ({ nextStep }) => {
  const { t } = useTranslation('order');
  const { mainContainer, summaryContainer, cartTotalContainer } = useStyles();
  const [{ boxCompleted, cartPrice }] = useCartContext();

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={mainContainer}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          spacing={3}
          className={summaryContainer}
        >
          <Grid item>
            <BoxesSection />
          </Grid>

          <Grid item>
            <CartItems />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container justify="center" className={cartTotalContainer}>
          <Typography variant="h3">
            {`${t('total')}: ${formatMoney(cartPrice)}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container justify="center">
          <Tooltip
            title={t('need-complete-box-info')}
            placement="top"
            enterDelay={300}
            disableHoverListener={boxCompleted}
            arrow
          >
            <div>
              <Button
                filled
                disabled={!boxCompleted}
                onClick={nextStep}
              >
                {t('proceed-to-purchase')}
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

OrderCart.propTypes = {
  nextStep: PropTypes.func,
};

OrderCart.defaultProps = {
  nextStep: () => {},
};

export default OrderCart;
