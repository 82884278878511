import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import { UserInfoContext } from '../UserInfoContext';
import Input from '../../../components/Input';
import PlacesAutocomplete from '../../../components/PlacesAutocomplete';
import Button from '../../../components/Button';
import SpreadAddress from './SpreadAddress';

const addressKeys = [
  'address',
  'city',
  'number',
  'state',
  'zip',
];

const Address = ({ active, confirm }) => {
  const { t } = useTranslation(['profile', 'order']);
  const { addressState: [formData, setFormData] } = useContext(UserInfoContext);
  const [manualMode, setManualMode] = useState(false);

  useEffect(() => {
    if (!isEmpty(formData)) {
      confirm();
    }
  // eslint-disable-next-line
  }, []);

  const {
    register,
    triggerValidation,
    errors,
    getValues,
    setValue,
  } = useForm();

  const {
    name,
    surname,
    address,
    number,
    city,
    state,
    zip,
  } = formData;

  const hasAddressError = !!intersection(addressKeys, Object.keys(errors)).length;

  useEffect(() => {
    if (hasAddressError) {
      setManualMode(true);
    }
  }, [hasAddressError]);

  const getRequired = (fieldName) => ({
    value: true,
    message: t('field-required', { fieldName: t(fieldName) }),
  });

  const getMinLength = (fieldName, minLength) => ({
    value: minLength,
    message: t('field-min', { fieldName: t(fieldName), minLength }),
  });

  const getDefaultAddressRegister = (fieldName) => ({
    required: getRequired(fieldName),
    minLength: getMinLength(fieldName, 3),
  });

  const getShortRegister = (fieldName) => ({
    required: getRequired(fieldName),
    minLength: getMinLength(fieldName, 2),
  });

  const addressInputs = [
    ['address', 'route', getDefaultAddressRegister],
    ['number', 'street_number', (fieldName) => ({ required: getRequired(fieldName) })],
    ['city', ['locality', 'administrative_area_level_3'], getDefaultAddressRegister],
    ['state', 'administrative_area_level_2', getShortRegister],
    ['zip', 'postal_code', getDefaultAddressRegister],
  ];

  useEffect(() => {
    if (!manualMode) {
      addressInputs.forEach(([inputName, , addressRegister]) => {
        register({ name: inputName }, addressRegister(inputName));
        setValue(inputName, formData[inputName]);
      });
    }
  // eslint-disable-next-line
  }, [active]);

  const labledAddress = address && `${address} ${number}, ${zip} ${city} ${state}`;

  const findAddressComponent = (geocodeData) => ({ types }) => (
    !!intersection(types, Array.isArray(geocodeData) ? geocodeData : [geocodeData]).length
  );

  const reduceAddressComponent = (addressComponents) => (
    (accumulator, [itemFormData, geocodeData]) => (
      geocodeData && {
        ...accumulator,
        [itemFormData]: addressComponents.find(findAddressComponent(geocodeData))
          && addressComponents.find(findAddressComponent(geocodeData)).short_name,
      }
    )
  );

  const handlePlaceSelected = ([{ address_components: addressComponents } = {}]) => {
    if (addressComponents) {
      const reducedAddressInputs = addressInputs.reduce(
        reduceAddressComponent(addressComponents),
        {},
      );
      Object.keys(reducedAddressInputs).forEach((addressInputKey) => {
        setValue(addressInputKey, reducedAddressInputs[addressInputKey]);
      });
    }
  };

  const handleConfirm = async () => {
    const validation = await triggerValidation();

    if (validation) {
      setFormData(getValues());
      confirm();
    }
  };

  const renderErrors = () => Object.keys(errors).map((fieldName) => (
    <Alert key={`${fieldName}-error`} severity="error">
      {errors[fieldName].message}
    </Alert>
  ));

  return (
    <>
      <Collapse in={!active}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ lineHeight: 1.2 }}>
              <p>
                {`${name} ${surname}`}
                <br />
                {`${address} ${number}`}
                <br />
                {`${zip} ${city} (${state})`}
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={active}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              required
              label={t('name')}
              name="name"
              value={name}
              ref={register(getDefaultAddressRegister('name'))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              label={t('surname')}
              name="surname"
              value={surname}
              ref={register(getDefaultAddressRegister('surname'))}
            />
          </Grid>
          <Grid item xs={12}>
            {!manualMode ? (
              <PlacesAutocomplete
                label={t('address')}
                onPlaceSelected={handlePlaceSelected}
                defaultValue={labledAddress}
              />
            ) : (
              <SpreadAddress
                register={register}
                data={getValues()}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {renderErrors()}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('order:required-disclaimer') }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button filled onClick={handleConfirm}>
              {t('common:confirm')}
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

Address.propTypes = {
  active: PropTypes.bool,
  confirm: PropTypes.func,
};

Address.defaultProps = {
  active: false,
  confirm: () => {},
};

export default Address;
