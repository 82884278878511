import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import minBy from 'lodash/minBy';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { useCartContext } from '../../contexts/CartContext';
import { DataContext } from './contexts';

const Head = () => {
  const { t } = useTranslation('orderDishes');
  const { dataType } = useContext(DataContext);
  const [{ boxes }] = useCartContext();
  const { quantity: minQuantity } = minBy(boxes, 'quantity') || {};

  return (
    <Hidden downSm>
      <Typography variant="h2" gutterBottom>{t(`title-${dataType}`)}</Typography>
      <Typography style={{ lineHeight: '28px' }}>{t(`main-text-${dataType}`, { minQuantity })}</Typography>
    </Hidden>
  );
};

export default Head;
