import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { black } }) => ({
  button: {
    fontSize: '1rem',
    padding: spacing(1, 2),
    borderRadius: 10,
  },

  caret: {
    marginRight: spacing(2),
    height: 18,
    '& .main': {
      stroke: black.light,
    },
  },
}));

export default useStyles;
