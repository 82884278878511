import gql from 'graphql-tag';

const recipeFragment = gql`
  fragment SmallInfoRecipe on Recipe {
    id
    slug
    title
    description
    likes
    new
    comments {
      pageInfo { total }
    }
    avgVote: avg_vote
    foods {
      id,
      name,
      image { url },
    },
    image {
      url,
      thumbnail,
      thumbnail2x,
      thumbnail3x
    }
    categories {
      id
      name
    }
  }
`;

const bigRecipeFragment = gql`
  fragment BigInfoRecipe on Recipe {
    id,
    slug,
    title,
    description,
    composition { id, ingredient, quantity, unit },
    categories { id, name },
    foods {
      id,
      name,
      image {
        url
      }
    }
    diets { id, name: label },
    comments {
      pageInfo { total }
      edges {
        node {
          id
          title
          text
        }
      }
    },
    avgVote: avg_vote,
    foods {
      id,
      name,
      image { url },
    },
    image {
      default,
      default2x,
      default3x
    },
    people,
    minPeople: min_people,
    maxPeople: max_people,
    variations {
      foods { name }
      ...SmallInfoRecipe
    }
    cookingTime: cooking_time,
    preparationTime: preparation_time,
    difficulty,
    cost,
    badge {
      thumbnail { original },
      name
    },
    isFavourite: is_favorite,
    hasVoted: has_voted
  }
  ${recipeFragment}
`;

const getPreferredRecipesQuery = gql`
  query PreferredRecipes($first: Int) {
    recipes(
      first: $first,
      filter: {
        random: true,
        orderBy: {
          field: "votes",
          order: DESC
        }
      },
    ) {
      edges {
        node { ...SmallInfoRecipe }
      }
    }
  }
  ${recipeFragment}
`;

const getRelatedRecipesByRecipeQuery = gql`
  query RelatedRecipesByRecipe($first: Int, $recipeId: Int) {
    recipes(
      first: $first,
      filter: {
        recipe: $recipeId,
      },
    ) {
      edges {
        node { ...SmallInfoRecipe }
      }
    }
  }
  ${recipeFragment}
`;

const getRecipesQuery = gql`
  query Recipes($first: Int, $after: String, $filter: RecipeFilterInput) {
    recipes(
      first: $first,
      after: $after,
      filter: $filter,
    ) {
      pageInfo {
        endCursor,
        hasNextPage
      }
      edges {
        node { ...SmallInfoRecipe }
      }
    }
  }
  ${recipeFragment}
`;

const getUserRecipesQuery = gql`
  query Recipes($first: Int, $after: String, $filter: RecipeFilterInput) {
    recipes: userRecipes(
      first: $first,
      after: $after,
      filter: $filter,
    ) {
      pageInfo {
        endCursor,
        hasNextPage
      }
      edges {
        node { ...SmallInfoRecipe }
      }
    }
  }
  ${recipeFragment}
`;

const getRecipeQuery = gql`
  query Recipe($recipeId: String) {
    recipe(id: $recipeId) {
      ...BigInfoRecipe
    }
  }
  ${bigRecipeFragment}
`;

export {
  getPreferredRecipesQuery,
  getRelatedRecipesByRecipeQuery,
  getRecipeQuery,
  getRecipesQuery,
  getUserRecipesQuery,
};
