import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import TimelineDot from '../../../../components/TimelineDot';
import Placeholder from '../../../../components/Placeholder';
import useLastActivityStyles from '../Tracking/LastActivity/style';

const Logs = ({ notes }) => {
  const { t } = useTranslation('dashboard');
  const { timeline, typography } = useLastActivityStyles();

  if (!notes.length) return <Placeholder text={t('no-diary-logs-placeholder')} />;

  return (
    <Timeline className={timeline} align="left">
      {notes.map(({ id, category, text }) => (
        <TimelineItem key={id}>
          <TimelineOppositeContent>
            <Typography color="textSecondary" variant="caption">
              {category}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot action={category} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ flexGrow: 4 }}>
            <ButtonBase>
              <Typography className={typography}>{text}</Typography>
            </ButtonBase>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

Logs.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape()),
};

Logs.defaultProps = {
  notes: [],
};

export default Logs;
