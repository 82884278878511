import { useMutation } from '@apollo/react-hooks';
import { manageFavouriteMutation } from '../../mutations/generic';

export default () => {
  const [
    manageFavourite,
    { loading, data, error },
  ] = useMutation(
    manageFavouriteMutation,
    { refetchQueries: ['Recipe'] },
  );

  return [manageFavourite, { loading, data, errors: error && error.graphQLErrors }];
};
