import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import ReactPixel from 'react-facebook-pixel';
import BottomCart from './BottomCart';
import Footer from '../../layout/Footer';

const Page = ({ component: Component, title, showBottomCart }) => {
  const { t } = useTranslation('titles');

  useEffect(ReactPixel.pageView, [title]);

  return (
    <>
      <MetaTags>
        <title>{t(title)}</title>
      </MetaTags>
      <Component />
      <Footer />
      {showBottomCart ? <BottomCart /> : null}
    </>
  );
};

Page.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string,
  showBottomCart: PropTypes.bool,
};

Page.defaultProps = {
  title: undefined,
  showBottomCart: false,
};

export default Page;
