import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { defaultUpdateQuery } from '../../../libraries/helpers';
import { getActivitiesQuery } from '../../queries/plans';

export default ({ lazy = false } = {}) => {
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const [getActivities, { loading, data, fetchMore }] = useLazyQuery(getActivitiesQuery, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!lazy) {
      getActivities();
    }
  }, [getActivities, lazy]);

  const { me: { activities: { edges, pageInfo } } } = data || { me: { activities: {} } };

  return {
    getActivities,
    loading,
    data: edges || data,
    loadingFetchMore,
    hasNextPage: pageInfo && pageInfo.hasNextPage,
    fetchMore: data
      ? () => {
        setLoadingFetchMore(true);
        fetchMore({
          variables: { after: pageInfo ? pageInfo.endCursor : null },
          updateQuery: defaultUpdateQuery('activities', setLoadingFetchMore),
        });
      }
      : fetchMore,
  };
};
