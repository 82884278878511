import React from 'react';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const OverlayMessage = ({ children }) => {
  const { container, label } = useStyles();

  return (
    <>
      <div className={container} />
      <span className={label}>{children}</span>
    </>
  );
};

OverlayMessage.propTypes = {
  children: childrenPropType,
};

OverlayMessage.defaultProps = {
  children: null,
};

export default OverlayMessage;
