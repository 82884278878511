import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { grey } }) => ({
  summaryContainer: {
    backgroundColor: grey.light,
    padding: spacing(4),
    borderRadius: 30,
  },
}));

export default useStyles;
