import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, palette: { primary, secondary, common } }) => ({
  modalSubtitle: {
    borderBottom: `1px solid ${primary.dark}`,
  },

  activeRow: {
    width: 10,
    height: 44,
    backgroundColor: secondary.main,
    position: 'absolute',
    left: 0,
  },

  cartButtonIcon: {
    '& path': {
      transition: 'fill 200ms ease',
      fill: ({ isEditing }) => (isEditing ? common.white : common.black),
    },
  },

  modalContainer: {
    minWidth: 500,
    [breakpoints.down('sm')]: {
      minWidth: '90%',
    },
  },
}));

export default useStyles;
