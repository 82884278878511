import { makeStyles } from '@material-ui/styles';
import homeBackground from '../../assets/homeBackground.jpg';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { common, grey, secondary } }) => ({
  aboutUsGridContainer: {
    paddingTop: spacing(6),
    paddingBottom: spacing(16),
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  background: {
    backgroundImage: `url(${homeBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    [breakpoints.down('sm')]: {
      backgroundAttachment: 'local',
    },
  },

  bannerBackground: {
    paddingTop: spacing(2),
    paddingBottom: spacing(3),
    backgroundColor: grey.light,
  },

  bannerContainer: {
    margin: 'auto',
    maxWidth: 900,
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  buttonContainer: {
    marginTop: spacing(4),
  },

  bannerText: {
    [breakpoints.down('sm')]: {
      maxWidth: 320,
      margin: 'auto',
    },
  },

  otherServicesContainer: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  presentationContainer: {
    position: 'relative',
    paddingTop: spacing(16),
    paddingBottom: spacing(16),
    color: common.white,

    [breakpoints.down('sm')]: {
      padding: spacing(4, 2),
    },
  },

  presentationText: {
    lineHeight: '24px',
    [breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.01em',
      textAlign: 'center',
    },
  },

  presentationTitle: {
    lineHeight: '24px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: '32px',
    },
  },

  smartphoneContainer: {
    [breakpoints.down('sm')]: {
      position: 'relative',
      height: 160,
    },
  },

  smartphoneStyle: {
    position: 'absolute',
    maxWidth: 600,
    bottom: -70,
    [breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 300,
      top: spacing(4),
    },
  },

  stepsContainer: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
  },

  whatCanWeDoContainer: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    [breakpoints.down('sm')]: {
      paddingTop: spacing(15),
      textAlign: 'center',
    },
  },

  whatCanWeDoIcon: {
    // minHeight: 260,
  },

  svgImage: {
    height: 250,
    maxWidth: '100%',
    [breakpoints.down('sm')]: {
      height: 200,
    },
  },

  gifImage: {
    height: 150,
  },

  stepsNumber: {
    color: secondary.main,
    fontSize: '25px',
  },

  dishesContainer: {
    backgroundColor: grey.light,
    background: `linear-gradient(180deg, ${common.white} 15%, ${grey.light} 15%, ${grey.light} 100%)`,
    paddingBottom: spacing(8),
    '& > * > * + *': {
      marginTop: spacing(8),
    },
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(4),
      '& > * > * + *': {
        marginTop: spacing(2),
      },
    },
  },

  proBadge: {
    position: 'absolute',
    width: 75,
    top: 50,
    right: 70,
  },

  aboutUsContainer: {
    backgroundColor: grey.light,
    background: `linear-gradient(180deg, ${common.white} 40%, ${grey.light} 40%, ${grey.light} 100%)`,
  },

  cardContainer: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  card: {
    borderRadius: 10,
    width: '100%',
    height: 300,
    outline: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(4),
    position: 'relative',
  },

  cardMessage: {
    textAlign: 'center',
    fontSize: 23,
    fontWeight: 900,
    lineHeight: '33px',
  },

  cardSign: {
    position: 'absolute',
    bottom: spacing(3),
    right: spacing(4),
  },

  doubleQuotation: {
    width: 100,
    position: 'absolute',
    top: -20,
    left: -35,
  },

  mobileCarousel: {
    width: `calc(100% + ${spacing(4)}px)`,
    display: 'flex',
    overflowX: 'scroll',
    justifyContent: 'space-between',
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
    marginBottom: spacing(3),
    paddingLeft: spacing(4),
    paddingBottom: spacing(2),
    '& > * + *': {
      marginLeft: spacing(4),
      [breakpoints.down('md')]: {
        marginLeft: spacing(2),
      },
    },
  },
}));

export default useStyles;
