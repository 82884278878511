import { useMutation } from '@apollo/react-hooks';
import { requestPremiumPlanMutation } from '../../mutations/generic';

export default () => {
  const [
    requestPremiumPlan,
    { loading, data, error },
  ] = useMutation(requestPremiumPlanMutation);

  return [requestPremiumPlan, { loading, data, errors: error && error.graphQLErrors }];
};
