import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RecipeContext from '../contexts';
import DosesContext from './contexts';
import CounterPillow from '../../../components/CounterPillow';
import useStyles from '../style';

const Head = () => {
  const { minPeople, maxPeople } = useContext(RecipeContext);
  const [doses, setDoses] = useContext(DosesContext);
  const { uppercase, bold } = useTheme().useClasses();
  const { t } = useTranslation('recipe');
  const {
    headContainer,
    counterContainer,
    ingredientsTitle,
    dosesTitleContainer,
    pillowContainer,
  } = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      className={headContainer}
    >
      <Grid item container xs={12} md={6} alignItems="center">
        <Grid item xs={3}>
          <span className={`${ingredientsTitle} ${uppercase} ${bold}`}>
            {t('ingredient', { count: 0 })}
          </span>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={6}
        className={counterContainer}
      >
        <Grid
          style={{ alignSelf: 'center' }}
          item
          container
          justify="flex-end"
          xs={6}
          md={9}
          className={dosesTitleContainer}
        >
          <span className={`${uppercase} ${bold}`}>{t('doses')}</span>
        </Grid>
        <Grid
          item
          container
          xs={6}
          md={3}
          className={pillowContainer}
        >
          <CounterPillow
            value={doses}
            handleChange={setDoses}
            min={minPeople || undefined}
            max={maxPeople || undefined}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Head;
