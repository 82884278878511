import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useReactHookForm from '../../hooks/useReactHookForm';
import useResponsive from '../../hooks/useResponsive';
import useRequestSubscribe from '../../graphql/hooks/generic/useRequestSubscribe';
import CollapseError from '../CollapseError';
import Button from '../Button';
import Form from './Form';
import { ReactComponent as HeaderImage } from '../../assets/illustrazione_popup_newsletter.svg';
import { ReactComponent as FeedbackImage } from '../../assets/illustrazione_popup_newsletter_feedback.svg';
import useStyles from './style';

const WelcomeModal = () => {
  const { t } = useTranslation('newsletter');
  const {
    greyHeader,
    headerTitle,
    mainContent,
    feedbackContainer,
  } = useStyles();
  const { isSmallView } = useResponsive();
  const [requestSubscribe, { loading, data, errors }] = useRequestSubscribe();
  const { useForm, registers: { getRequiredEmail } } = useReactHookForm();

  const { register, triggerValidation, getValues } = useForm();

  const formFields = [{
    label: 'name',
    type: 'text',
    xs: 12,
    sm: 6,
  }, {
    label: 'surname',
    type: 'text',
    xs: 12,
    sm: 6,
  }, {
    label: 'email',
    type: 'email',
    required: true,
    getRegister: getRequiredEmail,
    xs: 12,
    sm: 12,
  }];

  const handleSubscribe = async (event) => {
    event.preventDefault();
    const validated = await triggerValidation();

    if (validated) {
      const {
        name,
        surname,
        email,
        privacy,
        marketing,
      } = getValues();

      requestSubscribe({
        variables: {
          firstName: name,
          lastName: surname,
          email,
          privacyCheck: privacy,
          marketingCheck: marketing,
        },
      });
    }
  };

  if (data && data.requestSubscribe) {
    return (
      <Container className={feedbackContainer}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <FeedbackImage width="200" height="auto" />
          </Grid>
          <Grid item>
            <Typography variant="h2">
              {t('feedback-title')}
            </Typography>
          </Grid>
          <Grid item style={{ maxWidth: 390 }}>
            <Typography align="center">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('feedback-text') }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <form onSubmit={handleSubscribe}>
      <div className={greyHeader}>
        <Grid
          container
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <HeaderImage width="100%" height="auto" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h2"
              align={isSmallView ? 'center' : 'left'}
              className={headerTitle}
            >
              <span
                  // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t(`title${isSmallView ? '-mobile' : ''}`) }}
              />
            </Typography>
            <Typography align={isSmallView ? 'center' : 'left'}>
              <span
                  // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('main-text') }}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={mainContent}>
        <Form
          fields={formFields}
          register={register}
          loading={loading}
        />

        <CollapseError errors={errors} />

        <Button
          type="submit"
          filled
          disabled={loading}
          style={{ alignSelf: 'center' }}
        >
          {t('subscribe')}
        </Button>
      </div>
    </form>
  );
};

export default WelcomeModal;
