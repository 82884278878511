import React from 'react';
import PropTypes from 'prop-types';
import faker from 'faker';
import Grid from '@material-ui/core/Grid';
import Unit from './Unit';
import useStyles from './style';

const Rating = ({ max, value }) => {
  const { unitContainer } = useStyles();

  return (
    <Grid container className={unitContainer}>
      {[...new Array(max)].map((_, index) => (
        <Unit key={`${faker.random.number()}`} filled={index + 1 <= value} />
      ))}
    </Grid>
  );
};

Rating.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
};

Rating.defaultProps = {
  max: 5,
  value: 0,
};

export default Rating;
