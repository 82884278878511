import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CartIcon } from '../../../assets/icons/cart.svg';
import useStyles from '../style';

const MobileBottomCart = ({ itemsCount, boxCompleted }) => {
  const history = useHistory();
  const { t } = useTranslation('cart');
  const { mobileContainer, cartIcon } = useStyles({ boxCompleted });

  const handleClick = () => { history.push('/checkout'); };

  return (
    <div className={mobileContainer}>
      <Grid
        container
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Grid
            container
            justify="center"
            alignItems="center"
            onClick={handleClick}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h3">
                {t('dishes-in-cart', { count: itemsCount })}
              </Typography>
            </Grid>

            <Grid item>
              <CartIcon className={cartIcon} />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
};

MobileBottomCart.propTypes = {
  itemsCount: PropTypes.number,
  boxCompleted: PropTypes.bool,
};

MobileBottomCart.defaultProps = {
  itemsCount: 0,
  boxCompleted: false,
};

export default MobileBottomCart;
