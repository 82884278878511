import PropTypes from 'prop-types';

const mealActionsData = {
  week: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
  meal: PropTypes.number.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export { mealActionsData };
