import PropTypes from 'prop-types';
import { childrenPropType } from '../propTypes';

const propTypes = {
  label: childrenPropType,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  full: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: childrenPropType,
    value: childrenPropType,
    onClick: PropTypes.func,
  })),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default propTypes;
