import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { childrenPropType } from '../propTypes';
import { ReactComponent as CaretLeft } from '../../assets/icons/caretSlimLeft.svg';
import useStyles from './style';

const BackButton = ({ goTo, children }) => {
  const history = useHistory();
  const { button, caret } = useStyles();

  const handleClick = () => {
    if (goTo) {
      history.push(goTo);
    } else {
      history.goBack();
    }
  };

  return (
    <ButtonBase onClick={handleClick} className={button}>
      <CaretLeft className={caret} />
      {children}
    </ButtonBase>
  );
};

BackButton.propTypes = {
  goTo: PropTypes.string,
  children: childrenPropType,
};

BackButton.defaultProps = {
  goTo: null,
  children: '',
};

export default BackButton;
