import { useMutation } from '@apollo/react-hooks';
import { signupMutation } from '../../mutations/user';

export default () => {
  const [signup, {
    loading,
    data: { register } = {},
    error,
  }] = useMutation(signupMutation);

  const { status } = register || {};

  return [signup, {
    loading,
    data: { status },
    errors: error && error.graphQLErrors,
  }];
};
