import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Squared from './Squared';
import { useStepStyles } from './style';

const Mobile = ({ steps, activeStep, setActiveStep }) => {
  const { mobileContainer } = useStepStyles();

  const handleBackClick = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      className={mobileContainer}
    >
      <Grid item container justify="center" spacing={1}>
        {steps.map((step, index) => (
          <Grid key={step} item>
            <Squared active={index <= activeStep} />
          </Grid>
        ))}
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Zoom in={activeStep > 0}>
            <ArrowBackIosIcon
              onClick={handleBackClick}
              fontSize="small"
            />
          </Zoom>
        </Grid>
        <Grid
          item
          xs={4}
          container
          justify="center"
        >
          <Typography variant="caption">
            <b>{steps[activeStep]}</b>
          </Typography>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </Grid>
  );
};

Mobile.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default Mobile;
