import { useMutation } from '@apollo/react-hooks';
import { forgotPasswordMutation } from '../../mutations/user';

export default () => {
  const [forgotPasswordFunc, {
    loading,
    data: { forgotPassword } = {},
    errors,
  }] = useMutation(forgotPasswordMutation);

  const { status, message } = forgotPassword || {};

  return [forgotPasswordFunc, { loading, data: { status, message }, errors }];
};
