import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';

const Presentation = () => {
  const { t } = useTranslation('howItWorks');
  const {
    presentationContainer,
    presentationTitleContainer,
    titleClaim,
    background,
  } = useStyles();

  return (
    <div className={background} style={{ height: 570 }}>
      <Container className={presentationContainer} style={{ height: '100%' }}>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <Grid item container justify="center">
            <div className={presentationTitleContainer}>
              <Typography gutterBottom variant="h2">
                {t('title')}
              </Typography>
              <Typography className={titleClaim}>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: t('claim') }}
                />
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Presentation;
