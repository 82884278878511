import React, { useState, useEffect } from 'react';
import { format, isPast, isToday } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { usePlanContext } from '../../../contexts/PlanContext';
import GlobalLoading from '../../../components/GlobalLoading';
import LocalLoading from '../../../components/LocalLoading';
import DailyPlan from '../DailyPlan';
import HeaderPlan from './HeaderPlan';
import useStyles from './style';

const Week = () => {
  const { dailyPlanContainer } = useStyles();
  const [switchMode, setSwitchMode] = useState('');
  const [{ weeks, plan = {}, loading }, { getPlanWeeks }] = usePlanContext();
  const { currentWeek, num: masterNum } = plan.data || {};
  useEffect(getPlanWeeks, []);

  if (
    !weeks.data
    || !weeks.data.length
    || !plan.data.currentWeek
  ) return <LocalLoading />;

  const week = weeks.data.find(({ num }) => num === (masterNum || currentWeek));
  const weekCombinations = week.days;
  const isCurrentWeek = week.num === currentWeek;
  const todayWeekDayString = format(new Date(), 'EEEE');

  const todayIndex = weekCombinations.findIndex(
    ({ day }) => todayWeekDayString.toLowerCase() === day.toLowerCase(),
  );

  const sortedWeekCombinations = isCurrentWeek ? [
    ...weekCombinations.slice(todayIndex),
    ...weekCombinations.slice(0, todayIndex),
  ] : weekCombinations;

  const hasAlternatives = (swapableDay) => (
    weekCombinations.find(({ day }) => day === swapableDay).alternatives
    && weekCombinations.find(({ day }) => day === swapableDay).alternatives.length
  );

  const isAnAlternative = (swapableDay) => (
    weekCombinations.find(({ day }) => day === switchMode)
      .alternatives.find(({ day }) => day === swapableDay)
  );

  const isSwapable = (swapableDay) => {
    if (switchMode === '') {
      return hasAlternatives(swapableDay);
    }

    return isAnAlternative(swapableDay) || switchMode === swapableDay;
  };

  const renderDay = (dayCombinations) => {
    const dayDate = new Date(dayCombinations.date);
    const notPrimary = isPast(dayDate) && !isToday(dayDate);

    return (
      <DailyPlan
        key={dayCombinations.day}
        dayCombinations={dayCombinations}
        notPrimary={notPrimary}
        swapable={!!isSwapable(dayCombinations.day)}
        switchMode={switchMode}
        setSwitchMode={setSwitchMode}
        mealSwitchable={dayCombinations.switchable}
        trackable={dayCombinations.trackable}
        isCurrentWeek={isCurrentWeek}
        week={week.num}
        day={+format(dayDate, 'd')}
      />
    );
  };

  return (
    <Grid container direction="column" spacing={4} style={{ position: 'relative' }}>
      {(weeks.loading || loading) && <GlobalLoading />}
      <Grid item xs>
        <HeaderPlan />
      </Grid>
      <Grid item>
        <div className={dailyPlanContainer}>
          {sortedWeekCombinations.map(renderDay)}
        </div>
      </Grid>
    </Grid>
  );
};

export default Week;
