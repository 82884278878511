import { useMutation } from '@apollo/react-hooks';
import { checkCouponMutation } from '../../mutations/order';

export default () => {
  const [
    checkCoupon,
    response,
  ] = useMutation(checkCouponMutation);
  const {
    loading,
    data: { checkCoupon: checkCouponData } = {},
    error,
  } = response;

  return [checkCoupon, { loading, data: checkCouponData, errors: error && error.graphQLErrors }];
};
