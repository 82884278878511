import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCartContext } from '../../contexts/CartContext';
import useCheckCoupon from '../../graphql/hooks/order/useCheckCoupon';
import Input from '../../components/Input';
import Button from '../../components/Button';

const PromoCode = () => {
  const { t } = useTranslation('order');
  const { palette: { red } } = useTheme();
  const promoInput = useRef(null);
  const [{ getCoupon }, { updateCoupon }] = useCartContext();
  const [checkCoupon, { loading, data, errors }] = useCheckCoupon();
  const hasErrors = errors && errors.length;
  const [inputValue, setInputValue] = useState('');
  const [hideErrors, setHideErrors] = useState(false);

  useEffect(() => {
    if (data) {
      updateCoupon(data);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data]);

  useEffect(() => { setHideErrors(!hasErrors); }, [errors]);

  const handleInputFocus = () => {
    if (hasErrors) {
      setHideErrors(true);
      setInputValue('');
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    checkCoupon({ variables: { code: promoInput.current.value } });
  };

  if (getCoupon()) return null;

  return (
    <Grid item xs={12} style={{ position: 'relative' }}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
              spacing={0}
            >
              <Grid item xs={8}>
                <Input
                  disabled={loading}
                  ref={promoInput}
                  borderColor="grey.main"
                  placeholder={t('insert-code')}
                  fullWidth
                  hasError={hasErrors && !hideErrors}
                  onFocus={handleInputFocus}
                  controlledValue={inputValue}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="flex-end">
                  <Button
                    disabled={loading}
                    type="submit"
                    style={{
                      padding: '10px 20px',
                      fontSize: 15,
                    }}
                  >
                    {t('common:apply')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Collapse in={hasErrors && !hideErrors}>
                <Typography variant="caption" style={{ color: red.main }}>
                  {errors && errors.length && errors[0].debugMessage}
                </Typography>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default PromoCode;
