import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { white, primary }, spacing }) => ({
  tableContainer: {
    '& .MuiPaper-root': { borderRadius: 10 },
    '& th': {
      backgroundColor: primary.dark,
      color: white.main,
      fontWeight: 900,
      padding: spacing(2, 4),
    },
    '& th:first-child': { borderTopLeftRadius: 10 },
    '& th:last-child': { borderTopRightRadius: 10 },
    '& th:last-child div': { textAlign: 'right' },
    '& th + th': { paddingLeft: spacing() },
    '& td': { padding: spacing(2, 4) },
    '& td + td': { paddingLeft: spacing() },
    '& td:last-child': { textAlign: 'right' },
  },
}));

export default useStyles;
