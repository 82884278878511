import React from 'react';
import Container from '@material-ui/core/Container';
import { PlanProvider } from '../../contexts/PlanContext';
import BodyContainer from '../../components/BodyContainer';
import Content from './Content';

const Dashboard = () => (
  <BodyContainer>
    <Container>
      <PlanProvider>
        <Content />
      </PlanProvider>
    </Container>
  </BodyContainer>
);

export default Dashboard;
