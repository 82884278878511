import { useQuery } from '@apollo/react-hooks';
import { getCheckoutSession } from '../../queries/order';

export default ({ orderSessionId }) => {
  const { loading, data, errors } = useQuery(getCheckoutSession, {
    variables: {
      orderSessionId,
    },
  });

  const { checkoutSession } = data || {};

  return { loading, data: checkoutSession, errors };
};
