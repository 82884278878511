import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './style';

const Svg = ({
  active,
  noBorder,
  size,
  style,
  title,
  url,
}) => {
  const { iconContainer } = useStyles({ noBorder, size });
  const [svg, setSvg] = useState(null);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((res) => res.text())
        .then(setSvg)
        .catch(setIsErrored);
    }
  }, [url]);

  if (url && !isErrored) {
    return (
      <Tooltip
        title={title || ''}
        disableFocusListener={!title}
        disableHoverListener={!title}
        disableTouchListener={!title}
      >
        <div
          style={style}
          className={`svg ${iconContainer} ${active ? 'active' : ''}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      </Tooltip>
    );
  }

  return null;
};

Svg.propTypes = {
  active: PropTypes.bool,
  noBorder: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  title: PropTypes.string,
  url: PropTypes.string,
};

Svg.defaultProps = {
  active: false,
  noBorder: false,
  size: 45,
  style: {},
  title: undefined,
  url: undefined,
};

export default Svg;
