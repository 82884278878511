import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, spacing }) => ({
  tabStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: ({ active, contrasted, mainColor }) => {
      if (active && contrasted) return palette.common.white;
      if (active) return palette[mainColor].light;
      return palette[mainColor][mainColor === 'primary' ? 'dark' : 'main'];
    },
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: `0 ${spacing(4)}px`,
    width: '100%',
    height: 50,
    color: ({ active, mainColor }) => {
      if (mainColor === 'grey') { return 'black'; }
      return (active ? 'black' : palette.white.main);
    },
    fontSize: '1rem',
    fontWeight: 300,
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    justifyContent: ({ full }) => (full ? 'left' : 'center'),
    minWidth: ({ minWidth }) => (minWidth ? spacing(25) : 0),
    maxWidth: ({ maxWidth }) => (maxWidth ? spacing(30) : 'none'),
  },

  actionStyle: {
    marginLeft: spacing(3),
    marginRight: -spacing(2),
  },
}));

export default useStyles;
