import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import sumBy from 'lodash/sumBy';
import isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import { formatMoney } from '../../libraries/helpers';
import { useCartContext } from '../../contexts/CartContext';
import Quantity from '../Quantity';
import Dialog from '../Dialog';
import Button from '../Button';
import useStyles from './style';

const SizesDialog = ({
  cartInfo: {
    id,
    slug,
    price,
    image,
    title,
  },
  onClose,
  open,
  sizes,
}) => {
  const { t } = useTranslation(['dish', 'ingredients']);
  const [{ cartItems, voidSpacesLeft }, { updateQuantityCart }] = useCartContext();
  const getAlreadyInCart = (sizeId) => cartItems.find(({ id: cartId }) => cartId === sizeId);
  const cartQuantities = useRef(sizes.map((size) => ({
    ...size,
    quantity: getAlreadyInCart(size.id) ? getAlreadyInCart(size.id).quantity : 0,
  })));
  const [tempQuantities, setTempQuantities] = useState(cartQuantities.current);
  const isEditing = (sizeId) => {
    if (sizeId) {
      return !isEqual(
        tempQuantities.find((size) => `${size.id}` === `${sizeId}`),
        cartQuantities.current.find((size) => `${size.id}` === `${sizeId}`),
      );
    }
    return !isEqual(tempQuantities, cartQuantities.current);
  };
  const {
    modalContainer,
    modalSubtitle,
    activeRow,
    cartButtonIcon,
  } = useStyles({ isEditing: isEditing() });

  const getCurrentQuantityById = (sizeId, quantities = tempQuantities) => (
    quantities.find(({ id: quantityId }) => quantityId === sizeId).quantity
    || 0
  );

  const handleQuantityChange = (sizeId) => (updatedQuantity) => {
    setTempQuantities((actualQuantities) => actualQuantities.map((actualQuantity) => (
      actualQuantity.id === sizeId
        ? { ...actualQuantity, quantity: updatedQuantity }
        : actualQuantity
    )));
  };

  const handleAddCart = () => {
    tempQuantities.forEach((quantity) => {
      updateQuantityCart({
        id: quantity.id,
        slug,
        dishId: id,
        title,
        price: price + quantity.additionalCost,
        dimension: quantity.dimension,
        grams: quantity.grams,
        image: image.length && image[0],
        quantity: getCurrentQuantityById(quantity.id),
      });
    });
    onClose();
  };

  /* eslint-disable react/prop-types */
  const renderItem = ({
    id: sizeId,
    grams,
    dimension,
    additionalCost,
  }) => {
    const cartSpace = sumBy(cartQuantities.current, 'quantity');
    const tempSpace = sumBy(tempQuantities, 'quantity');
    const actualVoidSpacesLeft = voidSpacesLeft - (tempSpace - cartSpace);

    return (
      <Grid item key={sizeId}>
        {getAlreadyInCart(sizeId) || isEditing(sizeId) ? <div className={activeRow} /> : null}
        <Grid container justify="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="caption">
              {`${t(dimension.toLowerCase())} | ${grams}${t('ingredients:gram')}`}
            </Typography>
            <Typography style={{ lineHeight: 1, fontWeight: 900 }}>
              {formatMoney(additionalCost + price)}
            </Typography>
          </Grid>
          <Grid item>
            <Quantity
              quantity={getCurrentQuantityById(sizeId)}
              voidSpacesLeft={!!actualVoidSpacesLeft}
              onChange={handleQuantityChange(sizeId)}
              removable={false}
              isEditing={isEditing(sizeId)}
              allowZero
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  /* eslint-enable react/prop-types */

  const renderLabel = () => {
    if (sumBy(cartQuantities.current, 'quantity') === 0) {
      return (
        <>
          {t('add-to-cart')}
          <span style={{ fontSize: '1.1rem', fontWeight: 900, marginLeft: 8 }}>+</span>
          <CartIcon className={cartButtonIcon} />
        </>
      );
    }

    return (
      <>
        {t('update-cart')}
        <FontAwesomeIcon
          size="lg"
          icon={faSyncAlt}
          style={{ marginLeft: 8 }}
        />
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('choose-dimension')}
      className={modalContainer}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="body2" gutterBottom className={modalSubtitle}>
            <b style={{ fontWeight: 900 }}>{t('portion')}</b>
          </Typography>
          <Grid container direction="column" spacing={2}>
            {sizes.map(renderItem)}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Grid item xs={12} sm={8}>
              <Button
                stretched
                padding={2}
                filled={isEditing()}
                disabled={!voidSpacesLeft || isEqual(tempQuantities, cartQuantities.current)}
                onClick={handleAddCart}
              >
                {renderLabel()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

SizesDialog.propTypes = {
  cartInfo: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SizesDialog;
