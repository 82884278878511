import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import useCheckShoppingListItem from '../../graphql/hooks/shoppingList/checkShoppingListItem';
import useDeleteShoppingListItem from '../../graphql/hooks/shoppingList/deleteShoppingListItem';
import useStyles from './style';

const Item = ({
  id,
  label,
  quantity,
  selected,
  unit,
}) => {
  const { t } = useTranslation('ingredients');
  const [showDelete, setShowDelete] = useState(false);
  const { actionStyle } = useStyles({ showDelete });
  const [checkShoppingListItem] = useCheckShoppingListItem();
  const [deleteShoppingListItem] = useDeleteShoppingListItem();

  const handleOver = (over) => () => { setShowDelete(over); };

  const handleCheck = ({ target: { checked } }) => {
    checkShoppingListItem({ variables: { id, checked } });
  };

  const handleDelete = () => {
    deleteShoppingListItem({ variables: { id } });
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      spacing={1}
      onMouseEnter={handleOver(true)}
      onMouseOver={handleOver(true)}
      onFocus={handleOver(true)}
      onMouseLeave={handleOver(false)}
    >
      <Grid item xs={2} sm={1}>
        <Checkbox
          checked={selected}
          onChange={handleCheck}
        />
      </Grid>
      <Grid item xs sm={5} style={{ lineHeight: 1 }}>{label}</Grid>
      <Grid item xs={2} container justify="flex-end">{quantity}</Grid>
      <Grid item xs={2}>{quantity && unit && t(unit.toLowerCase())}</Grid>
      <Grid item xs={1}>
        <ButtonBase
          className={actionStyle}
          onClick={handleDelete}
        >
          <FontAwesomeIcon
            size="sm"
            icon={faTrashAlt}
          />
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  selected: PropTypes.bool,
  unit: PropTypes.string,
};

Item.defaultProps = {
  quantity: null,
  selected: false,
  unit: null,
};

export default Item;
