import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import useDateFns from '../../../hooks/useDateFns';
import useCheckShipping from '../../../graphql/hooks/order/useCheckShipping';
import { UserInfoContext } from '../UserInfoContext';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';

const DeliveryDate = ({ active, confirm }) => {
  const { t } = useTranslation('order');
  const { format } = useDateFns();
  const {
    addressState: [addressData],
    deliveryDateState: { choosenState: [choosenDate, setChoosenDate] },
  } = useContext(UserInfoContext);
  const [checkShipping, { loading, data: availableDates }] = useCheckShipping();

  useEffect(() => {
    if (!availableDates) {
      checkShipping({ variables: addressData });
    } else if (choosenDate && availableDates.find(({ date }) => date === choosenDate)) {
      confirm();
    } else {
      setChoosenDate('');
    }
    // eslint-disable-next-line
  }, [availableDates]);

  if (availableDates && availableDates.length === 0) {
    return (
      <Alert severity="error">{t('shipping-not-valid')}</Alert>
    );
  }

  const asapDate = availableDates && availableDates[0] && availableDates[0].date;
  const asapDateFormatted = availableDates
    && availableDates[0]
    && format(new Date(availableDates[0].date), 'd MMMM yyyy');

  const handleAsapClick = () => {
    setChoosenDate(asapDate);
    confirm();
  };

  if (loading) return <Loading />;

  return (
    <>
      <Collapse in={!active}>
        <p>
          {availableDates ? (
            <Typography variant="caption">
              {`${t('delivered-by')}: ${availableDates.find(({ date }) => date === asapDate).courierName}`}
            </Typography>
          ) : null}
          <br />
          <b>{asapDateFormatted}</b>
        </p>
        <Typography variant="caption">
          {availableDates && availableDates.find(({ date }) => date === asapDate).shippingHours}
        </Typography>
      </Collapse>

      <Collapse in={active}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={4}>
            <Button
              onClick={handleAsapClick}
              filled={choosenDate === asapDate}
              stretched
            >
              {asapDateFormatted}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button disabled stretched>
              {t('choose-date')}
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

DeliveryDate.propTypes = {
  active: PropTypes.bool,
  confirm: PropTypes.func,
};

DeliveryDate.defaultProps = {
  active: false,
  confirm: () => { },
};

export default DeliveryDate;
