import React, { useContext } from 'react';
import RecipeContext from './contexts';
import useStyles from './style';

const ImageBadge = () => {
  const { badgeContainer } = useStyles();
  const { badge } = useContext(RecipeContext);

  if (badge) {
    const { thumbnail, name } = badge;

    return (
      <div className={badgeContainer}>
        {
          thumbnail
            ? (
              <img
                alt="recipe badge"
                src={thumbnail.original}
                height="70%"
              />
            )
            : <b>{name}</b>
        }
      </div>
    );
  }

  return null;
};

export default ImageBadge;
