import gql from 'graphql-tag';

const setTrackingMutation = gql`
  mutation SetTracking($planWeek: Int, $meals: [TrackingMealInput], $day: PlanDay) {
    setTracking(tracking: { plan_week: $planWeek, meals: $meals, day: $day }) {
      id
    }
  }
`;

const switchMealMutation = gql`
  mutation SwitchMeal($planWeek: Int, $day: PlanDay!) {
    switchMeal(switch: { plan_week: $planWeek, day: $day }) {
      id
    }
  }
`;

const switchDayMutation = gql`
  mutation SwitchDay($planWeek: Int, $fromDay: PlanDay!, $toDay: PlanDay!) {
    switchDay(switch: { plan_week: $planWeek, from_day: $fromDay, to_day: $toDay }) {
      id
    }
  }
`;

export { setTrackingMutation, switchMealMutation, switchDayMutation };
