import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../components/propTypes';
import useGetDishQuery from '../graphql/hooks/dishes/useGetDishQuery';

const DishContext = createContext();

const DishProvider = ({ dishId, children }) => {
  const dish = useGetDishQuery({ dishId });

  return (
    <DishContext.Provider value={dish}>
      {children}
    </DishContext.Provider>
  );
};

const useDishContext = () => useContext(DishContext);

DishProvider.propTypes = {
  children: childrenPropType.isRequired,
  dishId: PropTypes.string.isRequired,
};

export {
  DishContext,
  DishProvider,
  useDishContext,
};
