import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { getIngredientsQuery } from '../../queries/generic';

export default ({ search, lazy = false }) => {
  const [getIngredients, { loading, data }] = useLazyQuery(getIngredientsQuery, {
    variables: { search },
  });

  useEffect(() => {
    if (!lazy) {
      getIngredients();
    }
  }, [getIngredients, search, lazy]);

  return {
    getIngredients,
    loading,
    data: data && data.ingredientTrees,
  };
};
