import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiPaper from '@material-ui/core/Paper';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const Paper = ({
  children,
  title,
  disabled,
  style,
  className,
  contentClassName,
}) => {
  const { container, content, head } = useStyles({ disabled });

  return (
    <MuiPaper
      className={classNames(container, className)}
      elevation={disabled ? 0 : 3}
      style={style}
    >
      {title && <div className={head}>{title}</div>}
      <div className={classNames(content, contentClassName)}>{children}</div>
    </MuiPaper>
  );
};

Paper.propTypes = {
  children: childrenPropType.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

Paper.defaultProps = {
  title: '',
  disabled: false,
  style: {},
  className: '',
  contentClassName: '',
};

export default Paper;
