import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { secondary, white, grey } }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    color: white.main,
    backgroundColor: secondary.main,
    width: spacing(5),
    height: spacing(4.5),
    fontWeight: 700,
    fontSize: '1.2rem',
  },
  substractionButton: {
    borderRadius: '999px 0 0 999px',
    paddingLeft: spacing(),
    backgroundColor: ({ noMoreDown }) => noMoreDown && grey.main,
  },
  additionButton: {
    borderRadius: '0 999px 999px 0',
    paddingRight: spacing(),
    backgroundColor: ({ noMoreUp }) => noMoreUp && grey.main,
  },
  label: {
    display: 'inline-flex',
    width: spacing(6.5),
    height: spacing(4.5),
    border: `2px solid ${secondary.main}`,
    fontWeight: 900,
    fontSize: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
