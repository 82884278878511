import React, { createContext, useState } from 'react';
import { childrenPropType } from '../../components/propTypes';

const ActivatedStepContext = createContext([]);

const ActivatedStepProvider = ({ children }) => {
  const activatedStepState = useState(0);

  return (
    <ActivatedStepContext.Provider value={activatedStepState}>
      {children}
    </ActivatedStepContext.Provider>
  );
};

ActivatedStepProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  ActivatedStepContext,
  ActivatedStepProvider,
};
