import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { white, grey }, spacing }) => ({
  container: {
    position: 'relative',
    background: white.main,
    borderRadius: 20,
    padding: spacing(2),
    height: '100%',
    boxShadow: `0px 0px 5px 0px ${grey.main}`,
    display: ({ flexCenter }) => (flexCenter ? 'flex' : 'block'),
    alignItems: ({ flexCenter }) => (flexCenter ? 'center' : 'start'),
  },

  content: {
    width: '100%',
    height: ({ title }) => (title ? 'calc(100% - 60px)' : 'auto'),
    overflowY: 'auto',
  },

  icon: {
    position: 'absolute',
    top: spacing(2),
    right: spacing(2),
  },

  weightGraphContainer: {
    width: '100%',
    height: 380,
    overflow: 'hidden',
  },
}));

export default useStyles;
