import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { defaultUpdateQuery, getOrderDishesFilters } from '../../../libraries/helpers';
import { getDishesQuery } from '../../queries/dishes';

export default ({ filters = {}, lazy = false, first } = {}) => {
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const [getDishes, { loading, data, fetchMore }] = useLazyQuery(getDishesQuery, {
    variables: {
      first: first || process.env.REACT_APP_DEFAULT_PAGINATION,
      filter: getOrderDishesFilters(filters),
    },
  });

  useEffect(() => {
    if (!lazy) {
      getDishes();
    }
  }, [getDishes, filters, lazy]);

  const { menus: { edges, pageInfo } } = data || { menus: {} };

  return {
    getDishes,
    loading,
    data: edges || data,
    loadingFetchMore,
    hasNextPage: pageInfo && pageInfo.hasNextPage,
    fetchMore: data
      ? () => {
        setLoadingFetchMore(true);
        fetchMore({
          variables: { after: pageInfo ? pageInfo.endCursor : null },
          updateQuery: defaultUpdateQuery('menus', setLoadingFetchMore),
        });
      }
      : fetchMore,
  };
};
