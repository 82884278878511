import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  spacing,
  breakpoints,
  palette: {
    white,
    primary,
    secondary,
    grey,
  },
}) => ({
  container: {
    position: 'relative',
    minHeight: 678,
  },

  switchModeElement: {
    border: `5px dashed ${primary.main}`,
    borderRadius: 30,
    zIndex: 999,
    pointerEvents: 'none',
  },

  header: {
    maxHeight: 82,
    transition: 'background-color 200ms ease',
    backgroundColor: ({ isSwitchTarget, isSwitchSource }) => {
      if (isSwitchTarget) return primary.main;
      if (isSwitchSource) return secondary.main;
      return primary.dark;
    },
    opacity: ({ notPrimary }) => (notPrimary ? 0.5 : 1),
    borderRadius: '30px 30px 0 0',
    color: white.main,
    fontWeight: 900,
    fontSize: '1.6rem',
    [breakpoints.down('sm')]: {
      padding: spacing(),
    },
  },

  buttonHeader: {
    fontWeight: 900,
    fontSize: '1.6rem',
    width: '100%',
    height: '100%',
    padding: spacing(3, 0),
    [breakpoints.down('sm')]: {
      padding: spacing(2),
      justifyContent: 'flex-start',
    },
  },

  fakeButtonHeader: {
    border: `1px solid ${white.main}`,
    borderRadius: 999,
    padding: spacing(1, 2),
    fontSize: '0.6em',
    position: 'absolute',
    right: spacing(3),
    top: '50%',
    transform: 'translateY(-50%)',
    [breakpoints.down('sm')]: {
      right: spacing(2),
    },
  },

  body: {
    backgroundColor: white.main,
    borderRadius: '0 0 30px 30px',
    padding: spacing(2, 0),
    '& .slider, .slider-slide': {
      outline: 'none',
    },
  },

  arrowButton: { height: '100%', width: '100%' },
  left: {
    '& svg .main': {
      transition: '500ms ease',
      stroke: ({ noPrev }) => (noPrev ? grey.dark : primary.dark),
    },
  },
  right: {
    '& svg .main': {
      transition: '500ms ease',
      stroke: ({ noNext }) => (noNext ? grey.dark : primary.dark),
    },
  },

  carouselContainer: {
    '& *': {
      userSelect: 'none',
      tapHighlightColor: 'transparent',
    },

    '& .slider-frame': {
      padding: `${spacing(2)}px 0 !important`,
      minHeight: spacing(30),
    },

    '& .slider-control-centerright, .slider-control-centerleft': {
      height: '100%',
      width: spacing(6),
      [breakpoints.down('sm')]: {
        width: spacing(4),
      },
    },

    '& .slider-slide': {
      [breakpoints.down('sm')]: {
        maxWidth: 280,
      },
    },
  },

  paperContainer: {
    minHeight: 170,
    transition: 'transform 250ms ease',
  },

  combinationTitle: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: spacing(3),
  },

  ingredientLabel: {
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    textAlign: 'center',
    display: 'inline-block',
    [breakpoints.down('sm')]: {
      marginLeft: spacing(),
    },
  },

  ingredientsContainer: {
    '& span + span': {
      [breakpoints.up('md')]: {
        marginLeft: ({ ingredientsCount }) => (ingredientsCount <= 3 ? -spacing(4) : -spacing(4.5)),
      },
      [breakpoints.down('sm')]: {
        marginLeft: -spacing(4.75),
      },
    },
  },

  ingredientIcon: {
    width: 45,
    height: 45,
    borderRadius: 999,
    border: `1px solid ${secondary.main}`,
    padding: spacing(0.5),
    backgroundColor: white.main,
  },

  quantityBadge: {
    padding: '0 5px 0 8px',
    border: `1px solid ${secondary.main}`,
    backgroundColor: white.main,
  },

  showMoreButton: {
    border: `1px solid ${secondary.main}`,
    borderRadius: 999,
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    padding: spacing(1, 1.50),
    [breakpoints.down('sm')]: {
      padding: spacing(),
    },
  },

  paperCombination: {
    boxSizing: 'border-box',
    minHeight: 175,
  },

  paperCombinationContent: {
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },

  active: {
    border: `3px solid ${secondary.main}`,
  },

  preselected: {
    border: `3px solid ${primary.dark}`,
  },

  switchButton: {
    [breakpoints.down('xs')]: {
      order: -1,
    },
  },
}));

export default useStyles;
